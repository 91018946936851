import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { Footer, Loading, MainNav, SubNav } from "./Components";
import ScrollToTop from "Utils/lib/scrollRestoration";

import {
  Login,
  Signup,
  Home,
  PlayGround,
  News,
  Subscribe,
  Question,
  Account,
  FindInfo,
  Lms,
  Builder,
  Share,
} from "Views";

const MainApp = () => {
  const [current, setCurrent] = useState("/");
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;
  const [fakeLoading, setFakeLoading] = useState(false);
  const user = localStorage.getItem("user_token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loading) {
      // 로딩이 0.5초 이상 걸릴때만 로딩화면 띄움
      setTimeout(() => {
        if (loadingRef.current) setFakeLoading(true);
      }, 500);
    } else setFakeLoading(false);
  }, [loading]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="view">
        {fakeLoading && <Loading loading={fakeLoading} />}
        <div className="nav">
          <SubNav current={current} />
          {/* <MainNav current={current} /> */}
          <Routes>
            {/* 랜딩 */}
            <Route
              path="/"
              element={<Home loading={loading} setLoading={setLoading} />}
            />
            {/* 뉴스 놀이터 목록 */}
            {/* 
                type : main / search
                keyword : null / keyword /id
                뉴스 놀이터 상세 주소 
            */}
            <Route
              path="/playground/:type/:keyword"
              element={<PlayGround loading={loading} setLoading={setLoading} />}
            />
            {/* 뉴스 상세 */}
            {/* 
                
            */}
            <Route
              path="/news/:id"
              element={<News loading={loading} setLoading={setLoading} />}
            />
            {/* 자주 묻는 질문 */}
            {/* 
                  type : news / search
               */}
            <Route
              path="/question/:type"
              element={<Question loading={loading} setLoading={setLoading} />}
            />

            {/* 구독 */}
            {/* 
              type : info / payment
            */}
            <Route
              path="/subscribe/:type/:id"
              element={<Subscribe loading={loading} setLoading={setLoading} />}
            />

            {/* 계정관리 */}
            {/* 
              type : info (student / parents) / children
            */}
            <Route
              path="/account/:type/:id"
              element={<Account loading={loading} setLoading={setLoading} />}
            />

            {/* 로그인 */}
            {/* 
              type : student / parents
            */}
            <Route
              path="/login/:type"
              element={<Login loading={loading} setLoading={setLoading} />}
            />
            {/* 정보 찾기 */}
            {/*             
              아이디 find/id/type
              비밀번호 find/pw/type
              비밀번호변경 find/pwchange/type
              location : id/pw
              type : student/parents/change
            */}

            <Route
              path="/find/:location/:type"
              element={<FindInfo loading={loading} setLoading={setLoading} />}
            />
            {/* 회원가입 */}
            <Route
              path="/signup/:type"
              element={<Signup loading={loading} setLoading={setLoading} />}
            />
            {/* lms */}
            {/*             
              location : student/parents
              type : dashboard/subscribe
              status : type이 dashboard 일때만 // subscribe 일땐 null
                      - location : student
                        participation 뉴스 놀이터 참여현황, 
                        news 나의뉴스,
                        newsletter 나의 뉴스레터,
                        likednews 좋아한뉴스, 
                        mycomment 나의댓글  
                      - location : parents
                        participation 뉴스 놀이터 참여현황, 
                        news 아이의 뉴스,
                        newsletter 아이의 뉴스레터,
                        likednews 좋아한뉴스, 
            */}

            <Route
              path="/lms/:location/:type/:status"
              element={<Lms loading={loading} setLoading={setLoading} />}
            />
            {/* 뉴스 빌더 */}
            {/*  
              type : news/newsletter
              status : save / temp
              id :             
            */}
            <Route
              path="/builder/:type/:status/:id"
              element={<Builder loading={loading} setLoading={setLoading} />}
            />
            {/* 뉴스 공유 */}
            {/*  
              type : news/newsletter
              id :             
            */}
            <Route
              path="/share/:type/:id"
              element={<Share loading={loading} setLoading={setLoading} />}
            />
          </Routes>
        </div>

        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default MainApp;

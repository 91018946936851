import React from "react";
import styled from "styled-components";

const View = styled.div`
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  i {
    font-size: 40px;
    color: var(--main);
  }
`;

const Loading = ({ loading }) => {
  return (
    <>
      {loading && (
        <View>
          <i className="xi-spinner-3 xi-spin"></i>
        </View>
      )}
    </>
  );
};

export default Loading;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import { Modal, Alert, Pager, LmsNav } from "Components";

// img
import NewsBuilderCalendar from "Assets/images/icons/newsbuilder_save_calendar.svg";
import NewsBuilderPeople from "Assets/images/icons/newsbuilder_save_people.svg";
import NewsBuilderGrade from "Assets/images/icons/newsbuilder_save_grade.svg";
import DownloadIcon from "Assets/images/icons/download_icon.svg";
import ShareIcon from "Assets/images/icons/share_icon.svg";
import Logo from "Assets/images/icons/newsbuilder_loco.svg";
import SimpleLogo from "Assets/images/icons/simple_logo.svg";
import Mail from "Assets/images/icons/newsbuilder_mail.svg";
import NewsLetterBuilderMail from "Assets/images/icons/newsletterbuilder_mail.svg";
import NewsLetterBuilderSymbol from "Assets/images/icons/newsletterbuilder_symbol.svg";
import WebsiteIcon from "Assets/images/icons/website_icon.svg";
import InstaIcon from "Assets/images/icons/insta_icon.svg";

// styles
import {
  LmsSt,
  NewsBuilderSave,
  NewsLetterTemplate,
  NewsLetterBuilderSave,
} from "../Lms/Lms.style";
import { BasicButton } from "Styles/styles";

// logics
import BuilderLogic from "./Share.logic";
import moment from "moment";

const Builder = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: "",
  });
  const { state, ref, func } = BuilderLogic({
    loading,
    setLoading,
    navigate,
    url: urlState,
  });
  useEffect(() => {
    setUrlState({
      ...urlState,
      current: location.pathname,
      type: location.pathname.split("/share/")[1].split("/")[0],
      id: location.pathname.split("/share/")[1].split("/")[1].split("/")[0],
    });
  }, [location.pathname]);
  return (
    <>
      <LmsSt className="on">
        {/* 발간된 뉴스빌더 */}
        {urlState.type === "news" && state.news !== [] ? (
          <NewsBuilderSave>
            <div id="news" ref={ref.ref}>
              <ul className="tag_list">
                {state.news.first_categories ? (
                  <li>{state.news.first_categories.category_name}</li>
                ) : null}
                {state.news.second_categories ? (
                  <li>{state.news.second_categories.category_name}</li>
                ) : null}
              </ul>
              <h1>{state.news.title}</h1>
              <ul className="info">
                <li>
                  <img src={NewsBuilderCalendar} alt="캘린더 아이콘" />
                  <p>{moment(state.news.published_at).format("YYYY.MM.DD.")}</p>
                </li>
                <li>
                  <img src={NewsBuilderPeople} alt="사용자 아이콘" />
                  <p>{state.news.users && state.news.users.nick_name}</p>
                </li>
                <li>
                  <img src={NewsBuilderGrade} alt="학년 아이콘" />
                  <p>3학년</p>
                </li>
              </ul>
              {state.news.contents &&
                state.news.contents.map((item) => (
                  <div>
                    <img
                      src={item.images.image_url}
                      alt="뉴스빌더 업로드 이미지"
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.content.replaceAll("\n", "<br/>"),
                      }}
                    ></p>
                  </div>
                ))}
            </div>
          </NewsBuilderSave>
        ) : null}
        {/* 발간된 뉴스레터 빌더 */}
        {urlState.type === "letter" && state.news !== [] ? (
          <NewsLetterBuilderSave>
            {state.news.letter ? (
              <NewsLetterTemplate id="news" ref={ref.ref}>
                <div>
                  <img
                    src={NewsLetterBuilderSymbol}
                    alt="뉴스레터 빌더 심볼"
                    className="newsletter_builder_symbol"
                  />
                  <div className="newsletter_builder_wrap">
                    <div className="header">
                      <img src={Logo} alt="뉴스레터탬플릿 로고" />
                      <div>
                        <img src={Mail} alt="메일아이콘" />
                        <span>
                          {state.news.letter.year}년 {state.news.letter.month}
                          월호
                        </span>
                      </div>
                    </div>
                    <div className="title">
                      <img
                        src={NewsLetterBuilderMail}
                        alt="뉴스레터 빌더 메일 아이콘"
                      />
                      <h1>{state.news.letter.title}</h1>
                      <div>
                        <img src={SimpleLogo} alt="심플 로고" />
                        <p>{state.news.letter.users.nick_name} 기자</p>
                      </div>
                    </div>

                    {/* 뉴스레터 빌터 컴포넌트1 */}
                    {state.news.contents && state.news.contents.length > 0 ? (
                      state.news.contents.map((item) => {
                        if (item.contents.image_location === "right") {
                          return (
                            <div
                              key={`content_${item.contents.id}`}
                              className="newsletter_special_component"
                            >
                              <div className="picture_component">
                                <div>
                                  <span className="special_tag">
                                    스페셜 뉴스
                                  </span>
                                  <h2>{item.contents.title}</h2>
                                </div>
                                <div className="narrow_component">
                                  <div className="imgbox_narrow">
                                    {/* 뉴스레터 빌터 컴포넌트2 - 발간된 이미지*/}

                                    <div className="imgbox_upload_done">
                                      <img
                                        src={item.contents.images.image_url}
                                        alt="뉴스빌더 업로드 이미지"
                                      />
                                    </div>
                                  </div>
                                  <div className="text_box">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.contents.content.replaceAll(
                                            "\n",
                                            "<br/>"
                                          ),
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (item.contents.image_location === "right") {
                          return (
                            <div
                              key={`content_${item.contents.id}`}
                              className="newsletter_special_component"
                            >
                              <div className="picture_component">
                                <div>
                                  <span className="special_tag">
                                    스페셜 뉴스
                                  </span>
                                  <h2>{item.contents.title}</h2>
                                </div>
                                <div className="narrow_component">
                                  <div className="text_box">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.contents.content.replaceAll(
                                            "\n",
                                            "<br/>"
                                          ),
                                      }}
                                    ></p>
                                  </div>
                                  <div className="imgbox_narrow">
                                    {/* 뉴스레터 빌터 컴포넌트2 - 발간된 이미지*/}

                                    <div className="imgbox_upload_done">
                                      <img
                                        src={item.contents.images.image_url}
                                        alt="뉴스빌더 업로드 이미지"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={`content_${item.contents.id}`}
                              className="newsletter_component"
                            >
                              <div className="imgbox_upload_done">
                                <img
                                  src={item.contents.images.image_url}
                                  alt="뉴스빌더 업로드 이미지"
                                />
                              </div>
                              <div className="news_bring">
                                <h2>{item.contents.title}</h2>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.contents.content.replaceAll(
                                      "\n",
                                      "<br/>"
                                    ),
                                  }}
                                ></p>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div>뉴스 내용이 없습니다.</div>
                    )}
                  </div>
                  <div className="newsletter_builder_footer">
                    <div>
                      <img src={Logo} alt="뉴스레터탬플릿 로고" />
                      <ul>
                        <li>
                          <img src={WebsiteIcon} alt="웹 사이트 아이콘" />
                          <p>https://herewigg.com/</p>
                        </li>
                        <li>
                          <img src={InstaIcon} alt="인스타 아이콘" />
                          <p>https://www.instagram.com/herewigg/</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </NewsLetterTemplate>
            ) : null}
          </NewsLetterBuilderSave>
        ) : null}
      </LmsSt>
      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );
};

export default Builder;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import SignupLogic from "./Signup.logic";
import SignupSt from "./Signup.style";
import Loading from "Components/Loading/Loading";

import { Alert } from "Components";

// style
import { BasicButton } from "Styles/styles";

// select
import { selectStyles, DropdownIndicator } from "Styles/select.style";
import Select, { components } from "react-select";

// img
import Logo from "Assets/images/icons/wigg_logo.png";
import Signuparrow from "Assets/images/icons/signup_rightarrow.svg";
import RightarrowSmall from "Assets/images/icons/rightarrow_small.svg";
import Parents from "Assets/images/icons/parents_icon.svg";
import Requirement from "Assets/images/icons/requirement.svg";
import InfoIcon from "Assets/images/icons/info_icon.svg";
import SignupFinish from "Assets/images/icons/signup_finish_icon.svg";

const Signup = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: location.pathname.split("/signup/")[1],
  });

  useEffect(() => {
    setUrlState({
      ...urlState,
      current: location.pathname,
      type: location.pathname.split("/signup/")[1],
    });
  }, [location.pathname]);
  const { state, data, ref, func } = SignupLogic({
    loading,
    setLoading,
    navigate,
    url: urlState,
  });
  return (
    <>
      {loading && <Loading loading={loading} />}
      <SignupSt>
        {urlState.type === "info" ? (
          <>
            <div className="signup_logobox">
              <img src={Logo} alt="로고이미지" />
              <h4>회원가입</h4>
            </div>
            <div className="form">
              <h3>
                위그에서 다양한 기능을 이용해보세요!
                <br /> 회원 유형을 선택해주세요.
              </h3>
              <div onClick={() => navigate("/signup/parents")}>
                14세 이상 회원
                <img src={Signuparrow} alt="rightarrow" />
              </div>
              <div onClick={() => navigate("/signup/student")}>
                14세 미만 회원
                <img src={Signuparrow} alt="rightarrow" />
              </div>
            </div>
          </>
        ) : (
          <>
            {state.step === 1 || state.step === 2 || state.step === 3 ? (
              <>
                <div className="signup_logobox">
                  <img src={Logo} alt="로고이미지" />
                  <ul>
                    <li className={state.step === 1 ? "on" : ""}>
                      약관동의 <img src={RightarrowSmall} alt="오른쪽 화살표" />
                    </li>
                    <li className={state.step === 2 ? "on" : ""}>
                      {urlState.type === "student"
                        ? "보호자 휴대폰 번호 인증"
                        : "본인 인증"}
                      <img src={RightarrowSmall} alt="오른쪽 화살표" />
                    </li>
                    <li className={state.step === 3 ? "on" : ""}>정보입력</li>
                  </ul>
                </div>
                <div className="signup_step">
                  {/* 약관동의 */}
                  {state.step === 1 ? (
                    <>
                      <div className="step1_agree">
                        <input
                          type="checkbox"
                          id="terms_all"
                          onClick={() =>
                            func.setStateValue("terms_all", !state.terms_all)
                          }
                          checked={state.terms_all}
                        />
                        <label htmlFor="terms_all" className="agree_all_text">
                          모두 동의합니다.
                        </label>
                      </div>
                      {!loading && state.term_list.length > 0
                        ? state.term_list.map((item, idx) => (
                            <div className="step1_agree" key={`terms_${idx}`}>
                              <h6>
                                {item.title} (
                                {item.is_required ? "필수" : "선택"})
                              </h6>
                              <div>
                                <span>{item.title}에 관한 동의사항</span>
                                {item.content}
                              </div>
                              <input
                                type="checkbox"
                                id={`agree_${idx}`}
                                onChange={() =>
                                  func.setInputStateValue("terms", item.id)
                                }
                                checked={
                                  data.inputData.terms.filter(
                                    (i) => i === item.id
                                  ).length > 0
                                    ? true
                                    : false
                                }
                              />
                              <label htmlFor={`agree_${idx}`}>
                                동의합니다.
                              </label>
                            </div>
                          ))
                        : null}
                      <BasicButton
                        className="main"
                        onClick={() => func.setStep(2)}
                      >
                        다음
                      </BasicButton>
                    </>
                  ) : null}
                  {/* 본인인증 */}
                  {state.step === 2 ? (
                    <div className="step2_certification">
                      <div>
                        <h6>
                          {urlState.type === "student" ? "보호자" : ""} 휴대폰
                          번호
                        </h6>
                        <input
                          type="text"
                          placeholder={`"-"을 제외한 숫자만 입력 바랍니다.`}
                          className="short_input"
                          onChange={({ target: { value } }) =>
                            func.setInputStateValue("phone_number", value)
                          }
                        />
                        {state.phone_send ? (
                          <>
                            <input
                              type="text"
                              className="short_input"
                              placeholder="인증번호를 입력해주세요."
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("code", value)
                              }
                            />
                            <div className="btns">
                              <button onClick={() => func.checkPhoneOk("send")}>
                                재전송
                              </button>
                              <button
                                onClick={() => func.checkPhoneOk("check")}
                              >
                                다음
                              </button>
                            </div>
                          </>
                        ) : (
                          <button
                            className="certification_button"
                            onClick={() => func.checkPhoneOk("send")}
                          >
                            인증번호 받기
                          </button>
                        )}
                      </div>
                      <span>
                        <img src={InfoIcon} alt="안내사항 아이콘" />
                        <h6>안내사항</h6>
                        {urlState.type === "student" ? (
                          <p>
                            보호자가 먼저 가입한 경우 자동으로 보호자와
                            연결돼요.
                          </p>
                        ) : (
                          <p>
                            학생이 보호자 번호로 인증한 경우 자동으로 학생과
                            연결돼요.
                          </p>
                        )}
                      </span>
                    </div>
                  ) : null}
                  {state.step === 3 ? (
                    <>
                      <div className="step3_certification">
                        {state.parents_name !== "" ? (
                          <div className="parents_connect">
                            <img src={Parents} alt="보호자 아이콘" />
                            <span>{state.parents_name}</span>
                            {urlState.type === "student" ? "보호자" : "학생"}와
                            연결되었습니다!
                          </div>
                        ) : null}

                        <div className="step3_inputbpx">
                          <div>
                            <h6>이름</h6>
                            <img
                              src={Requirement}
                              alt="필수이미지"
                              className="requirement"
                            />
                          </div>
                          <input
                            type="text"
                            placeholder="이름을 입력해주세요"
                            onChange={({ target: { value } }) =>
                              func.setInputStateValue("name", value)
                            }
                          />
                        </div>
                        {/*  학생 닉네임 , 학부모는 x */}
                        {urlState.type === "student" ? (
                          <div className="step3_inputbpx">
                            <div>
                              <h6>닉네임</h6>
                              <img
                                src={Requirement}
                                alt="필수이미지"
                                className="requirement"
                              />
                            </div>
                            <input
                              type="text"
                              placeholder="한글 6자, 영어 12자 이내 (숫자 사용 가능)"
                              className="short_input"
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("nick_name", value)
                              }
                            />
                            {state.nick_name_check !== null ? (
                              state.nick_name_check ? (
                                <p className="safe_tag">멋진 닉네임이네요!</p>
                              ) : (
                                <p className="error_tag">
                                  이미 사용중인 닉네임이에요
                                </p>
                              )
                            ) : null}
                            <button
                              onClick={() => func.checkInfoOk("nick_name")}
                            >
                              중복확인
                            </button>
                          </div>
                        ) : null}
                        {/*  학생은 아이디 , 학부모는 이메일 */}
                        {urlState.type === "student" ? (
                          <div className="step3_inputbpx">
                            <div>
                              <h6>아이디</h6>
                              <img
                                src={Requirement}
                                alt="필수이미지"
                                className="requirement"
                              />
                            </div>
                            <input
                              type="text"
                              placeholder="한글 6자, 영어 12자 이내 (숫자 사용 가능)"
                              className="short_input"
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("login_id", value)
                              }
                            />
                            {state.login_id_check !== null ? (
                              state.login_id_check ? (
                                <p className="safe_tag">
                                  사용 가능한 아이디에요
                                </p>
                              ) : (
                                <p className="error_tag">
                                  이미 사용중인 아이디에요
                                </p>
                              )
                            ) : null}
                            <button
                              onClick={() => func.checkInfoOk("login_id")}
                            >
                              중복확인
                            </button>
                          </div>
                        ) : (
                          <div className="step3_inputbpx">
                            <div>
                              <h6>이메일</h6>
                              <img
                                src={Requirement}
                                alt="필수이미지"
                                className="requirement"
                              />
                            </div>
                            <input
                              type="text"
                              placeholder="이메일을 입력해주세요"
                              className="short_input"
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("email", value)
                              }
                            />
                            {state.email_check !== null ? (
                              state.email_check ? (
                                <p className="safe_tag">
                                  사용 가능한 이메일이에요
                                </p>
                              ) : (
                                <p className="error_tag">
                                  이미 사용중인 이메일이에요
                                </p>
                              )
                            ) : null}
                            <button onClick={() => func.checkInfoOk("email")}>
                              중복확인
                            </button>
                          </div>
                        )}

                        <div className="step3_inputbpx">
                          <div>
                            <h6>비밀번호</h6>
                            <img
                              src={Requirement}
                              alt="필수이미지"
                              className="requirement"
                            />
                          </div>
                          <input
                            type="password"
                            placeholder="영어, 숫자, 특수문자 를 사용해 20자 이내"
                            onChange={({ target: { value } }) =>
                              func.setInputStateValue("password", value)
                            }
                          />
                          {/* <p className="error_tag">
                        영어, 숫자를 사용해 20자 이내로 입력해주세요
                      </p> */}
                        </div>
                        <div className="step3_inputbpx">
                          <div>
                            <h6>비밀번호 확인</h6>
                            <img
                              src={Requirement}
                              alt="필수이미지"
                              className="requirement"
                            />
                          </div>
                          <input
                            type="password"
                            placeholder="비밀번호를 한번 더 입력해주세요"
                            onChange={({ target: { value } }) =>
                              func.setInputStateValue("passwordCheck", value)
                            }
                          />
                          {state.pw_err ? (
                            <p className="error_tag">{state.pw_err}</p>
                          ) : null}
                          {state.pw_check ? (
                            <p className="safe_tag">비밀번호가 안전합니다.</p>
                          ) : null}
                        </div>
                        <div className="step3_inputbpx">
                          <div>
                            <h6>
                              {urlState.type === "student" ? "보호자" : ""}
                              휴대폰 번호
                            </h6>
                            <img
                              src={Requirement}
                              alt="필수이미지"
                              className="requirement"
                            />
                          </div>
                          <input
                            type="number"
                            className="grey_input"
                            value={data.inputData.phone_number}
                            readOnly={true}
                          />
                        </div>
                        <div className="step3_inputbpx">
                          <div>
                            <h6>주소</h6>
                            <img
                              src={Requirement}
                              alt="필수이미지"
                              className="requirement"
                            />
                          </div>
                          <Select
                            placeholder="국가"
                            options={[{ value: 1, label: "대한민국" }]}
                            value={{ value: 1, label: "대한민국" }}
                            components={{ DropdownIndicator }}
                            styles={selectStyles}
                            width={124}
                            height={46}
                          />
                          <Select
                            placeholder="지역"
                            options={state.city_list}
                            components={{ DropdownIndicator }}
                            styles={selectStyles}
                            width={124}
                            height={46}
                            onChange={(value) =>
                              func.setInputStateValue("city_id", value.value)
                            }
                          />
                        </div>
                        <div className="step3_inputbpx">
                          <div>
                            <h6>생년월일</h6>
                            <img
                              src={Requirement}
                              alt="필수이미지"
                              className="requirement"
                            />
                          </div>
                          <input
                            type="number"
                            placeholder="20120103 (예시)"
                            onChange={({ target: { value } }) =>
                              func.setInputStateValue("birth", value)
                            }
                          />
                        </div>
                        {urlState.type === "student" ? (
                          <div className="step3_inputbpx">
                            <div>
                              <h6>학년</h6>
                              <img
                                src={Requirement}
                                alt="필수이미지"
                                className="requirement"
                              />
                            </div>
                            <div className="signup_radio">
                              <input
                                type="radio"
                                id="firstyear"
                                name="age"
                                onClick={() =>
                                  func.setInputStateValue("grade", "1")
                                }
                              />
                              <label htmlFor="firstyear">1학년</label>
                              <input
                                type="radio"
                                id="secondyear"
                                name="age"
                                onClick={() =>
                                  func.setInputStateValue("grade", "2")
                                }
                              />
                              <label htmlFor="secondyear">2학년</label>
                              <input
                                type="radio"
                                id="thirdyear"
                                name="age"
                                onClick={() =>
                                  func.setInputStateValue("grade", "3")
                                }
                              />
                              <label htmlFor="thirdyear">3학년</label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                id="fourthyear"
                                name="age"
                                onClick={() =>
                                  func.setInputStateValue("grade", "4")
                                }
                              />
                              <label htmlFor="fourthyear">4학년</label>
                              <input
                                type="radio"
                                id="fifthyear"
                                name="age"
                                onClick={() =>
                                  func.setInputStateValue("grade", "5")
                                }
                              />
                              <label htmlFor="fifthyear">5학년</label>
                              <input
                                type="radio"
                                id="sixthyear"
                                name="age"
                                onClick={() =>
                                  func.setInputStateValue("grade", "6")
                                }
                              />
                              <label htmlFor="sixthyear">6학년</label>
                            </div>
                          </div>
                        ) : null}
                        <div className="step3_inputbpx">
                          <div>
                            <h6>성별</h6>
                            <img
                              src={Requirement}
                              alt="필수이미지"
                              className="requirement"
                            />
                          </div>
                          <input
                            type="radio"
                            id="male"
                            name="gender"
                            onClick={() =>
                              func.setInputStateValue("gender", "male")
                            }
                          />
                          <label htmlFor="male">남자</label>
                          <input
                            type="radio"
                            id="female"
                            name="gender"
                            onClick={() =>
                              func.setInputStateValue("gender", "female")
                            }
                          />
                          <label htmlFor="female">여자</label>
                        </div>
                        <BasicButton
                          className="main"
                          onClick={() => func.signup()}
                        >
                          확인
                        </BasicButton>
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div className="signup_logobox">
                  <img src={Logo} alt="로고이미지" />
                  <ul>
                    <li className="signup_done">회원가입 완료</li>
                  </ul>
                </div>
                <div className="signup_step">
                  <div className="step4_certification">
                    <div>
                      <h4>
                        {data.inputData.name}님,
                        <br /> 회원가입이 완료되었어요!
                      </h4>
                    </div>
                    <img src={SignupFinish} alt="회원가입 완료 이미지" />
                    <BasicButton
                      className="main"
                      onClick={() => navigate(`/login/${urlState.type}`)}
                    >
                      로그인 하러가기
                    </BasicButton>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {state.alert ? (
          <Alert
            title={state.alert.title}
            sub={state.alert.sub}
            isOk={state.alert.isOk}
            isCancel={state.alert.isCancel}
          />
        ) : null}
      </SignupSt>
    </>
  );
};

export default Signup;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// json
import jsonData from "Assets/contents/contents.json";

// components
import { Modal, Alert, Pager, LmsNav } from "Components";
import { setImageFromFile, checkFileSize } from "Utils/lib/helper";

// select
import { selectStyles, DropdownIndicator } from "Styles/select.style";
import Select, { components } from "react-select";

// img
import NewsbuilderSymbol from "Assets/images/icons/newsbuilder_symbol.svg";
import NewsbuilderPlane from "Assets/images/icons/newsbuilder_plane.svg";
import NewsBuilderPicture from "Assets/images/icons/newsbuilder_picture_icon.svg";
import NewsBuilderChange from "Assets/images/icons/newsbuilder_picture_change_icon.svg";
import AlertIcon from "Assets/images/icons/alert_icon.svg";
import NewsBuilderSaveIcon from "Assets/images/icons/newsbuilder_save_icon.svg";
import NewsBuilderCalendar from "Assets/images/icons/newsbuilder_save_calendar.svg";
import NewsBuilderPeople from "Assets/images/icons/newsbuilder_save_people.svg";
import NewsBuilderGrade from "Assets/images/icons/newsbuilder_save_grade.svg";
import DownloadIcon from "Assets/images/icons/download_icon.svg";
import ShareIcon from "Assets/images/icons/share_icon.svg";
import NewsLetterBuilderHow from "Assets/images/icons/newsletterbuilder_how.svg";
import Logo from "Assets/images/icons/newsbuilder_loco.svg";
import SimpleLogo from "Assets/images/icons/simple_logo.svg";
import Mail from "Assets/images/icons/newsbuilder_mail.svg";
import NewsLetterBuilderMail from "Assets/images/icons/newsletterbuilder_mail.svg";
import NewsLetterBuilderSymbol from "Assets/images/icons/newsletterbuilder_symbol.svg";
import NewsBuilderSymbol1 from "Assets/images/icons/newsbuilder_symbol1.svg";
import NewsBuilderSymbol2 from "Assets/images/icons/newsbuilder_symbol2.svg";
import WebsiteIcon from "Assets/images/icons/website_icon.svg";
import InstaIcon from "Assets/images/icons/insta_icon.svg";
import TempSaveIcon from "Assets/images/icons/temp_save.svg";
import SaveWhiteIcon from "Assets/images/icons/save_icon_white.svg";
import BackIcon from "Assets/images/icons/back_icon.svg";
import NewsBuilderClip from "Assets/images/icons/newsbuilder_ic_clip.svg";

// styles
import {
  LmsSt,
  LmsDashboard,
  NewsBuilderPage,
  NewsBuilderSave,
  NewsLetterTemplate,
  NewLetterHeader,
  NewsLetterBuilderSave,
} from "../Lms/Lms.style";
import { BasicButton } from "Styles/styles";

// logics
import BuilderLogic from "./Builder.logic";
import moment from "moment";

const Builder = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: "",
    status: "",
    id: "",
  });
  useEffect(() => {
    setUrlState({
      current: location.pathname,
      type: location.pathname.split("/builder/")[1].split("/")[0],
      status: location.pathname
        .split("/builder/")[1]
        .split("/")[1]
        .split("/")[0],
      id: location.pathname.split(
        `/${location.pathname.split("/builder/")[1].split("/")[1]}/`
      )[1],
    });
  }, [location.pathname]);
  console.log(urlState);
  const { state, ref, data, func } = BuilderLogic({
    loading,
    setLoading,
    navigate,
    url: {
      current: location.pathname,
      type: location.pathname.split("/builder/")[1].split("/")[0],
      status: location.pathname
        .split("/builder/")[1]
        .split("/")[1]
        .split("/")[0],
      id: location.pathname.split(
        `/${location.pathname.split("/builder/")[1].split("/")[1]}/`
      )[1],
    },
  });

  return (
    <>
      <LmsSt>
        {urlState.status === "temp" ? (
          <LmsNav urlLocation={"builder"} url={urlState} func={func} />
        ) : null}
        {urlState.status === "temp" ? (
          <>
            {urlState.type === "news" && !state.data_loading ? (
              <>
                <LmsDashboard>
                  <img
                    src={NewsBuilderSymbol1}
                    alt="뉴스빌더심볼이미지"
                    className="newsbuilder_symbol_img1"
                  />
                  <img
                    src={NewsBuilderSymbol2}
                    alt="뉴스빌더심볼이미지"
                    className="newsbuilder_symbol_img2"
                  />
                  <NewsBuilderPage type={urlState.type}>
                    <div>
                      <h1>나의 뉴스 빌더</h1>
                      <p>
                        매주 1개씩 나만의 뉴스를 작성할 수 있어요! <br />
                        작성한 뉴스를 모아 나만의 뉴스레터를 발간해보세요.
                      </p>
                    </div>
                    <img src={NewsbuilderPlane} alt="뉴스빌더 비행기 아이콘" />
                    <img src={NewsbuilderSymbol} alt="뉴스빌더 심볼 아이콘" />
                  </NewsBuilderPage>
                  <div className="newsbuilder_type">
                    <div
                      onClick={() => window.open(jsonData.about_url, "_blank")}
                      className="newsbuilder_how"
                    >
                      <img src={NewsBuilderClip} alt="클립 아이콘" />
                      <p>뉴스는 어떻게 작성하나요?</p>
                    </div>
                    <div>
                      <h6>분야1</h6>
                      <div className="selectbox">
                        <Select
                          placeholder="분야1을 선택해주세요"
                          components={{ DropdownIndicator }}
                          styles={selectStyles}
                          width={330}
                          height={48}
                          options={data.filterList.first_category}
                          onChange={(value) =>
                            func.setInputStateValue(
                              "first_category",
                              value.value
                            )
                          }
                          defaultValue={
                            data.filterList.first_category.filter(
                              (i) => i.value === data.inputData.first_category
                            )[0]
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <h6>분야2</h6>
                      <div className="selectbox">
                        <Select
                          placeholder="분야2를 선택해주세요"
                          components={{ DropdownIndicator }}
                          styles={selectStyles}
                          width={330}
                          height={48}
                          options={data.filterList.second_category}
                          onChange={(value) =>
                            func.setInputStateValue(
                              "second_category",
                              value.value
                            )
                          }
                          defaultValue={
                            data.filterList.second_category.filter(
                              (i) => i.value === data.inputData.second_category
                            )[0]
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <h6>뉴스 제목</h6>
                      <input
                        type="text"
                        placeholder="뉴스 제목을 입력해주세요 (최대 40자)"
                        onChange={({ target: { value } }) =>
                          func.setInputStateValue("title", value)
                        }
                        defaultValue={data.inputData.title}
                        maxLength={40}
                      />
                    </div>
                  </div>
                  <div className="newsbuilder_type">
                    <div>
                      <h6>사진</h6>
                      {data.inputData.image_id ? (
                        <div
                          className="imgbox_upload"
                          onClick={() =>
                            func.setModalData(
                              true,
                              "picture_select",
                              "image_id"
                            )
                          }
                        >
                          <img
                            src={data.inputData.image_id_url}
                            alt="뉴스빌더 업로드 이미지"
                          />
                          <span>
                            <div>
                              <img
                                src={NewsBuilderChange}
                                alt="사진 선택"
                                className="newsbuilder_picture_icon"
                              />
                              <p>사진 변경</p>
                            </div>
                          </span>
                        </div>
                      ) : (
                        <div
                          className="imgbox"
                          onClick={() =>
                            func.setModalData(
                              true,
                              "picture_select",
                              "image_id"
                            )
                          }
                        >
                          <div>
                            <img
                              src={NewsBuilderPicture}
                              alt="사진 선택"
                              className="newsbuilder_picture_icon"
                            />
                            <p>사진 선택</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <h6>뉴스 내용</h6>
                      {/* textarea의 red테두리의 className은 red_textarea입니다. */}
                      <div className="textarea_box">
                        <textarea
                          cols="30"
                          rows="10"
                          maxLength={450}
                          placeholder="뉴스 내용을 자유롭게 작성해보세요 (최대 450자)"
                          onChange={({ target: { value } }) =>
                            func.setInputStateValue("content", value)
                          }
                          defaultValue={data.inputData.content}
                        ></textarea>
                      </div>
                      <span className="max_length">
                        {data.inputData.content.length}/450
                      </span>
                      <div className="alert_text">
                        <img src={AlertIcon} alt="경고아이콘" />
                        작성하는 동안 꼭 저장하세요!
                      </div>
                      <button onClick={() => func.saveNews("temp")}>
                        임시 저장
                      </button>
                    </div>
                  </div>
                  <div className="newsbuilder_type">
                    <div className="recommend_write">
                      <div className="alert_text">
                        <img src={AlertIcon} alt="경고아이콘" />
                        학년별 추천 글자 수
                      </div>
                      <div>
                        <span>1학년</span> 250자
                      </div>
                      <div>
                        <span>2학년</span> 350자
                      </div>
                      <div>
                        <span>3학년 - 6학년</span> 450자
                      </div>
                    </div>
                    <button
                      className="save_button"
                      onClick={() => func.saveNews("save")}
                    >
                      <img
                        src={NewsBuilderSaveIcon}
                        alt="뉴스빌더 저장 아이콘"
                      />
                      저장하고 발간하기
                    </button>
                  </div>
                </LmsDashboard>
              </>
            ) : null}
            {urlState.type === "newsletter" ? (
              <>
                <NewLetterHeader>
                  <ul>
                    <li onClick={() => navigate(-1)}>
                      <img src={BackIcon} alt="Lms돌아가기 아이콘" />
                      <p> LMS 돌아가기</p>
                    </li>
                  </ul>
                  <div>
                    <ul>
                      <li onClick={() => func.saveNewsLetter("temp")}>
                        <img src={TempSaveIcon} alt="임시저장 아이콘" />
                        <p>임시저장</p>
                      </li>
                      <li
                        onClick={() => func.saveNewsLetter("save")}
                        // onClick={() => navigate("/builder/newsletter/save")}
                      >
                        <img src={SaveWhiteIcon} alt="화이트 저장 아이콘" />
                        <p>저장하고 발간하기</p>
                      </li>
                    </ul>
                  </div>
                </NewLetterHeader>
                <LmsDashboard>
                  <NewsBuilderPage type={urlState.type}>
                    <div>
                      <h1>나의 뉴스레터 빌더</h1>
                      <p>
                        매월 내 이름으로 된 뉴스레터를 만들어 가족, 친구들에게
                        보내주세요! <br /> 내가 읽은 기사 외에도 책 소개, 가족
                        소식 등 다양한 소식을 담아보세요!
                        <button>
                          <img
                            src={NewsLetterBuilderHow}
                            alt="뉴스레터 작성법 아이콘"
                          />
                          <p>뉴스레터는 어떻게 작성하나요?</p>
                        </button>
                      </p>
                    </div>
                    <img src={NewsbuilderPlane} alt="뉴스빌더 비행기 아이콘" />
                    <img src={NewsbuilderSymbol} alt="뉴스빌더 심볼 아이콘" />
                  </NewsBuilderPage>
                  <NewsLetterTemplate>
                    <img
                      src={NewsLetterBuilderSymbol}
                      alt="뉴스레터 빌더 심볼"
                      className="newsletter_builder_symbol"
                    />
                    <div className="newsletter_builder_wrap">
                      <div className="header">
                        <img src={Logo} alt="뉴스레터탬플릿 로고" />
                        <div>
                          <img src={Mail} alt="메일아이콘" />
                          <span>{moment().format("YYYY년 MM월호")}</span>
                        </div>
                      </div>
                      <div className="title">
                        <img
                          src={NewsLetterBuilderMail}
                          alt="뉴스레터 빌더 메일 아이콘"
                        />
                        <input
                          type="text"
                          placeholder="뉴스레터의 제목을 지어주세요"
                          onChange={({ target: { value } }) =>
                            func.setInputStateValue("title", value)
                          }
                          defaultValue={data.inputData.title}
                        />
                        <div>
                          <img src={SimpleLogo} alt="심플 로고" />
                          <p>{localStorage.getItem("user_nick_name")} 기자</p>
                        </div>
                      </div>

                      {/* 뉴스레터 빌터 컴포넌트1 */}
                      <div className="newsletter_component">
                        <div className="imgbox">
                          {/* 뉴스레터 빌터 컴포넌트1 - 사진 선택 */}
                          {data.inputData.image_01_id ? (
                            <div
                              className="imgbox_upload"
                              onClick={() =>
                                func.setModalData(
                                  true,
                                  "picture_select",
                                  "image_01_id"
                                )
                              }
                            >
                              <img
                                src={data.inputData.image_01_id_url}
                                alt="뉴스빌더 업로드 이미지"
                              />
                              <span>
                                <div>
                                  <img
                                    src={NewsBuilderChange}
                                    alt="사진 선택"
                                    className="newsbuilder_picture_icon"
                                  />
                                  <p>사진 변경</p>
                                </div>
                              </span>
                            </div>
                          ) : (
                            <div
                              className="imgbox"
                              onClick={() =>
                                func.setModalData(
                                  true,
                                  "picture_select",
                                  "image_01_id"
                                )
                              }
                            >
                              <div>
                                <img
                                  src={NewsBuilderPicture}
                                  alt="사진 선택"
                                  className="newsbuilder_picture_icon"
                                />
                                <p>사진 선택</p>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* 뉴스레터 빌터 컴포넌트1 - 업로드 된 이미지 , hover시 이미지 재선택 */}
                        <div className="news_bring">
                          <input
                            type="text"
                            placeholder="제목을 작성해주세요."
                            onChange={({ target: { value } }) =>
                              func.setInputStateValue("title_01", value)
                            }
                            defaultValue={data.inputData.title_01}
                          />
                          <div>
                            <textarea
                              cols="30"
                              rows="10"
                              maxLength={450}
                              placeholder="뉴스 내용을 자유롭게 작성해보세요 (최대 450자)"
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("content_01", value)
                              }
                              defaultValue={data.inputData.content_01}
                            ></textarea>
                            <span className="max_length">
                              {data.inputData.content_01.length}/450
                            </span>
                          </div>
                          <button
                            onClick={() =>
                              func.setModalData(true, "my_news", 1)
                            }
                          >
                            나의 뉴스 불러오기
                          </button>
                        </div>
                      </div>
                      {/* 뉴스레터 빌터 컴포넌트2 */}
                      <div className="newsletter_component">
                        <div className="imgbox">
                          {/* 뉴스레터 빌터 컴포넌트2 - 사진 선택 */}
                          {data.inputData.image_02_id ? (
                            <div
                              className="imgbox_upload"
                              onClick={() =>
                                func.setModalData(
                                  true,
                                  "picture_select",
                                  "image_02_id"
                                )
                              }
                            >
                              <img
                                src={data.inputData.image_02_id_url}
                                alt="뉴스빌더 업로드 이미지"
                              />
                              <span>
                                <div>
                                  <img
                                    src={NewsBuilderChange}
                                    alt="사진 선택"
                                    className="newsbuilder_picture_icon"
                                  />
                                  <p>사진 변경</p>
                                </div>
                              </span>
                            </div>
                          ) : (
                            <div
                              className="imgbox"
                              onClick={() =>
                                func.setModalData(
                                  true,
                                  "picture_select",
                                  "image_02_id"
                                )
                              }
                            >
                              <div>
                                <img
                                  src={NewsBuilderPicture}
                                  alt="사진 선택"
                                  className="newsbuilder_picture_icon"
                                />
                                <p>사진 선택</p>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* 뉴스레터 빌터 컴포넌트2 - 업로드 된 이미지 , hover시 이미지 재선택 */}
                        <div className="news_bring">
                          <input
                            type="text"
                            placeholder="제목을 작성해주세요."
                            onChange={({ target: { value } }) =>
                              func.setInputStateValue("title_02", value)
                            }
                            defaultValue={data.inputData.title_02}
                          />
                          <div>
                            <textarea
                              cols="30"
                              rows="10"
                              maxLength={450}
                              placeholder="뉴스 내용을 자유롭게 작성해보세요 (최대 450자)"
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("content_02", value)
                              }
                              defaultValue={data.inputData.content_02}
                            ></textarea>
                            <span className="max_length">
                              {data.inputData.content_02.length}/450
                            </span>
                          </div>

                          <button
                            onClick={() =>
                              func.setModalData(true, "my_news", 2)
                            }
                          >
                            나의 뉴스 불러오기
                          </button>
                        </div>
                      </div>
                      {/* 뉴스레터 빌터 컴포넌트3 */}
                      <div className="newsletter_component">
                        <div className="imgbox">
                          {/* 뉴스레터 빌터 컴포넌트3 - 사진 선택 */}

                          {data.inputData.image_03_id ? (
                            <div
                              className="imgbox_upload"
                              onClick={() =>
                                func.setModalData(
                                  true,
                                  "picture_select",
                                  "image_03_id"
                                )
                              }
                            >
                              <img
                                src={data.inputData.image_03_id_url}
                                alt="뉴스빌더 업로드 이미지"
                              />
                              <span>
                                <div>
                                  <img
                                    src={NewsBuilderChange}
                                    alt="사진 선택"
                                    className="newsbuilder_picture_icon"
                                  />
                                  <p>사진 변경</p>
                                </div>
                              </span>
                            </div>
                          ) : (
                            <div
                              className="imgbox"
                              onClick={() =>
                                func.setModalData(
                                  true,
                                  "picture_select",
                                  "image_03_id"
                                )
                              }
                            >
                              <div>
                                <img
                                  src={NewsBuilderPicture}
                                  alt="사진 선택"
                                  className="newsbuilder_picture_icon"
                                />
                                <p>사진 선택</p>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* 뉴스레터 빌터 컴포넌트3 - 업로드 된 이미지 , hover시 이미지 재선택 */}
                        <div className="news_bring">
                          <input
                            type="text"
                            placeholder="제목을 작성해주세요."
                            onChange={({ target: { value } }) =>
                              func.setInputStateValue("title_03", value)
                            }
                            defaultValue={data.inputData.title_03}
                          />
                          <div>
                            <textarea
                              cols="30"
                              rows="10"
                              maxLength={450}
                              placeholder="뉴스 내용을 자유롭게 작성해보세요 (최대 450자)"
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("content_03", value)
                              }
                              defaultValue={data.inputData.content_03}
                            ></textarea>
                            <span className="max_length">
                              {data.inputData.content_03.length}/450
                            </span>
                          </div>

                          <button
                            onClick={() =>
                              func.setModalData(true, "my_news", 3)
                            }
                          >
                            나의 뉴스 불러오기
                          </button>
                        </div>
                      </div>

                      <div className="newsletter_special_component">
                        {/* 뉴스레터 빌터 컴포넌트4 */}
                        <div className="picture_component">
                          <div>
                            <span className="special_tag">스페셜 뉴스</span>
                            <input
                              type="text"
                              placeholder="제목을 작성해주세요."
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("title_04", value)
                              }
                              defaultValue={data.inputData.title_04}
                            />
                          </div>
                          <div className="narrow_component">
                            {data.inputData.image_04_id ? (
                              <div
                                className="imgbox_narrow"
                                onClick={() =>
                                  func.setModalData(
                                    true,
                                    "picture_select",
                                    "image_04_id"
                                  )
                                }
                              >
                                <img
                                  src={data.inputData.image_04_id_url}
                                  alt="뉴스빌더 업로드 이미지"
                                />
                                <span>
                                  <div>
                                    <img
                                      src={NewsBuilderChange}
                                      alt="사진 선택"
                                      className="newsbuilder_picture_icon"
                                    />
                                    <p>사진 변경</p>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              <div
                                className="imgbox imgbox_narrow"
                                onClick={() =>
                                  func.setModalData(
                                    true,
                                    "picture_select",
                                    "image_04_id"
                                  )
                                }
                              >
                                <div>
                                  <img
                                    src={NewsBuilderPicture}
                                    alt="사진 선택"
                                    className="newsbuilder_picture_icon"
                                  />
                                  <p>사진 선택</p>
                                </div>
                              </div>
                            )}
                            <div className="textarea">
                              <textarea
                                cols="20"
                                rows="10"
                                maxLength={200}
                                placeholder="뉴스 내용을 자유롭게 작성해보세요 (최대 200자)"
                                onChange={({ target: { value } }) =>
                                  func.setInputStateValue("content_04", value)
                                }
                                defaultValue={data.inputData.content_04}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        {/* 뉴스레터 빌터 컴포넌트5 */}
                        <div className="picture_component">
                          <div>
                            <span className="special_tag">스페셜 뉴스</span>
                            <input
                              type="text"
                              placeholder="제목을 작성해주세요."
                              onChange={({ target: { value } }) =>
                                func.setInputStateValue("title_05", value)
                              }
                              defaultValue={data.inputData.title_05}
                            />
                          </div>
                          <div className="narrow_component">
                            <div className="textarea">
                              <textarea
                                cols="20"
                                rows="10"
                                maxLength={200}
                                placeholder="뉴스 내용을 자유롭게 작성해보세요 (최대 200자)"
                                onChange={({ target: { value } }) =>
                                  func.setInputStateValue("content_05", value)
                                }
                                defaultValue={data.inputData.content_05}
                              ></textarea>
                            </div>
                            {data.inputData.image_05_id ? (
                              <div
                                className="imgbox_narrow"
                                onClick={() =>
                                  func.setModalData(
                                    true,
                                    "picture_select",
                                    "image_05_id"
                                  )
                                }
                              >
                                <img
                                  src={data.inputData.image_05_id_url}
                                  alt="뉴스빌더 업로드 이미지"
                                />
                                <span>
                                  <div>
                                    <img
                                      src={NewsBuilderChange}
                                      alt="사진 선택"
                                      className="newsbuilder_picture_icon"
                                    />
                                    <p>사진 변경</p>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              <div
                                className="imgbox imgbox_narrow"
                                onClick={() =>
                                  func.setModalData(
                                    true,
                                    "picture_select",
                                    "image_05_id"
                                  )
                                }
                              >
                                <div>
                                  <img
                                    src={NewsBuilderPicture}
                                    alt="사진 선택"
                                    className="newsbuilder_picture_icon"
                                  />
                                  <p>사진 선택</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="newsletter_builder_footer">
                      <div>
                        <img src={Logo} alt="뉴스레터탬플릿 로고" />
                        <ul>
                          <li>
                            <img src={WebsiteIcon} alt="웹 사이트 아이콘" />
                            <p>https://herewigg.com/</p>
                          </li>
                          <li>
                            <img src={InstaIcon} alt="인스타 아이콘" />
                            <p>https://www.instagram.com/herewigg/</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </NewsLetterTemplate>
                </LmsDashboard>
              </>
            ) : null}
          </>
        ) : (
          <>
            {/* 발간된 뉴스빌더 */}
            <div className="news_builder_bg">
              {urlState.type === "news" && data.inputData.news !== [] ? (
                <NewsBuilderSave>
                  <div id="news" ref={ref.ref}>
                    <ul className="tag_list">
                      {data.inputData.news.first_categories ? (
                        <li>
                          {data.inputData.news.first_categories.category_name}
                        </li>
                      ) : null}
                      {data.inputData.news.second_categories ? (
                        <li>
                          {data.inputData.news.second_categories.category_name}
                        </li>
                      ) : null}
                    </ul>
                    <h1>{data.inputData.news.title}</h1>
                    <ul className="info">
                      <li>
                        <img src={NewsBuilderCalendar} alt="캘린더 아이콘" />
                        <p>
                          {moment(data.inputData.news.published_at).format(
                            "YYYY.MM.DD."
                          )}
                        </p>
                      </li>
                      <li>
                        <img src={NewsBuilderPeople} alt="사용자 아이콘" />
                        <p>
                          {data.inputData.news.users &&
                            data.inputData.news.users.nick_name}
                        </p>
                      </li>
                      <li>
                        <img src={NewsBuilderGrade} alt="학년 아이콘" />
                        <p>
                          {data.inputData.news.contents &&
                            data.inputData.news.contents[0].grade}
                          학년
                        </p>
                      </li>
                    </ul>
                    {data.inputData.news.contents &&
                      data.inputData.news.contents.map((item) => (
                        <>
                          <img
                            src={item.images.image_url}
                            alt="뉴스빌더 업로드 이미지"
                            className="news_builder_img"
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.content.replaceAll("\n", "<br/>"),
                            }}
                          ></p>
                        </>
                      ))}
                  </div>

                  <div className="btns">
                    <button
                      onClick={() =>
                        func.getNewDownload(data.inputData.news.title, "news")
                      }
                    >
                      <img src={DownloadIcon} alt="다운로드 아이콘" />
                      <p>다운로드</p>
                    </button>
                    <button onClick={() => func.getNewsUrl("news")}>
                      <img src={ShareIcon} alt="공유 아이콘" />
                      <p>공유하기</p>
                    </button>
                  </div>
                </NewsBuilderSave>
              ) : null}
            </div>

            <div className="newsletter_builder_bg">
              {/* 발간된 뉴스레터 빌더 */}
              {urlState.type === "newsletter" &&
              data.inputData.letter !== [] ? (
                <NewsLetterBuilderSave>
                  {data.inputData.letter.letter ? (
                    <NewsLetterTemplate id="news" ref={ref.ref}>
                      <div>
                        <img
                          src={NewsLetterBuilderSymbol}
                          alt="뉴스레터 빌더 심볼"
                          className="newsletter_builder_symbol"
                        />
                        <div className="newsletter_builder_wrap">
                          <div className="header">
                            <img src={Logo} alt="뉴스레터탬플릿 로고" />
                            <div>
                              <img src={Mail} alt="메일아이콘" />
                              <span>
                                {data.inputData.letter.letter.year}년{" "}
                                {data.inputData.letter.letter.month}월호
                              </span>
                            </div>
                          </div>
                          <div className="title">
                            <img
                              src={NewsLetterBuilderMail}
                              alt="뉴스레터 빌더 메일 아이콘"
                            />
                            <h1>{data.inputData.letter.letter.title}</h1>
                            <div>
                              <img src={SimpleLogo} alt="심플 로고" />
                              <p>
                                {data.inputData.letter.letter.users.nick_name}{" "}
                                기자
                              </p>
                            </div>
                          </div>
                          {data.inputData.letter.contents &&
                          data.inputData.letter.contents.length > 0 ? (
                            data.inputData.letter.contents.map((item) => {
                              if (item.contents.image_location === "left") {
                                return (
                                  <div
                                    key={`content_${item.contents.id}`}
                                    className="newsletter_special_component"
                                  >
                                    <div className="picture_component">
                                      <div>
                                        <span className="special_tag">
                                          스페셜 뉴스
                                        </span>
                                        <h2>{item.contents.title}</h2>
                                      </div>
                                      <div className="narrow_component">
                                        <div className="imgbox_narrow">
                                          <div className="imgbox_upload_done">
                                            <img
                                              src={
                                                item.contents.images.image_url
                                              }
                                              alt={`${item.contents.title} 이미지`}
                                            />
                                          </div>
                                        </div>
                                        <div className="text_box">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item.contents.content.replaceAll(
                                                  "\n",
                                                  "<br/>"
                                                ),
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (
                                item.contents.image_location === "right"
                              ) {
                                return (
                                  <div
                                    key={`content_${item.contents.id}`}
                                    className="newsletter_special_component"
                                  >
                                    <div className="picture_component">
                                      <div>
                                        <span className="special_tag">
                                          스페셜 뉴스
                                        </span>
                                        <h2>{item.contents.title}</h2>
                                      </div>
                                      <div className="narrow_component">
                                        <div className="text_box">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item.contents.content.replaceAll(
                                                  "\n",
                                                  "<br/>"
                                                ),
                                            }}
                                          ></p>
                                        </div>
                                        <div className="imgbox_narrow">
                                          <div className="imgbox_upload_done">
                                            <img
                                              src={
                                                item.contents.images.image_url
                                              }
                                              alt={`${item.contents.title} 이미지`}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={`content_${item.contents.id}`}
                                    className="newsletter_component"
                                  >
                                    <div className="imgbox_upload_done">
                                      <img
                                        src={item.contents.images.image_url}
                                        alt={`${item.contents.title} 이미지`}
                                      />
                                    </div>
                                    <div className="news_bring">
                                      <h2>{item.contents.title}</h2>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.contents.content.replaceAll(
                                              "\n",
                                              "<br/>"
                                            ),
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <div>뉴스 내용이 없습니다.</div>
                          )}
                        </div>
                        <div className="newsletter_builder_footer">
                          <div>
                            <img src={Logo} alt="뉴스레터탬플릿 로고" />
                            <ul>
                              <li>
                                <img src={WebsiteIcon} alt="웹 사이트 아이콘" />
                                <p>https://herewigg.com/</p>
                              </li>
                              <li>
                                <img src={InstaIcon} alt="인스타 아이콘" />
                                <p>https://www.instagram.com/herewigg/</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </NewsLetterTemplate>
                  ) : null}
                  <div className="btns">
                    <button
                      onClick={() =>
                        func.getNewDownload(
                          data.inputData.letter.letter.title,
                          "newsletter"
                        )
                      }
                    >
                      <img src={DownloadIcon} alt="다운로드 아이콘" />
                      <p>다운로드</p>
                    </button>
                    <button onClick={() => func.getNewsUrl("letter")}>
                      <img src={ShareIcon} alt="공유 아이콘" />
                      <p>공유하기</p>
                    </button>
                  </div>
                </NewsLetterBuilderSave>
              ) : null}
            </div>
          </>
        )}
      </LmsSt>

      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}

      {/* 링크복사 모달*/}
      {state.modal.open && state.modal.type === "copy" ? (
        <Modal
          minWidth={339}
          maxHeight={287}
          borderRadius={12}
          // setmodal={() => func.setModalData(!state.modal.open, null, null)}

          data={
            <>
              <div className="modal_style link_copy">
                <h3>링크가 자동으로 복사되었어요!</h3>
                <h4>링크를 보내고싶은 곳에 붙여넣기를 해보세요.</h4>
                <div className="btns">
                  <BasicButton
                    className="main"
                    onClick={() =>
                      func.setModalData(!state.modal.open, null, null)
                    }
                  >
                    확인
                  </BasicButton>
                </div>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}

      {/* 사진선택 모달*/}
      {state.modal.open && state.modal.type === "picture_select" ? (
        <Modal
          minWidth={1100}
          maxHeight={716}
          borderRadius={12}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="picture_select">
                <h5>사진 선택</h5>
                <h6>뉴스와 어울리는 사진을 선택해주세요!</h6>
                <div>
                  <div className="search_box">
                    <i className="xi-search"></i>
                    <input
                      type="text"
                      placeholder="이미지를 검색해보세요"
                      onChange={({ target: { value } }) =>
                        func.setStateValue("search_keyword", value)
                      }
                      onKeyPress={(e) => {
                        if (e.key !== "Enter") return;
                        if (e.shiftKey) return;
                        e.preventDefault();
                        func.searchList();
                      }}
                    />
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    id="image_add"
                    accept="image/jpeg, image/png"
                    onChange={({ target: { files } }) => {
                      if (files.length) {
                        if (checkFileSize(10, files[0].size)) {
                          setImageFromFile({
                            file: files[0],
                            setImageUrl: ({ result }) =>
                              func.imageUpload("save", files[0]),
                          });
                        } else {
                          func.setStateValue("alert", {
                            title: "10MB 이하의 파일만 업로드 가능합니다.",
                            sub: null,
                            isOk: () => func.setStateValue("alert", null),
                            isCancel: null,
                          });
                          return;
                        }
                      }
                    }}
                  />
                  <label htmlFor="image_add" className="image_add">
                    내 사진 업로드하기
                  </label>
                </div>

                {data.filterList.image_list.length > 0 ? (
                  <div className="picture_list">
                    <h6>모든 사진</h6>
                    <ul>
                      {data.filterList.image_list.map((item) => (
                        <li
                          key={`image_${item.id}`}
                          onClick={() =>
                            func.setInputStateValue(
                              "image",
                              item.id,
                              item.image_url
                            )
                          }
                        >
                          <img src={item.image_url} alt="사진 업로드 이미지" />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="search_none">
                    <div>
                      <i className="xi-search"></i>
                      <h4>검색 결과가 없어요</h4>
                      <p>
                        검색어를 다시 한번 확인하거나 다른 단어로 검색해보세요!
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <Pager
                page={data.currentPage}
                count={10}
                total={data.filterList.image_total}
                paging={func.paging}
              />
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}

      {/* 나의 뉴스 불러오기 모달*/}
      {state.modal.open && state.modal.type === "my_news" ? (
        <Modal
          minWidth={720}
          maxHeight={600}
          borderRadius={12}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="mynews_select">
                <h5>나의 뉴스</h5>
                <div>
                  <Select
                    placeholder="2023년"
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={150}
                    height={40}
                    options={data.filterList.year_list}
                    defaultValue={
                      data.filterList.year_list.filter(
                        (i) => i.value === state.news_year
                      )[0]
                    }
                    onChange={(value) =>
                      func.setStateValue("news_year", value.value)
                    }
                  />
                  <Select
                    placeholder="1월"
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={150}
                    height={40}
                    options={data.filterList.month_list}
                    defaultValue={
                      data.filterList.month_list.filter(
                        (i) => i.value === state.news_month
                      )[0]
                    }
                    onChange={(value) =>
                      func.setStateValue("news_month", value.value)
                    }
                  />
                </div>
                <div className="mynews_list">
                  <ul>
                    {data.filterList.news_list.length > 0 ? (
                      data.filterList.news_list.map((item) => (
                        <li>
                          {item.contents !== [] ? (
                            <img
                              src={item.contents[0].images.image_url}
                              alt="사진 업로드 이미지"
                            />
                          ) : (
                            <div></div>
                          )}

                          <div>
                            <div className="tagbox">
                              {item.first_categories ? (
                                <span>
                                  {item.first_categories.category_name}
                                </span>
                              ) : null}
                              {item.second_categories ? (
                                <span>
                                  {item.second_categories.category_name}
                                </span>
                              ) : null}
                            </div>
                            <h5>{item.title ? item.title : "제목없음"}</h5>
                            {item.published_at ? (
                              <h6>
                                {moment(item.published_at).format(
                                  "YYYY.MM.DD."
                                )}
                              </h6>
                            ) : null}
                            <button onClick={() => func.setNews(item)}>
                              불러오기
                            </button>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li>뉴스가 없습니다.</li>
                    )}
                  </ul>
                  <Pager
                    page={data.currentPage}
                    count={12}
                    total={data.filterList.news_total}
                    paging={func.paging}
                  />
                </div>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}
    </>
  );
};

export default Builder;

import React, { useState, useEffect } from "react";

//lib
import { scrollTop, phoneNumToString } from "Utils/lib/helper";

// img

const HomeLogic = ({ loading, setLoading }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    user_list: [],
    search_keyword: "",
    modal: { open: false, type: "", id: "" },
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (state.search_keyword === "") {
      getList();
      scrollTop();
    }
  }, [state.search_keyword, currentPage]);

  // 데이터 list 관리
  const dataList = {};

  ////////////////  API  ////////////////
  // 목록 조회
  const getList = async () => {};
  const onClickLink = async (type) => {
    if (type === "signup") {
      if (localStorage.getItem("user_token")) {
        setStateValue("alert", {
          title: "이미 가입된 회원입니다.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } else {
        window.location.href = "/signup/info";
      }
    } else {
      if (localStorage.getItem("type") === "child") {
        setStateValue("alert", {
          title: "결제는 학부모 회원만 가능합니다.",
          sub: "학부모 계정으로 로그인하여 결제해주시길 바랍니다.",
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } else {
        window.location.href = `/subscribe/payment/${type}`;
      }
    }
  };
  // keyword로 검색 결과 셋팅
  const searchList = () => {
    console.log(state.search_keyword);
    if (state.search_keyword === "" || state.search_keyword.length < 2) {
      alert("키워드는 두글자 이상으로 검색 바랍니다.");
      return;
    } else {
      getList();
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };

  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };
  return {
    state,
    data: { dataList, currentPage },
    func: { setStateValue, setModalData, paging, searchList, onClickLink },
  };
};

export default HomeLogic;

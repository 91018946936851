import styled from "styled-components";

export const Header = styled.header`
  width: 220px;
  min-width: 220px;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--main);
  background-color: #ffffff;
  box-shadow: 1px 0px 0px #e4e4e4;
  padding: 26px 20px;
  z-index: 20;
  text-align: center;
  line-height: 140%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0.6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }

  > ul {
    text-align: left;
    margin-top: 26px;

    > li {
      cursor: pointer;
      line-height: 48px;
      font-size: 14px;
      vertical-align: middle;
      position: relative;
      color: var(--grey2);
      h3 {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        color: var(--grey1);
        font-size: 14px;
        padding: 14px 0;
        width: calc(100% - 30px);
        font-weight: 400;
      }
      > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        vertical-align: middle;
      }
      ul {
        margin-left: 30px;
        display: none;
        li {
          padding: 14px 0;
          color: var(--grey2);
          &.on,
          &:hover {
            color: var(--text);
            font-weight: 700;
          }
        }
        &.on {
          display: block;
        }
      }
      button {
        position: absolute;
        right: 0;
        top: 16px;
        width: 6px;
        height: 12px;
        object-fit: contain;
        transition: 0.25s ease-in;
        &.on {
          img {
            transform: rotate(90deg);
          }
        }
      }
      &.on,
      &:hover {
        border-radius: 4px;
        h3 {
          color: var(--text);
          font-weight: 700;
        }
      }
    }
  }
`;

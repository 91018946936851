// selector의 스크롤 상단 이동
export function scrollTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

// selector의 스크롤 상단 이동
export function scrollBottom(selector) {
  document.querySelector(selector).scrollTo(0, 0);
}

// selector의 스크롤 하단 이동
export function scrollToBottom(selector) {
  const ele = document.querySelector(selector);
  ele.scrollTop = ele.scrollHeight;
}

// 을(를) 구별
export const koreanEulReulReturner = (str) => {
  let strGa = 44032;
  let strHih = 55203;
  let lastStrCode = str.charCodeAt(str.length - 1);
  let prop = true;
  let result;

  if (lastStrCode < strGa || lastStrCode > strHih) return false;
  if ((lastStrCode - strGa) % 28 === 0) prop = false;

  prop ? (result = str + "을") : (result = str + "를");

  return result;
};

// 가격 문자열로 변경
export function numberToPrice(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 문자열에서 숫자만 추출
export function priceToNumber(x) {
  return x.replace(/[^0-9]/g, "");
}

// 폼데이터 생성
export const setFormData = (data) => {
  const form = new FormData();
  for (let v in data) {
    form.append(v, data[v]);
  }
  return form;
};

// 확인 얼럿
export const isConfirmOk = (type) => {
  let isOk = window.confirm(type);
  if (isOk) {
    return true;
  } else {
    return false;
  }
};
// 삭제 얼럿
export const isDeleteOk = (type) => {
  let isOk = window.confirm(`${type} 삭제하시겠습니까?`);
  if (isOk) {
    return true;
  } else {
    return false;
  }
};
// 수정 얼럿
export const isEditOk = (type) => {
  let isOk = window.confirm(`${type} 수정하시겠습니까?`);
  if (isOk) {
    return true;
  } else {
    return false;
  }
};

// file 객체와 image 선택 시 실행할 함수 입력 시 -> 이미지 업로드 되면 함수 실행
export const setImageFromFile = ({ file, setImageUrl }) => {
  let reader = new FileReader();
  reader.onload = function () {
    setImageUrl({ result: reader.result });
  };
  reader.readAsDataURL(file);
};

// 데이터 값 검증
export const getDataType = (item) => {
  var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
  if (item !== null) {
    if (Array.isArray(item)) {
      const arr = item.map((i, idx) => <span key={`item_${idx}`}>{i}</span>);
      return arr;
    } else {
      if (item.match(reg) !== null) {
        return <img src={item} alt="" />;
      } else {
        return item;
      }
    }
  } else {
    return "";
  }
};
// 별점 반환 (1 -> 1.0 / 3.5 -> 3.5)
export const getGradeDouble = (grade) => {
  return parseFloat(Math.round(grade * 100) / 100).toFixed(1);
};

// form data 확인
export const isAllDataIn = (arr) => {
  let needValues = [];
  for (var key in arr) {
    if (arr[key] === "" || arr[key] === null || arr[key] === []) {
      needValues.push(key);
    }
  }
  console.log(needValues);
  return needValues;
};

// 폰번호 문자열로 변경 (01011112222 -> 010-1111-2222)
export const phoneNumToString = (x) => {
  return x
    .toString()
    .replace(/[^0-9]/g, "")
    .replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      "$1-$2-$3"
    )
    .replace("--", "-");
};

// 휴대폰 번호 검증
export const isPhoneNum = (phoneNum) => {
  var pattern = /^(?:(010\d{4})|(01[1|6|7|8|9]\d{3,4}))(\d{4})$/;
  return pattern.test(phoneNum);
};
// 단어 이/ 가 검증 받침있으면true
export const isWordEnding = (word) => {
  if (typeof word !== "string") return null;

  var lastLetter = word[word.length - 1];
  var uni = lastLetter.charCodeAt(0);

  if (uni < 44032 || uni > 55203) return null;

  return (uni - 44032) % 28 != 0;
};

// pw 검증
export const isOkPw = (pw) => {
  var regExp =
    /^(?!((?:[A-Za-z]+)|(?:[!@#$%^&*]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,20}$/;
  return !regExp.test(pw);
};
// url 번호 검증
export const isUrl = (url) => {
  let expUrl = /^http[s]?:\/\/([\S]{3,})/i;
  return expUrl.test(url);
};
// email 검증
export const isEmail = (email) => {
  var regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  if (email.match(regExp) != null) {
    return true;
  } else {
    return false;
  }
};
// 이름 검증 (한글, 영어)
export const isName = (name) => {
  const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/;
  return regex.test(name);
};

// 여권번호 검증 (숫자, 영어)
export const isPassportNum = (name) => {
  const regex = /^[0-9|a-z|A-Z]+$/;
  return regex.test(name);
};
// 용량체크
export const checkFileSize = (maxFileSizeMb, size) => {
  const max = maxFileSizeMb * 1024 * 1024;
  if (size > max) {
    return false;
  }
  return true;
};

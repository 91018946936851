// 구독 상세
export function getSubscribeStatusKr(type) {
  if (type === "progress") {
    return "구독중";
  } else if (type === "end") {
    return "만료";
  } else {
    return "-";
  }
}

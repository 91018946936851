import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// components
import { Alert, Pager } from "Components";

// Import Swiper styles
import "swiper/swiper.min.css";

// img
import NewsBuilderCalendar from "Assets/images/icons/newsbuilder_save_calendar.svg";
import NewsEX from "Assets/images/icons/playground_ex.png";
import Share from "Assets/images/icons/playground_share_icon.svg";
import Heart from "Assets/images/icons/playground_heart.svg";
import LikedPurpleHeart from "Assets/images/icons/liked_purple.svg";
import Question from "Assets/images/icons/chat_question.svg";
import ChatPeople from "Assets/images/icons/chat_people.png";
import ChatPeopleNone from "Assets/images/icons/chat_people_none.png";
import LmsEmptyIcon from "Assets/images/icons/lms_empty_icon.svg";

// styles
import { Container } from "Styles/styles";
import { NewsBuilderSave, LmsEmpty } from "../Lms/Lms.style";

// logics
import NewsLogic from "./News.logic";
import moment from "moment";
require("moment");
require("moment/locale/ko");

const News = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: "",
  });
  const { state, data, func } = NewsLogic({
    loading,
    setLoading,
    navigate,
    url: urlState,
  });
  useEffect(() => {
    setUrlState({
      ...urlState,
      current: location.pathname,
      id: location.pathname.split("/news/")[1].split("/")[0],
    });
  }, [location.pathname]);
  console.log(location.pathname.split("/news/")[1].split("/")[0]);
  return (
    <>
      {state.data_loading ? null : (
        <Container>
          {state.data_news !== [] ? (
            <NewsBuilderSave>
              <ul className="tag_list">
                {state.data_news.first_categories ? (
                  <li>{state.data_news.first_categories.category_name}</li>
                ) : null}
                {state.data_news.second_categories ? (
                  <li>{state.data_news.second_categories.category_name}</li>
                ) : null}
              </ul>
              <h1>{state.data_news.title}</h1>
              <ul className="info">
                <li>
                  <img src={NewsBuilderCalendar} alt="캘린더 아이콘" />
                  <p>
                    {moment(state.data_news.published_at).format("YYYY.MM.DD.")}
                  </p>
                </li>
              </ul>
              <div className="tab">
                <span
                  className={state.grade_type === "1" ? "on" : ""}
                  onClick={() => func.setStateValue("grade_type", "1")}
                >
                  1학년
                </span>
                <span
                  className={state.grade_type === "2" ? "on" : ""}
                  onClick={() => func.setStateValue("grade_type", "2")}
                >
                  2학년
                </span>
                <span
                  className={state.grade_type === "3" ? "on" : ""}
                  onClick={() => func.setStateValue("grade_type", "3")}
                >
                  3 · 4학년
                </span>
                <span
                  className={state.grade_type === "5" ? "on" : ""}
                  onClick={() => func.setStateValue("grade_type", "5")}
                >
                  5 · 6학년
                </span>
              </div>
              {state.data_news.contents.filter(
                (item) => item.grade === state.grade_type
              ).length > 0 ? (
                state.data_news.contents
                  .filter((item) => item.grade === state.grade_type)
                  .map((item) => {
                    // 뉴스빌더 이미지 방식에 따라 className 줘서 구분
                    if (item.image_location === "right") {
                      return (
                        <div key={`content_${item.id}`} className="right">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.content.replaceAll("\n", "<br/>"),
                            }}
                          ></p>
                          {item.images && item.images.image_url ? (
                            <img
                              src={item.images.image_url}
                              alt="뉴스 양쪽 양식 이미지"
                            />
                          ) : null}
                        </div>
                      );
                    } else if (item.image_location === "left") {
                      return (
                        <div key={`content_${item.id}`} className="left">
                          {item.images && item.images.image_url ? (
                            <img
                              src={item.images.image_url}
                              alt="뉴스 양쪽 양식 이미지"
                            />
                          ) : null}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.content.replaceAll("\n", "<br/>"),
                            }}
                          ></p>
                        </div>
                      );
                    } else {
                      return (
                        <div key={`content_${item.id}`} className="center">
                          {item.images && item.images.image_url ? (
                            <img
                              src={item.images.image_url}
                              alt="뉴스 양쪽 양식 이미지"
                            />
                          ) : null}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.content.replaceAll("\n", "<br/>"),
                            }}
                          ></p>
                        </div>
                      );
                    }
                  })
              ) : (
                <div>해당 학년 뉴스 내용이 없습니다.</div>
              )}
              <div className="newsbtns">
                <button
                  className={state.data_news.is_liked ? "liked" : ""} // liked 일때 좋아요인 상태
                  onClick={() => func.likedNews()}
                >
                  <img
                    src={state.data_news.is_liked ? LikedPurpleHeart : Heart}
                    alt="하트 아이콘"
                  />
                  <p className={state.data_news.is_liked ? "liked" : ""}>
                    {state.data_news.like_count}
                  </p>
                </button>
                <button onClick={() => func.getNewsUrl()}>
                  <img src={Share} alt="공유 아이콘" />
                  <p>공유하기</p>
                </button>
              </div>
            </NewsBuilderSave>
          ) : null}
          <div className="news_add">
            <h2>관련 뉴스 더보기</h2>
            <p>지금 읽은 뉴스와 비슷해요</p>

            {localStorage.getItem("user_token") ? (
              state.data_related_news &&
              state.data_related_news.news.length > 0 ? (
                <Swiper
                  className="swiper-wrapper"
                  spaceBetween={24}
                  slidesPerView={4}
                  centeredSlides={false}
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                    },
                    810: {
                      slidesPerView: 3,
                      spaceBetween: 24,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 24,
                    },
                  }}
                >
                  {state.data_related_news.news.map((item) => (
                    <SwiperSlide
                      className="swiper-slide"
                      onClick={() => navigate(`/news/${item.id}`)}
                    >
                      <img src={item.images ?item.images.image_url : LmsEmptyIcon} 
                      alt="이번주 뉴스 이미지" />               
                      <div className="tagbox">
                        {item.first_categories ? (
                          <span>{item.first_categories.category_name}</span>
                        ) : null}
                        {item.first_categories ? (
                          <span>{item.first_categories.category_name}</span>
                        ) : null}
                      </div>


                      <h4>{item.title}</h4>
                      <h6>{moment(item.published_at).format("YYYY.MM.DD.")}</h6>
                      {/* <div className="iconbox">
                        <span>
                          <img src={Share} alt="공유 아이콘" />
                        </span>
                        <span>
                          <img src={LikedHeart} alt="하트 아이콘" />
                        </span>
                      </div> */}
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div className="news_none">관련뉴스가 없습니다.</div>
              )
            ) : (
              <div className="login_news">
                <span onClick={() => navigate("/login/student")}>로그인</span>{" "}
                해야 관련뉴스를 확인 하실 수 있습니다.
              </div>
            )}
            {state.data_comments !== [] ? (
              <div className="comment_playground">
                <h4>
                  댓글 놀이터<span>{state.data_comments.total}</span>
                </h4>
                <div>
                  <img src={Question} alt="질문아이콘" />
                  <p>{state.data_news.lead_comment}</p>
                </div>
                <div>
                  {localStorage.getItem("user_token") ? (
                    <img
                      src={localStorage.getItem("user_profile")}
                      alt="프로필 없는 이미지"
                    />
                  ) : (
                    <img src={ChatPeopleNone} alt="프로필 없는 이미지" />
                  )}

                  {/*} <input
                    id="comment"
                    type="text"
                    placeholder={state.data_news.lead_comment}
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("comment", value)
                    }
                  /> */}
                  <textarea
                    id="comment"
                    type="text"
                    // placeholder={state.data_news.lead_comment}
                    placeholder="이 뉴스에 대해 어떻게 생각하나요?"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("comment", value)
                    }
                  ></textarea>
                  <div>
                    <button
                      onClick={
                        () =>
                          func.setStateValue("alert", {
                            title: "관리자의 확인 후 댓글이 남겨져요.",
                            sub: "비방, 욕설이 있다면 댓글이 남겨지지 않으니 주의해주세요.",
                            isOk: () => func.writeComment(),
                            isCancel: () => func.setStateValue("alert", null),
                          })
                        // () => func.setModalData(true, "comment_request")
                      }
                    >
                      남기기
                    </button>
                  </div>
                </div>
                {state.data_comments.comments.length > 0 ? (
                  <ul>
                    {state.data_comments.comments.map((item) => (
                      <li>
                        {item.users.profile_image_url && (
                          <img
                            src={item.users.profile_image_url}
                            alt="프로필 없는 이미지"
                          />
                        )}
                        <div>
                          <h5>{item.users.nick_name}</h5>
                          <span>{item.users.grade}학년</span>
                          <h6>· {moment(item.created_at).fromNow()}</h6>
                          <p>{item.comment}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <LmsEmpty>
                    <img src={LmsEmptyIcon} alt="lms공백 아이콘" />
                    <p className="mb0">아직 댓글이 없어요</p>
                    <span>이 뉴스에 대한 의견을 먼저 남겨보세요!</span>
                  </LmsEmpty>
                )}
                <Pager
                  page={data.currentPage}
                  count={10}
                  total={state.data_comments_total}
                  paging={func.paging}
                />
              </div>
            ) : null}
          </div>
        </Container>
      )}
      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );
};

export default News;

import axios from "axios";
import jsonData from "Assets/contents/contents.json";

const api = axios.create({
  baseURL: jsonData.api_url,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("user_token")}`,
  },
});

export const userApi = {
  // 로그인
  login: (json) => api.post("/user/login", json),
  // 회원가입
  signup: ({ type }, json) => api.post(`/user/signup/${type}`, json),
  // 아이디 확인  type = child/ parents
  getId: ({ type }, json) => api.post(`/user/setting/id/${type}`, json),
  // 비밀번호 재설정 type = child/ parents
  setPw: ({ type }, json) => api.patch(`/user/setting/password/${type}`, json),
  // 내정보 조회
  getMyInfo: () => api.get(`/user/mypage`),
  // 내정보 수정
  editMyInfo: (json) => api.patch(`/user/mypage`, json),
  // 내 자녀 조회
  getMyChildInfo: ({ id }) => api.get(`/user/mypage/child/${id}`),
  // 내 자녀 수정
  editMyChildInfo: ({ id }, json) =>
    api.patch(`/user/mypage/child/${id}`, json),
  // 프로필 이미지 수정
  editProfileImage: (form) =>
    api.post(`/user/mypage/image/profile`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  // 회원 탈퇴
  leaveUs: () => api.get(`/user/mypage/resign`),
};

export const checkApi = {
  // 약관 조회 type = child / parents // kind = signup/ payment/ etc
  getTerms: ({ kind, type }) => api.get(`/user/service/${kind}/${type}`),
  // 아이디 이메일  닉네임 중복확인 type = login_id / email / nick_name
  checkInfo: ({ type }, json) => api.post(`/user/check/${type}`, json),
  // 휴대폰 인증 kind = signup, id, password, edit // type = child / parents
  sendPhoneCheck: ({ kind, type }, json) =>
    api.post(`/user/send/phone/${kind}/${type}`, json),
  // 휴대폰 인증 kind = signup, id, password, edit // type = child / parents
  checkPhoneVerify: ({ kind, type }, json) =>
    api.post(`/user/verify/phone/${kind}/${type}`, json),
  // 주소 조회 현재 id = 1 대한민국
  getCtiy: ({ id }) =>
    api.get(`user/area/city`, {
      params: {
        id,
      },
    }),
};
// 메인
export const mainApi = {
  // 뉴스 목록 조회
  getNewsList: ({ grade, page }) =>
    api.get(`/main/`, {
      params: {
        grade,
        page,
      },
    }),
  // 뉴스 상세 조회
  getNewsDetail: ({ id, grade, page }) =>
    api.get(`/main/${id}`, {
      params: {
        grade,
        page, // 댓글
      },
    }),
  // 뉴스 검색
  getNewsSearchList: ({ search, page }) =>
    api.get(`/main/search`, {
      params: {
        search,
        page,
      },
    }),
  // 구독 서비스 목록
  getSubscribeList: () => api.get(`/main/subscribe/list`),
  // 뉴스 좋아요
  setLikeNews: ({ id }) => api.post(`/main/news/like/${id}`),
  // 뉴스 댓글
  setCommentNews: ({ id }, json) => api.post(`/main/news/${id}/comment`, json),
  // 뉴스, 작성한 뉴스 , 뉴스레터 공유 된것 조회 type= news, letter, public
  getUrlToNews: ({ type, id }) => api.get(`/main/share/${type}/${id}`),
};
// 결제
export const paymentApi = {
  // 결제 화면 조회
  getPayment: ({ id }) => api.get(`/main/payment/${id}`),
  // 결제 하기 id : product_id (1: 비구독 / 2: 월간 구독 / 3: 1년 구독)
  setPayment: ({ id }, json) => api.post(`/main/payment/${id}`, json),
  // 카드 등록
  addCardInfo: (json) => api.post(`/main/payment/issue-billing`, json),
};

// 공통 lms
export const lmsCommonApi = {
  // 뉴스 목록 조회 > 부모일때 id :child_id
  getNewsList: ({ id, year, month, week, page }) =>
    api.get(`/lms/news/builder`, {
      params: {
        id,
        year,
        month,
        week,
        page,
      },
    }),
  // 뉴스 상세 조회
  getNewsDetail: ({ id }) => api.get(`/lms/news/builder/${id}`),

  // 뉴스 레터 목록 조회 > 부모일때 id :child_id
  getNewsLetterList: ({ id, year }) =>
    api.get(`/lms/news/letter/builder`, {
      params: {
        id,
        year,
      },
    }),
  // 뉴스 레터 상세 조회
  getNewsLetterDetail: ({ id }) => api.get(`/lms/news/letter/builder/${id}`),

  // 좋아한 뉴스 조회 > 부모일때 id :child_id
  getLikedNewsList: ({ id }) =>
    api.get(`/lms/news/like`, {
      params: {
        id,
      },
    }),

  // 뉴스놀이터 참여 현황 목록 조회 > 부모일때 id :child_id
  getNewsStudyList: ({ id, year, month, week, page }) =>
    api.get(`/lms/news/study`, {
      params: {
        id,
        year,
        month,
        week,
        page,
      },
    }),

  // 뉴스, 작성한 뉴스 , 뉴스레터 공유 type= news, letter, public
  getNewsUrl: ({ type, id }) => api.get(`/main/news/share/${type}/${id}`),
};
// 학생용 lms
export const lmsChildApi = {
  // 뉴스 목록 조회
  getNewsList: ({ year, month, page }) =>
    api.get(`/lms/news/letter/my_news`, {
      params: {
        year,
        month,
        page,
      },
    }),
  // 카테고리 불러오기, 이미지풀 목록 불러오기 type = image, first(첫번째 카테고리), second(두번째 카테고리)
  getNewsSupportList: ({ type, search, page }) =>
    api.get(`/lms/news/builder/supports/${type}`, {
      params: {
        search,
        page,
      },
    }),

  // 이미지 등록
  setImage: (form) =>
    api.post(`/lms/news/builder/images`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  // 이미지 삭제
  deleteImage: (id) => api.delete(`/lms/news/builder/images/${id}`),

  // 뉴스 등록 type= save(저장), temp(임시저장)
  setNews: ({ type, id }, json) =>
    api.post(`/lms/news/builder/${type}/${id}`, json),
  // 뉴스 삭제
  deleteNews: ({ id }) => api.delete(`/lms/news/builder/${id}`),
  // 뉴스레터 등록 type= save(저장), temp(임시저장)
  setLetter: ({ type, id }, json) =>
    api.post(`/lms/news/letter/${type}/${id}`, json),
  // 뉴스레터 삭제
  deleteLetter: ({ id }) => api.delete(`/lms/news/letter/${id}`),
  // 댓글 목록 조회
  getCommentList: ({ page }) =>
    api.get(`/lms/news/comments`, {
      params: {
        page,
      },
    }),
};

// 학부모 lms
export const lmsParentsApi = {
  // 구독관리 조회
  getSubscription: ({ id, page }) =>
    api.get(`/lms/subscription`, {
      params: {
        id,
        page,
      },
    }),
  // 구독관리 조회 id :child_id
  deleteSubscription: ({ id }) =>
    api.delete(`/lms/subscription`, {
      params: {
        id,
      },
    }),
};

import { useState } from "react";
import jsonData from "Assets/contents/contents.json";

const QuestionLogic = () => {
  // 기본 state 관리
  const [state, setState] = useState({
    user_list: [],
    search_keyword: "",
    modal: { open: false, type: "", id: "" },
    type: "news", // news, subscribe
    question_info: "true", // question_box
    contentOpen: { open: false },
  });

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  // question box on/off state 관리
  const [questionOpen, setQuestionOpen] = useState({
    setting: true, // 상품설정
    news_include: true, // 뉴스 포함
  });

  // content box on/off state 관리
  const [contentOpen, setContentOpen] = useState({
    news1: false, // 뉴스 질문1
    news2: false, // 뉴스 질문2
    news3: false, // 뉴스 질문3
    news4: false, // 뉴스 질문4
    subscribe1: false, // 구독 질문1
    subscribe2: false, // 구독 질문2
    subscribe3: false, // 구독 질문3
    subscribe4: false, // 구독 질문4
    subscribe5: false, // 구독 질문5
  });
  // 이메일 복사
  const copyLink = async () => {
    navigator.clipboard.writeText(jsonData.contact_url.email);

    setStateValue("alert", {
      title: "이메일 주소가 복사 되었습니다. 이메일로 문의 바랍니다.",
      sub: null,
      isOk: () => setStateValue("alert", null),
      isCancel: null,
    });
  };

  //  content on/off 기능
  const setContentsOnoff = (key, value) => {
    setContentOpen({ ...contentOpen, [key]: value });
  };

  // Question On/off 기능
  const setQuestionOnoff = (key, value) => {
    setQuestionOpen({ ...questionOpen, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };

  return {
    state,
    questionOpen,
    contentOpen,

    func: {
      setStateValue,
      setModalData,
      setQuestionOnoff,
      setContentsOnoff,
      copyLink,
    },
  };
};

export default QuestionLogic;

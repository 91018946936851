import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import { Modal, Alert } from "Components";

// img
import Mail from "Assets/images/icons/landing_mail.png";
import LandingArrow from "Assets/images/icons/landing_arrow.svg";
import LandingArrow2 from "Assets/images/icons/landing_arrow2.svg";
import LandingCheck from "Assets/images/icons/landing_check.svg";
import Logo from "Assets/images/icons/wigg_logo.svg";
import CheckIcon from "Assets/images/icons/check_icon.svg";
import RightarrowSmall from "Assets/images/icons/rightarrow_small.svg";
import ChildCheck from "Assets/images/icons/child_check.svg";
import ChildCheckDone from "Assets/images/icons/child_check_done.svg";

// styles
import { PaymentSt, SubscribeBox, PaymentContainer } from "Styles/styles";

// logics
import SubscribeLogic from "./Subscribe.logic";
import { numberToPrice } from "Utils/lib/helper";

// select
import { selectStyles, DropdownIndicator } from "Styles/select.style";
import Select, { components } from "react-select";

const Subscribe = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: "",
    id: null,
  });
  const { state, data, func, contentOpen } = SubscribeLogic({
    loading,
    setLoading,
    navigate,
    url: urlState,
  });
  useEffect(() => {
    setUrlState({
      ...urlState,
      current: location.pathname,
      type: location.pathname.split("/subscribe/")[1].split("/")[0],
      id: location.pathname.split("/subscribe/")[1].split("/")[1],
    });
  }, [location.pathname]);
  return (
    <>
      {urlState.type === "info" ? (
        <SubscribeBox>
          {/* <div className="subscribe_main">
            <h4>구독</h4>
            <ul>
              <li>
                <img src={Mail} alt="메일이미지" />
              </li>
              <li>
                <h4>무료</h4>
                <h3>0원</h3>
                <button onClick={() => func.onClickLink("signup")}>
                  회원가입
                </button>
                <img src={LandingArrow} alt="구독박스화살표" />
              </li>
              <li>
                <h4>한 달 구독</h4>
                <h3>19,900원</h3>
                <button onClick={() => func.onClickLink(2)}>결제하기</button>
                <img src={LandingArrow} alt="구독박스화살표" />
              </li>
              <li>
                <h4>1년 구독</h4>
                <h3>
                  <span>25%</span>179,000원
                </h3>
                <button onClick={() => func.onClickLink(3)}>결제하기</button>
                <img src={LandingArrow2} alt="구독박스화살표" />
              </li>
            </ul>
            <div className="subscribe_wrap">
              <div>뉴스 놀이터</div>
              <ul className="subscribe">
                <li>
                  <span>
                    <h5>최신 뉴스 읽기</h5>
                    <p>매주 뉴스가 업데이트 돼요.</p>
                  </span>
                </li>
                <li>
                  <h6>무료</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe">
                <li>
                  <span>
                    <h5>댓글 놀이터</h5>
                    <p>댓글을 쓰고 읽으며 생각을 나눠요.</p>
                  </span>
                </li>
                <li>
                  <h6>무료</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe">
                <li className="big2">
                  <span>
                    <h5>관련 뉴스 더보기</h5>
                    <p>주제와 관련된 다양한 뉴스를 읽으며 사고력을 더해요.</p>
                  </span>
                </li>
                <li className="big2">
                  <h6>무료</h6>
                </li>
                <li className="big2">
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li className="big2">
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe mb40 ">
                <li className="big2">
                  <span>
                    <h5>좋아하는 뉴스 저장하기</h5>
                    <p>마음에 드는 뉴스를 저장해 나만의 콜렉션을 만들어요.</p>
                  </span>
                </li>
                <li className="big2">
                  <h6>무료</h6>
                </li>
                <li className="big2">
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li className="big2">
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
            </div>
            <div className="subscribe_wrap">
              <div>뉴스 빌더</div>
              <ul className="subscribe">
                <li>
                  <span>
                    <h5>나의 뉴스 작성하기</h5>
                    <p>관심있는 주제의 뉴스를 매주 하나씩 써봐요.</p>
                  </span>
                </li>
                <li>
                  <h6>무료</h6>
                </li>
                <li>
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe">
                <li>
                  <span>
                    <h5>뉴스레터 작성하기</h5>
                    <p>나의 이름을 건 뉴스레터를 매월 만들어요.</p>
                  </span>
                </li>
                <li>
                  <h6>무료</h6>
                </li>
                <li>
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe">
                <li className="big">
                  <span>
                    <h5>뉴스를 발간하고 공유하기</h5>
                    <p>
                      직접 쓴 뉴스와 뉴스레터를 발간하고 가족, 친구와 공유해요.
                    </p>
                  </span>
                </li>
                <li className="big">
                  <h6>무료</h6>
                </li>
                <li className="big">
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li className="big">
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe mb110">
                <li className="big2">
                  <span>
                    <h5>뉴스레터 모음 인쇄본 받기</h5>
                    <p>1년간 작성한 뉴스레터를 모아 인쇄본으로 간직해요.</p>
                  </span>
                </li>
                <li className="big2">
                  <h6>무료</h6>
                </li>
                <li className="big2">
                  <h6>한 달 구독</h6>
                </li>
                <li className="big2">
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
            </div>
          </div> */}
          <div className="subscribe_main">
            <h4>구독</h4>
            <ul>
              <li>
                <img src={Mail} alt="메일이미지" />
              </li>
              <li>
                <h4>무료</h4>
                <h3>0원</h3>
                <button onClick={() => func.onClickLink("signup")}>
                  회원가입
                </button>
                <img src={LandingArrow} alt="구독박스화살표" />
              </li>
              <li>
                <h4>한 달 구독</h4>
                <h3>9,900원</h3>
                <button onClick={() => func.onClickLink(2)}>결제하기</button>
                <img src={LandingArrow} alt="구독박스화살표" />
              </li>
              <li>
                <h4>1년 구독</h4>
                <h3>
                  <span>15%</span>99,000원
                </h3>
                <button onClick={() => func.onClickLink(3)}>결제하기</button>
                <img src={LandingArrow2} alt="구독박스화살표" />
              </li>
            </ul>
            <div className="subscribe_wrap">
              <div>뉴스 놀이터</div>
              <ul className="subscribe">
                <li>
                  <span>
                    <h5>최신 뉴스 읽기</h5>
                    <p>매주 뉴스가 업데이트 돼요.</p>
                  </span>
                </li>
                <li>
                  <h6>무료</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe">
                <li>
                  <span>
                    <h5>댓글 놀이터</h5>
                    <p>댓글을 쓰고 읽으며 생각을 나눠요.</p>
                  </span>
                </li>
                <li>
                  <h6>무료</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe">
                <li className="big2">
                  <span>
                    <h5>관련 뉴스 더보기</h5>
                    <p>주제와 관련된 다양한 뉴스를 읽으며 사고력을 더해요.</p>
                  </span>
                </li>
                <li className="big2">
                  <h6>무료</h6>
                </li>
                <li className="big2">
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li className="big2">
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe mb40 ">
                <li className="big2">
                  <span>
                    <h5>좋아하는 뉴스 저장하기</h5>
                    <p>마음에 드는 뉴스를 저장해 나만의 콜렉션을 만들어요.</p>
                  </span>
                </li>
                <li className="big2">
                  <h6>무료</h6>
                </li>
                <li className="big2">
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li className="big2">
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
            </div>
            <div className="subscribe_wrap">
              <div>뉴스 빌더</div>
              <ul className="subscribe">
                <li>
                  <span>
                    <h5>나의 뉴스 작성하기</h5>
                    <p>관심있는 주제의 뉴스를 매주 하나씩 써봐요.</p>
                  </span>
                </li>
                <li>
                  <h6>무료</h6>
                </li>
                <li>
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe">
                <li>
                  <span>
                    <h5>뉴스레터 작성하기</h5>
                    <p>나의 이름을 건 뉴스레터를 매월 만들어요.</p>
                  </span>
                </li>
                <li>
                  <h6>무료</h6>
                </li>
                <li>
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li>
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe">
                <li className="big">
                  <span>
                    <h5>뉴스를 발간하고 공유하기</h5>
                    <p>
                      직접 쓴 뉴스와 뉴스레터를 발간하고 가족, 친구와 공유해요.
                    </p>
                  </span>
                </li>
                <li className="big">
                  <h6>무료</h6>
                </li>
                <li className="big">
                  <h6>한 달 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
                <li className="big">
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
              <ul className="subscribe mb110">
                <li className="big2">
                  <span>
                    <h5>뉴스레터 모음 인쇄본 받기</h5>
                    <p>1년간 작성한 뉴스레터를 모아 인쇄본으로 간직해요.</p>
                  </span>
                </li>
                <li className="big2">
                  <h6>무료</h6>
                </li>
                <li className="big2">
                  <h6>한 달 구독</h6>
                </li>
                <li className="big2">
                  <h6>1년 구독</h6>
                  <img src={LandingCheck} alt="체크이미지" />
                </li>
              </ul>
            </div>
          </div>
        </SubscribeBox>
      ) : (
        <PaymentSt>
          {!state.loading && state.step === 1 ? (
            <>
              <div className="payment">
                <img src={Logo} alt="로고이미지" />
                <PaymentContainer open={contentOpen.period}>
                  <div className="payment_box">
                    <div
                      onClick={() =>
                        func.setContentsOnoff("period", !contentOpen.period)
                      }
                    >
                      <h3>
                        {state.data_info.bill &&
                          state.data_info.bill.product_name}
                      </h3>
                      <div className="pay_right">
                        <h4>
                          {state.data_info.bill
                            ? numberToPrice(state.data_info.bill.price)
                            : 0}
                          원/<span>월</span>
                        </h4>
                        <img src={RightarrowSmall} alt="화살표" />
                      </div>
                    </div>
                    <div className="month_subscribe">
                      <ul>
                        {state.data_info.bill &&
                          state.data_info.bill.product_contents.map((item) => (
                            <li key={`content_${item.id}`}>
                              <img src={CheckIcon} alt="체크아이콘" />
                              <p>{item.content}</p>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </PaymentContainer>
                <PaymentContainer open={contentOpen.child_select}>
                  <div className="payment_box">
                    <div
                      onClick={() =>
                        func.setContentsOnoff(
                          "child_select",
                          !contentOpen.child_select
                        )
                      }
                    >
                      <h3>자녀 선택</h3>
                      <div className="pay_right">
                        <h4>{data.inputData.child.length}명</h4>
                        <img src={RightarrowSmall} alt="화살표" />
                      </div>
                    </div>
                    <div className="child_list">
                      <ul>
                        {state.data_info.family &&
                          state.data_info.family.map((item) => (
                            <li
                              className={
                                data.inputData.child.filter(
                                  (i) => i === item.id
                                ).length > 0
                                  ? "on"
                                  : ""
                              }
                              key={`child_${item.id}`}
                              onClick={() =>
                                func.setInputStateValue("child", item.id)
                              }
                            >
                              <img
                                src={item.profile_image_url}
                                alt={`${item.name}프로필 이미지`}
                              />
                              <p>{item.name}</p>
                              <div>
                                <img
                                  src={
                                    data.inputData.child.filter(
                                      (i) => i === item.id
                                    ).length > 0
                                      ? ChildCheckDone
                                      : ChildCheck
                                  }
                                  alt="나의 뉴스레터,뉴스레터 빌더 아이콘"
                                />
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </PaymentContainer>
                <PaymentContainer open={contentOpen.payment}>
                  <div className="payment_box">
                    <div
                      onClick={() =>
                        func.setContentsOnoff("payment", !contentOpen.payment)
                      }
                    >
                      <h3>결제 금액</h3>
                      <div className="pay_right">
                        <h4>{data.inputData.child.length}명</h4>
                        <img src={RightarrowSmall} alt="화살표" />
                      </div>
                    </div>
                    <div className="payment_amount">
                      <ul>
                        <li>
                          <h5>한 달 구독 x {data.inputData.child.length}</h5>
                          <p>
                            {state.data_info.bill
                              ? `${numberToPrice(
                                  state.data_info.bill.price *
                                    data.inputData.child.length
                                )}원`
                              : "-"}
                          </p>
                        </li>
                        <li>
                          <h5>총 결제금액</h5>
                          <p>
                            {state.data_info.bill
                              ? `${numberToPrice(
                                  state.data_info.bill.price *
                                    data.inputData.child.length
                                )}원`
                              : "-"}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </PaymentContainer>

                <PaymentContainer open={contentOpen.methods}>
                  <div className="payment_box">
                    <div
                      onClick={() =>
                        func.setContentsOnoff("methods", !contentOpen.methods)
                      }
                    >
                      <h3>결제 수단</h3>
                      <div className="pay_right">
                        {state.data_info.user_card ? (
                          <h4>
                            {state.data_info.user_card.card_name}
                            {`(${state.data_info.user_card.card_last_number})`}
                          </h4>
                        ) : (
                          <h4>결제 수단을 등록해주세요</h4>
                        )}
                        <img src={RightarrowSmall} alt="화살표" />
                      </div>
                    </div>
                    <div className="payment_methods">
                      <ul>
                        <li>
                          {state.data_info.user_card ? (
                            <h5>
                              {state.data_info.user_card.card_name}
                              {`(${state.data_info.user_card.card_last_number})`}
                            </h5>
                          ) : (
                            <h5>등록된 결제 수단이 없어요</h5>
                          )}
                          <button
                            onClick={() =>
                              func.setModalData(true, "payment_change")
                            }
                          >
                            결제 수단 변경
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </PaymentContainer>
                <div className="agree">
                  <ul>
                    <li>
                      <p>만 14세 이상 결제 동의</p>
                      <img src={RightarrowSmall} alt="화살표" />
                    </li>
                    <li>
                      <p>개인정보 제공 동의</p>
                      <img src={RightarrowSmall} alt="화살표" />
                    </li>
                  </ul>
                  <p>위 내용을 확인하였으며 결제에 동의합니다</p>
                  <button onClick={() => func.addPayment()}>결제하기</button>
                </div>
              </div>
            </>
          ) : null}
          {state.step === 2 && state.payment_info !== [] ? (
            <div className="payment">
              <img src={Logo} alt="로고이미지" />
              <ul className="payment_done">
                <li>
                  <h3>구매 정보</h3>
                  <div>
                    <h5>구매 상품</h5>
                    <div>
                      <p>
                        {state.payment_info.payments
                          ? state.payment_info.payments.product_name
                          : ""}
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <h5>자녀 정보</h5>
                    <div>
                      <p>
                        {state.payment_info.payments
                          ? state.payment_info.payments.child.toString()
                          : ""}
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <h5>결제 수단</h5>
                    <div>
                      <p>
                        {state.payment_info.payments
                          ? state.payment_info.payments.card_name
                          : ""}{" "}
                        {state.payment_info.payments
                          ? `(${state.payment_info.payments.card_last_number})`
                          : ""}
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <h5>결제 금액</h5>
                    <div>
                      <p>
                        {state.payment_info.payments
                          ? `${numberToPrice(
                              state.payment_info.payments.total_price
                            )}원`
                          : ""}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="payment_done">
                <li>
                  <h3>구매자 정보</h3>
                  <div>
                    <h5>이름</h5>
                    <div>
                      <p>
                        {state.payment_info.users
                          ? state.payment_info.users.name
                          : ""}
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <h5>이메일</h5>
                    <div>
                      <p>
                        {state.payment_info.users
                          ? state.payment_info.users.email
                          : ""}
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <h5>전화번호</h5>
                    <div>
                      <p>
                        {state.payment_info.users
                          ? state.payment_info.users.phone_number
                          : ""}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="payment_done">
                <li>
                  <h4>안내사항</h4>
                  <div className="info">
                    <p>
                      <span>&bull;</span>
                      구독주기에 맞추어 자동으로 갱신돼요.
                    </p>
                    <p>
                      <span>&bull;</span>
                      구독을 해지해도 만료일까지 서비스를 이용할 수 있어요.
                    </p>
                  </div>
                </li>
              </ul>
              <button
                onClick={() => navigate("/lms/student/dashboard/participation")}
              >
                LMS 바로가기
              </button>
            </div>
          ) : null}
        </PaymentSt>
      )}
      {/* 결제 수단 변경 */}
      {state.modal.open && state.modal.type === "payment_change" ? (
        <Modal
          minWidth={500}
          // minHeight={600}
          maxHeight={600}
          borderRadius={10}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="payment_change">
                <h3>결제 수단 변경</h3>
                <div>
                  <div>
                    <h6>카드 번호</h6>
                  </div>
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_num01", value)
                    }
                  />
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_num02", value)
                    }
                  />
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_num03", value)
                    }
                  />
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_num04", value)
                    }
                  />
                </div>
                <div>
                  <div>
                    <h6>생년월일</h6>
                  </div>
                  <input
                    type="number"
                    placeholder="930101"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_birth", value)
                    }
                  />
                </div>

                <div className="mb40">
                  <div>
                    <h6>유효기간</h6>
                  </div>
                  <Select
                    placeholder="월"
                    options={data.filterList.month_list}
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={140}
                    height={48}
                    onChange={(value) =>
                      func.setInputStateValue("card_expiry_month", value.value)
                    }
                  />
                  <Select
                    placeholder="년도"
                    options={data.filterList.year_list}
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={140}
                    height={48}
                    onChange={(value) =>
                      func.setInputStateValue("card_expiry_year", value.value)
                    }
                  />
                </div>
                <div>
                  <div>
                    <h6>비밀번호 앞 두자리</h6>
                  </div>
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_pwd_2digit", value)
                    }
                  />
                  <input
                    type="password"
                    className="grey_input"
                    readOnly={true}
                  />
                </div>
                <button onClick={() => func.setPaymentCard()}>확인</button>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}

      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );
};

export default Subscribe;

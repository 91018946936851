import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";

// api
import { lmsCommonApi, userApi } from "Utils/api/api";

//lib
import { scrollTop, phoneNumToString } from "Utils/lib/helper";

// json
import jsonData from "Assets/contents/contents.json";

// img

const LmsLogic = ({ loading, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    loading: true,
    user_info: [],
    child_id: null,
    menu_open: 1,
  });

  useEffect(() => {
    getUserInfo();
  }, [url]);

  ////////////////  API  ////////////////
  // 유저 정보 조회
  const getUserInfo = async () => {
    try {
      const {
        data: { data },
      } = await userApi.getMyInfo({ id: null });
      setState({
        ...state,
        user_info: data,
        loading: false,
        child_id:
          data.family && data.family.length > 0 ? data.family[0].id : null,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
    }
  };
  const setMenuOnOff = (idx) => {
    if (state.menu_open === idx) {
      setState({ ...state, menu_open: null });
    } else {
      setState({ ...state, menu_open: idx });
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return {
    state,
    data: {},
    funcs: { setStateValue, setMenuOnOff },
  };
};

export default LmsLogic;

import styled from "styled-components";
import People from "Assets/images/icons/people.svg";
import Stroke1 from "Assets/images/icons/Stroke1.svg";

export const Top = styled.header`
  z-index: 9;
  width: 100%;
  height: 60px;
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #e9ebee;
  div {
    width: 1128px;
    height: 60px;
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 746px;
    }
    @media screen and (max-width: 809px) {
      width: 90%;
    }
    > ul {
      /* padding: 15px 25px; */
      position: relative;
      display: inline-block;
      vertical-align: middle;
      li {
        padding: 12px 10px;
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
        color: var(--grey7);
        font-size: 14px;
        cursor: pointer;
        margin-top: 8px;
        &:nth-child(1) {
          padding: 12px 0;
        }
        &.hidden {
          display: none;
        }
        img {
          width: 69px;
          height: 24px;
          vertical-align: middle;
          cursor: pointer;
          display: block;
          margin-right: 18px;
          object-fit: cover;
        }
        h3 {
          display: inline-block;
          vertical-align: middle;
          margin-left: 12px;
          color: var(--sub);
          color: #fff;
          font-size: 13px;
        }
        @media screen and (max-width: 809px) {
          &:nth-child(1) {
            display: block;
          }
          display: none;
        }
      }
    }

    > div {
      float: right;
      display: inline-block;
      width: auto;
      vertical-align: middle;
      position: relative;
      line-height: 60px;
      .search {
        line-height: 60px;
        margin-right: 24px;
        cursor: pointer;
        img {
          width: 22px;
          height: 22px;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .people {
        background: url(${People});
        width: 24px;
        height: 24px;
        display: inline-block;
        background-size: cover;
        vertical-align: middle;
        cursor: pointer;
        border-radius:50%;
        @media screen and (max-width: 809px) {
          display: none;
        }
      }
      .mb_menu {
        display: none;
        @media screen and (max-width: 809px) {
          display: block;
          width: 24px;
          height: 24px;
          display: inline-block;
          background-size: cover;
          vertical-align: middle;
          cursor: pointer;
        }
      }
      .bar {
        background: url(${Stroke1}) no-repeat;
        width: 16px;
        height: 16px;
        display: inline-block;
        background-size: cover;
        vertical-align: middle;
      }

      .open_box {
        &.hidden {
          display: none;
        }
        // profile open
        > div {
          &:nth-child(1) {
            background-repeat: no-repeat;
            position: absolute;
            top: 55px;
            right: 0px;
            width: 240px;
            padding: 8px;
            z-index: 3;
            background-color: var(--white);
            box-shadow: 0px 4px 16px rgba(40, 50, 60, 0.1);
            border-radius: 6px;
            box-sizing: border-box;
            height: auto;
            z-index: 9;
            > ul {
              width: 100%;
              li {
                cursor: pointer;
                box-sizing: border-box;
                height: 44px;
                font-size: 14px;
                width: 100%;
                color: var(--text);
                margin-top: 0;
                padding: 12px 16px;
                &:nth-child(1) {
                  padding: 12px 6px;
                  height: auto;
                  img {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                    vertical-align: top;
                    border-radius: 100%;
                    object-fit: cover;
                  }
                }
                img {
                  width: 20px;
                  height: 20px;
                  display: inline-block;
                  vertical-align: sub;
                }
                &:hover {
                  background-color: var(--grey2);
                  border-radius: 6px;
                }
                .profile_box {
                  display: inline-block;
                  vertical-align: middle;
                  width: 170px;
                  height: auto;
                  h5 {
                    font-size: 14px;
                    line-height: 140%;
                    letter-spacing: -0.04em;
                    width: 90%;
                  }
                  p {
                    font-size: 12px;
                    line-height: 140%;
                    letter-spacing: -0.04em;
                    color: var(--grey6);
                  }
                  img {
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 12px;
                    margin-right: 0;
                    right: 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // 모바일 메뉴
  .mb_menu_open {
    position: absolute;
    top: 0px;
    right: 0;
    width: 100%;
    height: fit-content;
    background-color: var(--white);
    padding: 20px;
    border-bottom: 1px solid var(--grey3);
    .xi-close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: var(--grey4);
      font-size: 20px;
      cursor: pointer;
    }
    ul {
      width: 100%;
      display: block;
      li {
        display: 100%;
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #28323c;
        margin-top: 0;
        margin-right: 0;
        padding: 12px 0;
        &:nth-child(1) {
          margin-top: 20px;
          border-bottom: 1px solid var(--grey3);
        }
        > div {
          width: calc(100% - 62px);
          display: inline-block;
          vertical-align: middle;
          text-align: left;
          margin-top: 16px;
          h6 {
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #28323c;
            margin-bottom: 8px;
          }
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #646f7c;
          }
        }
        > img {
          display: inline-block;
          vertical-align: middle;
          width: 52px;
          height: 52px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 0;
        }
      }
    }
  }
  // search open
  .search_open {
    width: 100%;
    height: 96px;
    background-color: #fff;
    > div {
      position: absolute;
      top: 22px;
      left: 50%;
      transform: translateX(-50%);
      width: 1038px;
      height: 52px;
      z-index: 3;
      background-color: transparent;
      border-radius: 6px;
      box-sizing: border-box;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 746px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
      }
      input[type="text"] {
        width: 100%;
        background-color: var(--grey1);
        border: 1px solid var(--grey3);
        height: 52px;
        border-radius: 10px;
        padding: 14px 20px;
        box-sizing: border-box;
        position: relative;
        padding-left: 52px;
        &::placeholder {
          font-size: 16px;
          line-height: 140%;
          color: var(--grey5);
          letter-spacing: -0.04em;
        }
        &:focus {
          border: 1px solid var(--main);
        }
      }
      .xi-search {
        font-size: 20px;
        position: absolute;
        top: 16px;
        left: 20px;
        color: var(--grey4);
      }
      img {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 20px;
        top: 15px;
      }
    }
  }
`;

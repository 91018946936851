import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// json
import lmsJson from "./lms.json";

// components
import { Modal, Alert, Pager, LmsNav } from "Components";

// select
import { selectStyles, DropdownIndicator } from "Styles/select.style";
import Select, { components } from "react-select";

// img

import SubscribePlan from "Assets/images/icons/subscribe_plan.svg";
import SubscribePayment from "Assets/images/icons/subscribe_payment.svg";
import SubscribeNextPayment from "Assets/images/icons/subscribe_next_payment.svg";
import NewsBuilderIcon from "Assets/images/icons/newsbuilder_icon.svg";
import NewsBuilderPicture from "Assets/images/icons/newsbuilder_picture_icon.svg";
import NewsBuilderTrash from "Assets/images/icons/newsbuilder_trash.svg";
import NewsLetterBuilderTrash from "Assets/images/icons/newsletterbuilder_trash.svg";
import NewsLetterBuilderEx from "Assets/images/newsletterbuilder_img_ex.png";
import LmsEmptyIcon from "Assets/images/icons/lms_empty_icon.svg";
import MyCommentIcon from "Assets/images/icons/mycomment_icon.svg";

// styles
import {
  LmsSt,
  LmsSubscribe,
  LmsDashboard,
  NewsBuilderGo,
  LmsEmpty,
} from "./Lms.style";
import { BasicButton } from "Styles/styles";

// logics
import LmsLogic from "./Lms.logic";

import Loading from "Components/Loading/Loading";
import moment from "moment";
import { numberToPrice } from "Utils/lib/helper";
require("moment");
require("moment/locale/ko");

const Lms = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    location: "",
    type: "",
    status: "",
  });
  useEffect(() => {
    setUrlState({
      ...urlState,
      current: location.pathname,
      location:
        localStorage.getItem("type") === "parents" ? "parents" : "student",
      type: location.pathname.split("/lms/")[1].split("/")[1].split("/")[0],
      status: location.pathname.split(
        `/${location.pathname.split("/lms/")[1].split("/")[1]}/`
      )[1],
    });
  }, [location.pathname]);

  const { state, data, func } = LmsLogic({
    loading,
    setLoading,
    navigate,
    url: urlState,
  });

  return (
    <>
      {loading && <Loading loading={loading} />}
      <LmsSt>
        <LmsNav child_id={state.child_id} url={urlState} func={func} />
        {/* 대시보드 */}
        {urlState.type === "dashboard" && !data.dataState.data_loading ? (
          <LmsDashboard>
            <div>
              <h2>
                {
                  lmsJson[urlState.status].title[
                    localStorage.getItem("type") === "child"
                      ? "student"
                      : "parents"
                  ]
                }
              </h2>
              <h4>
                {
                  lmsJson[urlState.status].sub[
                    localStorage.getItem("type") === "child"
                      ? "student"
                      : "parents"
                  ]
                }
              </h4>
              {state.loading ? null : (
                <div
                  className={
                    urlState.status === "participation" ||
                    urlState.status === "news" ||
                    urlState.status === "newsletter"
                      ? "selectbox"
                      : "hidden"
                  }
                >
                  <Select
                    placeholder="2023년"
                    options={data.filterList.year_list}
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={150}
                    height={40}
                    defaultValue={
                      data.filterList.year_list.filter(
                        (i) => i.value === state.year
                      )[0]
                    }
                    onChange={(value) =>
                      func.setStateValue("year", value.value)
                    }
                  />

                  <Select
                    className={
                      urlState.status === "participation" ||
                      urlState.status === "news"
                        ? ""
                        : "hidden"
                    }
                    placeholder="1월"
                    options={data.filterList.month_list}
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={150}
                    height={40}
                    defaultValue={
                      data.filterList.month_list.filter(
                        (i) => i.value === state.month
                      )[0]
                    }
                    onChange={(value) =>
                      func.setStateValue("month", value.value)
                    }
                  />
                  <Select
                    className={
                      urlState.status === "participation" ||
                      urlState.status === "news"
                        ? ""
                        : "hidden"
                    }
                    placeholder="1주"
                    options={data.filterList.week_list}
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={150}
                    height={40}
                    defaultValue={
                      data.filterList.week_list.filter(
                        (i) => i.value === state.week
                      )[0]
                    }
                    onChange={(value) =>
                      func.setStateValue("week", value.value)
                    }
                  />
                </div>
              )}
            </div>
            {/* 뉴스놀이터 참여현황 */}
            {urlState.status === "participation" ? (
              <table>
                <thead>
                  <tr>
                    <td>분야</td>
                    <td>뉴스 제목</td>
                    <td>참여 여부</td>
                  </tr>
                </thead>
                <tbody>
                  {data.dataState.study_news.length > 0 ? (
                    <>
                      {data.dataState.study_news.map((item) => (
                        <tr
                          key={`news_${item.id}`}
                          onClick={() => navigate(`/news/${item.id}`)}
                        >
                          <td>
                            {item.first_categories.category_name},
                            {item.second_categories.category_name}
                          </td>
                          <td>{item.title}</td>
                          <td>
                            {item.is_studied ? (
                              <span className="tag finish">완료</span>
                            ) : (
                              <span className="tag not_yet">미완료</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr className="lms_empty">
                      <td>
                        <LmsEmpty>
                          <img src={LmsEmptyIcon} alt="lms공백 아이콘" />
                          <p>
                            {urlState.location === "parents" &&
                            state.user_info.family.length == 0
                              ? "아직 연결된 자녀가 없어요."
                              : "아직 학습할 뉴스가 없어요."}
                          </p>
                        </LmsEmpty>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : null}
            {/* 뉴스목록 */}
            {urlState.status === "news" ? (
              <>
                {urlState.location === "student" ? (
                  <NewsBuilderGo>
                    <img src={NewsBuilderIcon} alt="뉴스빌더 심볼 아이콘" />
                    <h4>나만의 뉴스를 작성해볼까요?</h4>
                    <button onClick={() => navigate("/builder/news/temp/null")}>
                      뉴스 빌더 바로가기
                    </button>
                  </NewsBuilderGo>
                ) : null}
                {data.dataState.my_news.length > 0 ? (
                  <ul className="newsbuilder">
                    {data.dataState.my_news.map((item) => (
                      <li key={`news_${item.id}`}>
                        <div
                          onClick={
                            urlState.location === "student"
                              ? item.save_type === "temp"
                                ? () =>
                                    navigate(`/builder/news/temp/${item.id}`)
                                : () =>
                                    navigate(`/builder/news/save/${item.id}`)
                              : item.save_type === "temp"
                              ? () =>
                                  func.setStateValue("alert", {
                                    title:
                                      "학생이 임시저장한 데이터는 열람하실 수 없습니다.",
                                    sub: null,
                                    isOk: () =>
                                      func.setStateValue("alert", null),
                                    isCancel: null,
                                  })
                              : () => navigate(`/builder/news/save/${item.id}`)
                          }
                        >
                          {item.images && item.images.image_url ? (
                            <img
                              src={item.images.image_url}
                              alt={`${item.title} 뉴스 사진`}
                              className=""
                            />
                          ) : (
                            <img
                              src={NewsBuilderPicture}
                              alt="뉴스빌더 사진 아이콘"
                              className="newsbuilder_picture_icon"
                            />
                          )}
                        </div>
                        <div
                          onClick={
                            item.save_type === "temp"
                              ? () => navigate(`/builder/news/temp/${item.id}`)
                              : () => navigate(`/builder/news/save/${item.id}`)
                          }
                        >
                          <div className="tagbox">
                            {item.first_categories ? (
                              <span>{item.first_categories.category_name}</span>
                            ) : null}
                            {item.second_categories ? (
                              <span>
                                {item.second_categories.category_name}
                              </span>
                            ) : null}
                          </div>
                          <h3>
                            {item.title ? item.title : "제목없음"}
                            {item.save_type === "temp" ? (
                              <span> 작성중</span>
                            ) : null}
                          </h3>
                          {item.published_at ? (
                            <h6>
                              {moment(item.published_at).format("YYYY.MM.DD.")}
                            </h6>
                          ) : null}
                        </div>
                        {urlState.location === "student" ? (
                          <div
                            onClick={() =>
                              func.setModalData(true, "delete_modal", item.id)
                            }
                          >
                            <img src={NewsBuilderTrash} alt="뉴스빌더 휴지통" />
                          </div>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <LmsEmpty>
                    <img src={LmsEmptyIcon} alt="lms공백 아이콘" />
                    <p>
                      {urlState.location === "parents" &&
                      state.user_info.family.length == 0
                        ? "아직 연결된 자녀가 없어요."
                        : "아직 작성한 뉴스가 없어요."}
                    </p>
                  </LmsEmpty>
                )}
              </>
            ) : null}
            {/* 뉴스레터 */}
            {urlState.status === "newsletter" ? (
              <>
                {urlState.location === "student" ? (
                  <NewsBuilderGo>
                    <img src={NewsBuilderIcon} alt="뉴스빌더 심볼 아이콘" />
                    <h4>나만의 뉴스를 작성해볼까요?</h4>
                    <button
                      onClick={() => navigate("/builder/newsletter/temp/null")}
                    >
                      뉴스레터 빌더 바로가기
                    </button>
                  </NewsBuilderGo>
                ) : null}
                {data.dataState.news_letter.length > 0 ? (
                  <ul className="newsletterbuilder">
                    {data.dataState.news_letter.map((item) => (
                      <li key={`letter_${item.id}`}>
                        <div className="newsletter_picture">
                          {item.save_type === "temp" ? (
                            <img
                              onClick={
                                item.save_type === "temp"
                                  ? () =>
                                      navigate(
                                        `/builder/newsletter/temp/${item.id}`
                                      )
                                  : () =>
                                      navigate(
                                        `/builder/newsletter/save/${item.id}`
                                      )
                              }
                              src={NewsLetterBuilderEx} // 이게 임시저장일때 (미리보기 지원x)
                              alt="뉴스레더빌더 이미지"
                            />
                          ) : (
                            // 저장 상태일때 퍼블리싱 해야하는 것
                            <div
                              onClick={
                                item.save_type === "temp"
                                  ? () =>
                                      navigate(
                                        `/builder/newsletter/temp/${item.id}`
                                      )
                                  : () =>
                                      navigate(
                                        `/builder/newsletter/save/${item.id}`
                                      )
                              }
                            >
                              {/* 몇월호 */}
                              <h5 className="month">
                                {item.year}년{item.month}월호
                              </h5>
                              {/* 제목 */}
                              <h3 className="title">{item.title}</h3>
                              <img
                                src={NewsLetterBuilderEx} // 뉴스레터 퍼블리싱 뒷배경
                                alt="뉴스레더빌더 이미지"
                              />
                              {/* 기자 이름 */}
                              <h4 className="name">
                                {data.dataState.news_letter_writer}
                              </h4>
                            </div>
                          )}
                          {urlState.location === "student" ? (
                            <div
                              className="trash_box"
                              onClick={() =>
                                func.setModalData(true, "delete_modal", item.id)
                              }
                            >
                              <img
                                src={NewsLetterBuilderTrash}
                                alt="뉴스레터 빌더 휴지통"
                              />
                            </div>
                          ) : null}
                        </div>

                        <h5>
                          {item.title}
                          {item.save_type === "temp" ? (
                            <span>(작성중)</span>
                          ) : null}
                        </h5>
                        {item.month ? <span>{item.month}월</span> : null}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <LmsEmpty>
                    <img src={LmsEmptyIcon} alt="lms공백 아이콘" />
                    <p>
                      {urlState.location === "parents" &&
                      state.user_info.family.length == 0
                        ? "아직 연결된 자녀가 없어요."
                        : "아직 작성한 뉴스레터가 없어요."}
                    </p>
                  </LmsEmpty>
                )}
              </>
            ) : null}
            {/* 좋아한 뉴스 */}
            {urlState.status === "likednews" ? (
              <>
                {data.dataState.liked_news.length > 0 ? (
                  <ul className="likednews">
                    {data.dataState.liked_news.map((item) => (
                      <li
                        key={`liked_${item.id}`}
                        onClick={() => navigate(`/news/${item.news.id}`)}
                      >
                        <div className="newsletter_picture">
                          {item.news.images ? (
                            <img
                              src={item.news.images.image_url}
                              alt="좋아한 뉴스"
                            />
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className="tagbox">
                          <span>
                            {item.news.first_categories.category_name}
                          </span>
                          <span>
                            {item.news.second_categories.category_name}
                          </span>
                        </div>
                        <h4>{item.news.title}</h4>
                        <h6>{moment(item.created_at).format("YYYY.MM.DD")}</h6>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <LmsEmpty>
                    <img src={LmsEmptyIcon} alt="lms공백 아이콘" />
                    <p>
                      {urlState.location === "parents" &&
                      state.user_info.family.length == 0
                        ? "아직 연결된 자녀가 없어요."
                        : "아직 좋아한 뉴스가 없어요."}
                    </p>
                  </LmsEmpty>
                )}
              </>
            ) : null}
            {/* 나의 댓글 : 학생만 */}
            {urlState.status === "mycomment" ? (
              <>
                {data.dataState.comment_news.length > 0 ? (
                  <>
                    <ul className="mycomment">
                      {data.dataState.comment_news.map((item) => (
                        <li
                          key={`news_${item.id}`}
                          onClick={() => navigate(`/news/${item.id}`)}
                        >
                          <div className="mycomment_picture">
                            {item.contents !== [] ? (
                              item.contents.length > 0 ? (
                                <img
                                  src={item.contents[0].images.image_url}
                                  alt="댓글단 뉴스"
                                />
                              ) : null
                            ) : null}

                            <div>
                              <h4>{item.title}</h4>
                              <h6>
                                {moment(item.published_at).format("YYYY.MM.DD")}
                              </h6>
                            </div>
                          </div>
                          <ul>
                            {item.news_comments.map((i) => (
                              <li key={`comment_${i.id}`}>
                                <img
                                  src={MyCommentIcon}
                                  alt="나의댓글 아이콘"
                                />
                                <p>{i.comment}</p>
                                {i.status === "request" ? (
                                  <span className="tag approve_tag">
                                    승인중
                                  </span>
                                ) : i.status === "fail" ? (
                                  <span className="tag red_tag">
                                    정책상의 이유로 승인이 반려된 댓글입니다.
                                  </span>
                                ) : (
                                  <span className="tag">
                                    {moment(i.created_at).fromNow()}
                                  </span>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>{" "}
                    <Pager
                      page={data.currentPage}
                      count={10}
                      total={data.dataState.comment_news_total}
                      paging={func.paging}
                    />
                  </>
                ) : (
                  <LmsEmpty>
                    <img src={LmsEmptyIcon} alt="lms공백 아이콘" />
                    <p>아직 작성한 댓글이 없어요.</p>
                  </LmsEmpty>
                )}
              </>
            ) : null}
          </LmsDashboard>
        ) : null}
        {/* 구독관리 */}
        {urlState.type === "subscribe" ? (
          <LmsSubscribe>
            <div>
              <h2>구독 관리</h2>
              <h4>현재 이용중인 구독 서비스</h4>
              <ul>
                <li>
                  <span>
                    <img src={SubscribePlan} alt="나의플랜 아이콘" />
                    <p>나의 플랜</p>
                    <b
                      onClick={() =>
                        func.setStateValue("is_setting", !state.is_setting)
                      }
                    >
                      관리
                    </b>
                  </span>
                  <h5>
                    {state.user_payment_info.subscription &&
                    state.user_payment_info.subscription.length > 0
                      ? state.user_payment_info.subscription.products
                          .product_name
                      : "-"}
                  </h5>
                </li>
              </ul>
              <ul>
                <li>
                  <span>
                    <img src={SubscribeNextPayment} alt="다음결제일 아이콘" />
                    <p>다음 결제일</p>
                  </span>
                  <h5>
                    {state.user_payment_info.subscription &&
                    state.user_payment_info.subscription.length > 0
                      ? moment(
                          state.user_payment_info.subscription.next_paid_at
                        ).format("YYYY.MM.DD")
                      : "-"}
                  </h5>
                </li>
              </ul>
              <ul>
                <li>
                  <span>
                    <img src={SubscribePayment} alt="결제 예정 금액 아이콘" />
                    <p>결제 예정 금액</p>
                  </span>
                  <h5>
                    {state.user_payment_info.subscription &&
                    state.user_payment_info.subscription.length > 0
                      ? moment(
                          state.user_payment_info.subscription.next_paid_at
                        ).format("YYYY.MM.DD")
                      : "-"}
                  </h5>
                </li>
              </ul>
            </div>
            <div>
              <h2>결제 수단</h2>
              <h4>등록된 결제 수단</h4>
              <ul>
                <li>
                  <span>
                    <img src={SubscribePlan} alt="나의플랜 아이콘" />
                    <p>
                      {state.user_payment_info.user_card
                        ? state.user_payment_info.user_card.card_name
                        : "등록된 결제 수단이 없습니다."}
                    </p>
                  </span>
                  <h5>
                    {state.user_payment_info.user_card
                      ? state.user_payment_info.user_card.card_number
                      : ""}
                  </h5>
                  <button
                    onClick={() => func.setModalData(true, "payment_change")}
                  >
                    결제 수단 변경
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h2>결제 내역</h2>
              <table>
                <tbody>
                  {state.user_payment_info.payments
                    ? state.user_payment_info.payments.payments.map((item) => (
                        <tr key={`payment_${item.id}`}>
                          <td>
                            <h6>
                              {item.subscriptions.products.product_name}(
                              {moment(item.subscriptions.started_at).format(
                                "YYYY.MM.DD"
                              )}{" "}
                              -{" "}
                              {moment(item.subscriptions.ended_at).format(
                                "YYYY.MM.DD"
                              )}
                              )
                              <span>
                                {numberToPrice(
                                  item.subscriptions.products.price
                                )}
                                원
                              </span>
                            </h6>
                            <p>
                              {moment(item.paid_at).format("YYYY.MM.DD")}
                              <span>카드 결제</span>
                            </p>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
                <Pager
                  page={data.currentPage}
                  count={10}
                  total={state.total}
                  paging={func.paging}
                />
              </table>
            </div>
            {state.is_setting === true ? (
              <div className="subscribe_setting_all">
                <div>
                  <div className="subscribe_setting_white">
                    <h3>관리</h3>
                    <i
                      className="xi-close-min"
                      onClick={() =>
                        func.setStateValue("is_setting", !state.is_setting)
                      }
                    ></i>
                    <div>
                      <div>
                        <h6>나의 플랜</h6>
                      </div>
                      <input
                        type="text"
                        className="grey_input"
                        readOnly={true}
                        value={
                          state.user_payment_info.subscription &&
                          state.user_payment_info.subscription.length > 0
                            ? state.user_payment_info.subscription.products
                                .product_name
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <div>
                        <h6>결제 주기</h6>
                      </div>
                      <input
                        type="text"
                        className="grey_input"
                        readOnly={true}
                        value={
                          state.user_payment_info.subscription &&
                          state.user_payment_info.subscription.length > 0
                            ? moment(
                                state.user_payment_info.subscription
                                  .next_paid_at
                              ).format("YYYY.MM.DD")
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <div>
                        <h6>만료일</h6>
                      </div>
                      <input
                        type="text"
                        className="grey_input"
                        readOnly={true}
                        value={
                          state.user_payment_info.subscription &&
                          state.user_payment_info.subscription.length > 0
                            ? moment(
                                state.user_payment_info.subscription.ended_at
                              ).format("YYYY.MM.DD")
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <div>
                        <h6>상태</h6>
                      </div>
                      <input
                        type="text"
                        className="grey_input"
                        readOnly={true}
                        value={
                          state.user_payment_info.subscription &&
                          state.user_payment_info.subscription.length > 0
                            ? state.user_payment_info.subscription.status ===
                              "progress"
                              ? "구독중"
                              : "구독해지"
                            : ""
                        }
                      />
                    </div>
                    <span>
                      <strong>안내사항</strong>
                      <p>
                        <span>&bull;</span>구독시 구독 주기에 맞추어 자동으로
                        결제가 이루어져요.
                      </p>
                      <p>
                        <span>&bull;</span>
                        구독을 해지해도 만료일까지 서비스를 이용할 수 있어요.
                      </p>
                      <p>
                        <span>&bull;</span>
                        등록된 카드로 구독료가 결제 돼요.
                      </p>
                    </span>
                    {/* TODO : css 수정 , 클릭해서 나오는 Alert 수정 */}
                    {state.user_payment_info.subscription &&
                    state.user_payment_info.subscription.length > 0 ? (
                      <p
                        onClick={() =>
                          func.setStateValue("alert", {
                            title: "구독을 해지할까요?",
                            sub: "구독을 해지하면 관련 뉴스 더보기, 뉴스 빌더 등 위그의 다양한 서비스를 이용하실 수 없어요.",
                            isOk: () => func.cancelSubscription(),
                            isCancel: () => func.setStateValue("alert", null),
                          })
                        }
                        className="subscription_text"
                      >
                        구독 해지
                      </p>
                    ) : (
                      <p
                        onClick={() =>
                          (window.location.href = "/subscribe/info/null")
                        }
                        className="subscription_text"
                      >
                        구독 하기
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </LmsSubscribe>
        ) : null}
      </LmsSt>

      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}

      {/* 게시물 삭제 모달*/}
      {state.modal.open && state.modal.type === "delete_modal" ? (
        <Modal
          minWidth={370}
          maxHeight={287}
          borderRadius={12}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="modal_style">
                <h3>작성한 뉴스를 삭제하시겠어요?</h3>
                <h4>삭제한 뉴스는 다시 되돌릴 수 없어요.</h4>
                <div className="btns">
                  <BasicButton
                    className="grey2"
                    onClick={() =>
                      func.setModalData(!state.modal.open, null, null)
                    }
                  >
                    취소하기
                  </BasicButton>
                  <BasicButton
                    className="main"
                    onClick={() =>
                      func.deleteNews(urlState.status, state.modal.id)
                    }
                  >
                    삭제하기
                  </BasicButton>
                </div>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}

      {/* 결제 수단 변경 */}
      {state.modal.open && state.modal.type === "payment_change" ? (
        <Modal
          minWidth={500}
          // minHeight={600}
          maxHeight={600}
          borderRadius={10}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="payment_change">
                <h3>결제 수단 변경</h3>
                <div>
                  <div>
                    <h6>카드 번호</h6>
                  </div>
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_num01", value)
                    }
                  />
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_num02", value)
                    }
                  />
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_num03", value)
                    }
                  />
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_num04", value)
                    }
                  />
                </div>
                <div>
                  <div>
                    <h6>생년월일</h6>
                  </div>
                  <input
                    type="number"
                    placeholder="930101"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_birth", value)
                    }
                  />
                </div>

                <div className="mb40">
                  <div>
                    <h6>유효기간</h6>
                  </div>
                  <Select
                    placeholder="월"
                    options={data.filterList.card_month_list}
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={140}
                    height={48}
                    onChange={(value) =>
                      func.setInputStateValue("card_expiry_month", value.value)
                    }
                  />
                  <Select
                    placeholder="년도"
                    options={data.filterList.card_year_list}
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={140}
                    height={48}
                    onChange={(value) =>
                      func.setInputStateValue("card_expiry_year", value.value)
                    }
                  />
                </div>
                <div>
                  <div>
                    <h6>비밀번호 앞 두자리</h6>
                  </div>
                  <input
                    type="number"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("card_pwd_2digit", value)
                    }
                  />
                  <input
                    type="password"
                    className="grey_input"
                    readOnly={true}
                  />
                </div>
                <button onClick={() => func.setPaymentCard()}>확인</button>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}

      {/* 결제 수단 변경 실패 */}
      {state.modal.open && state.modal.type === "payment_fail" ? (
        <Modal
          minWidth={379}
          minHeight={317}
          // tabletWidth={289}
          borderRadius={10}
          // tabletMinHeight={"142px"}
          // tabletMaxHeight={"142px"}
          // mobileWidth={"289px"}
          // mobileMinHeight={"142px"}
          // mobileMaxHeight={"142px"}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="payment_fail">
                <h3>결제 수단 변경에 실패했어요.</h3>
                <h4>다음과 같은 사유로 실패할 수 있어요.</h4>
                <span>
                  <strong>실패사유</strong>
                  <p>
                    <span>&bull;</span>입력한 정보가 일치하지 않는 경우
                  </p>

                  <p>
                    <span>&bull;</span>
                    카드의 유효기간이 만료된 경우
                  </p>
                </span>
                <button
                  onClick={() =>
                    func.setModalData(!state.modal.open, null, null)
                  }
                >
                  확인
                </button>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}
    </>
  );
};

export default Lms;

import styled from "styled-components";

export const FooterSt = styled.footer`
  width: 100%;
  background-color: var(--grey3);
  position: relative;
  > div {
    color: var(--grey6);
    padding: 62px 0;
    width: 1240px;
    margin: 0 auto;
    > div {
      display: inline-block;
      vertical-align: top;
      ul {
        margin-bottom: 14px;
        li {
          display: inline-block;
          vertical-align: middle;
          margin-right: 28px;
          font-size: 14px;
          img {
            width: 69px;
            height: 24px;
          }
        }
        &.footer_img {
          li {
            margin-right: 60px;
            cursor: initial !important;
            width: 69px;
            height: 24px;
          }
        }
        &:nth-child(1) {
          margin-bottom: 26px;
          font-weight: 700;
          li {
            cursor: pointer;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 682px;
    }
    @media screen and (max-width: 809px) {
      width: 90%;
      > div {
        display: block;
        ul {
          display: block;
          li {
            display: block;
            margin-bottom: 10px;
            line-height: 140%;
            font-size: 12px;
            img {
              width: 69px;
              height: 24px;
            }
          }
          &.footer_img {
            margin-bottom: 60px !important;
          }
          &:nth-child(1) {
            margin-bottom: 26px;
            li {
              margin-bottom: 18px;
              width: fit-content;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { FooterSt } from "./Footer.style";

//img
import FooterLogo from "Assets/images/icons/footer_logo.png";

const Footer = ({ current, notification, setOnoff }) => {
  const [state, setState] = useState({
    current: "",
    menu_open: false,
    terms_list: [],
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setState({
      ...state,
      current: location.pathname,
    });
  }, [location.pathname]);

  return (
    <>
      <FooterSt>
        <div>
          <div>
            <ul className="footer_img">
              <li>
                <img src={FooterLogo} alt="푸터 로고" />
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li
                onClick={() =>
                  window.open(
                    "https://wigg-resource.s3.ap-northeast-2.amazonaws.com/default/1.+%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4+%EC%88%98%EC%A7%91+%EB%B0%8F+%EC%9D%B4%EC%9A%A9%EB%8F%99%EC%9D%98.pdf",
                    "_blank"
                  )
                }
              >
                개인정보처리방침
              </li>
              <li
                onClick={() =>
                  window.open(
                    "https://wigg-resource.s3.ap-northeast-2.amazonaws.com/default/2.+%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80.pdf",
                    "_blank"
                  )
                }
              >
                이용약관
              </li>
            </ul>
            <ul>
              <li>(주)온플</li>
              <li>대표 : 연수경</li>
            </ul>
            <ul>
              <li>사업자등록번호 : 811-87-02597</li>
            </ul>
            <ul>
              <li>Copyright (주)온플. All rights reserved.</li>
            </ul>
          </div>
        </div>
      </FooterSt>
    </>
  );
};

export default Footer;

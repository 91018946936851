import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// json

// components
import { Modal, Alert } from "Components";

// styles
import { BasicButton, Container, InputBox, LogoBox } from "Styles/styles";

// img
import Logo from "Assets/images/icons/wigg_logo.svg";

// logics
import FindInfoLogic from "./FindInfo.logic";

const FindInfo = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlInfo, setUrlInfo] = useState({
    current: "",
    type: "",
  });
  useEffect(() => {
    setUrlInfo({
      ...urlInfo,
      current: location.pathname,
      location: location.pathname.split("/find/")[1].split("/")[0],
      type: location.pathname.split("/find/")[1].split("/")[1],
    });
  }, [location.pathname]);
  const { state, data, edit, func } = FindInfoLogic({
    loading,
    setLoading,
    url: urlInfo,
  });

  return (
    <Container>
      <LogoBox>
        <div>
          <img src={Logo} alt="로고이미지" />
          <h4>
            {urlInfo.location === "id"
              ? state.step === 1
                ? `${urlInfo.type === "student" ? "아이디" : "이메일"}찾기`
                : state.step === 2
                ? `${urlInfo.type === "student" ? "아이디" : "이메일"}안내`
                : null
              : urlInfo.location === "pwchange"
              ? "비밀번호 변경"
              : urlInfo.type === "student" || urlInfo.type === "parents"
              ? "비밀번호 찾기"
              : null}
          </h4>
          <h5>
            {urlInfo.location === "id"
              ? state.step === 1
                ? urlInfo.type === "student"
                  ? "이름 입력 후 인증을 진행해주세요."
                  : "본인인증을 진행해주세요."
                : urlInfo.type === "student"
                ? "등록된 나의 아이디에요."
                : "등록된 나의 이메일이에요."
              : (urlInfo.type === "student" || urlInfo.type === "parents") &&
                state.step === 1
              ? "아이디 입력 후 인증을 진행해주세요."
              : null}
          </h5>
        </div>
      </LogoBox>
      {(urlInfo.type === "student" || urlInfo.type === "parents") &&
      state.step === 1 ? (
        <>
          <InputBox>
            <div>
              {urlInfo.location === "id" ? (
                // 아이디 찾기 학생일때
                urlInfo.type === "student" ? (
                  <>
                    <h6>이름</h6>
                    <input
                      type="text"
                      placeholder="이름을 입력해주세요"
                      onChange={({ target: { value } }) =>
                        func.setInputStateValue("name", value)
                      }
                    />
                  </>
                ) : // 아이디 찾기 학부모일때 본인인증만 진행
                null
              ) : (
                // 비밀번호 찾기
                <>
                  <h6> {urlInfo.type === "student" ? "아이디" : "이메일"}</h6>
                  <input
                    type="text"
                    placeholder={`${
                      urlInfo.type === "student" ? "아이디" : "이메일"
                    }를 입력해주세요  `}
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("login_id", value)
                    }
                  />
                </>
              )}
              {urlInfo.location === "pw" && state.find_err ? (
                <p className="error_tag">{`가입되지 않은 ${
                  urlInfo.type === "student" ? "아이디" : "이메일"
                }에요.`}</p>
              ) : null}
            </div>
            <div>
              <h6>{urlInfo.type === "student" ? "보호자" : ""} 휴대폰 번호</h6>
              <input
                type="text"
                className="short_input"
                onChange={({ target: { value } }) =>
                  func.setInputStateValue("phone_number", value)
                }
              />
              {state.phone_send ? (
                <>
                  <input
                    type="text"
                    className="short_input certification_input"
                    placeholder="인증번호를 입력해주세요."
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("code", value)
                    }
                  />
                  <div className="btns">
                    <button onClick={() => func.checkPhoneOk("send")}>
                      재전송
                    </button>
                    <button onClick={() => func.checkPhoneOk("check")}>
                      확인
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="certification_button"
                  onClick={() => func.checkPhoneOk("send")}
                >
                  인증번호 받기
                </button>
              )}
            </div>
          </InputBox>
        </>
      ) : null}
      {urlInfo.location === "id" && state.step === 2 ? (
        <InputBox>
          <div>
            {urlInfo.type === "student" ? <h6>아이디</h6> : <h6>이메일</h6>}
            <input
              type="text"
              className="grey_input"
              value={state.find_id}
              readOnly={true}
            />
          </div>
          <p
            className="find_password"
            onClick={() => (window.location.href = `/find/pw/${urlInfo.type}`)}
          >
            비밀번호 찾기
          </p>
          <div className="btns">
            <BasicButton
              className="main login_go"
              onClick={() => navigate("/login/student")}
            >
              로그인 하기
            </BasicButton>
          </div>
        </InputBox>
      ) : null}
      {(urlInfo.location === "pw" || urlInfo.location === "pwchange") &&
      state.step === 2 ? (
        <InputBox>
          <div className="mb30">
            <h6>새 비밀번호</h6>
            <input
              type="password"
              placeholder="영어, 숫자로 20자 이내"
              onChange={({ target: { value } }) =>
                func.setInputStateValue("pw", value)
              }
            />
            {/*  */}
            {state.pw_err && !data.inputData.pw_check ? (
              <p className="error_tag">{state.pw_err}</p>
            ) : null}
          </div>
          <div>
            <h6>새 비밀번호 확인</h6>
            <input
              type="password"
              placeholder="비밀번호를 한번 더 입력해주세요"
              onChange={({ target: { value } }) =>
                func.setInputStateValue("pw_check", value)
              }
            />
            {state.pw_err && data.inputData.pw_check ? (
              <p className="error_tag">{state.pw_err}</p>
            ) : null}
            {state.pw_check ? (
              <p className="safe_tag">비밀번호가 안전합니다.</p>
            ) : null}
          </div>
          <div className="btns">
            <BasicButton className="main2" onClick={() => func.changePw()}>
              확인
            </BasicButton>
          </div>
        </InputBox>
      ) : null}

      {state.modal.open ? (
        <Modal
          minWidth={289}
          minHeight={142}
          maxHeight={142}
          tabletWidth={289}
          borderRadius={10}
          tabletMinHeight={"142px"}
          tabletMaxHeight={"142px"}
          mobileWidth={"289px"}
          mobileMinHeight={"142px"}
          mobileMaxHeight={"142px"}
          data={
            <>
              <div className="find_okay">
                <h3>
                  임시 비밀번호가 작성하신 이메일로
                  <br />
                  전송되었습니다.
                </h3>
                <button onClick={() => navigate("/login")}>확인</button>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </Container>
  );
};

export default FindInfo;

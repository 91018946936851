import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import LoginLogic from "./Login.logic";
import LoginSt from "./Login.style";
import Loading from "Components/Loading/Loading";

// img
import Logo from "Assets/images/icons/wigg_logo.png";
import { Alert } from "Components";

const Login = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: "",
  });

  useEffect(() => {
    setUrlState({
      ...urlState,
      current: location.pathname,
      type: location.pathname.split("/login/")[1],
    });
  }, [location.pathname]);
  const { state, data, func } = LoginLogic({
    loading,
    setLoading,
    navigate,
    url: {
      type: urlState.type,
      keyword: urlState.keyword,
    },
  });
  return (
    <>
      {loading && <Loading loading={loading} />}
      <LoginSt>
        <h1>
          <img src={Logo} alt="로고이미지" />
          <ul>
            <li
              className={urlState.type === "student" ? "on" : ""}
              onClick={() => navigate("/login/student")}
            >
              14세 미만 회원
            </li>
            <li
              className={urlState.type === "parents" ? "on" : ""}
              onClick={() => navigate("/login/parents")}
            >
              14세 이상 회원
            </li>
          </ul>
        </h1>
        <div className="form">
          <div className="mb30">
            <h6>아이디</h6>
            <input
              type="text"
              placeholder="아이디를 입력해주세요"
              onChange={({ target: { value } }) =>
                func.setStateValue("id", value)
              }
            />
            {/* <p className="error_tag">가입되지 않은 아이디에요</p> */}
          </div>
          <div>
            <h6>비밀번호</h6>
            <input
              type="password"
              placeholder="비밀번호를 입력해주세요"
              onChange={({ target: { value } }) =>
                func.setStateValue("pw", value)
              }
            />
            {/* <p className="error_tag">비밀번호가 맞지 않아요</p> */}
          </div>
          <ul>
            <li onClick={() => navigate(`/find/id/${urlState.type}`)}>
              {urlState.type === "student" ? "아이디" : "이메일"} 찾기
            </li>
            <li onClick={() => navigate(`/find/pw/${urlState.type}`)}>
              비밀번호 찾기
            </li>
          </ul>
          <div className="login_button">
            <button onClick={() => func.login()}>로그인</button>
            <p>
              아직 회원이 아니신가요?
              <span onClick={() => navigate("/signup/info")}>회원가입</span>
            </p>
          </div>
        </div>
      </LoginSt>
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );
};

export default Login;

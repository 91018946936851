import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

// components
import { Alert } from "Components";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

// img
import Logo from "Assets/images/icons/main_logo.png";
import Laptop from "Assets/images/icons/laptop.png";
import News01 from "Assets/images/icons/landing_news01.png";
import News02 from "Assets/images/icons/landing_news02.png";
import News03 from "Assets/images/icons/landing_news03.png";
import News04 from "Assets/images/icons/landing_news04.png";
import Newsbuild01 from "Assets/images/icons/landing_newsplay01.png";
import Newsbuild02 from "Assets/images/icons/landing_newsplay02.png";
import Newsbuild03 from "Assets/images/icons/landing_newsplay03.png";
import Space01 from "Assets/images/icons/landing_space01.svg";
import Space02 from "Assets/images/icons/landing_space02.svg";
import SpaceList01 from "Assets/images/icons/landing_space_list01.png";
import SpaceList02 from "Assets/images/icons/landing_space_list02.png";
import SpaceList03 from "Assets/images/icons/landing_space_list03.png";
import SpaceList04 from "Assets/images/icons/landing_space_list04.png";
import SpaceList05 from "Assets/images/icons/landing_space_list05.png";
import SpaceList06 from "Assets/images/icons/landing_space_list06.png";
import Star from "Assets/images/icons/star.svg";
import Mail from "Assets/images/icons/landing_mail.png";
import LandingArrow from "Assets/images/icons/landing_arrow.svg";
import LandingArrow2 from "Assets/images/icons/landing_arrow2.svg";
import LandingCheck from "Assets/images/icons/landing_check.svg";
import Tablet from "Assets/images/icons/tablet_icon.svg";
import MainList1 from "Assets/images/icons/main_cicon.svg";
import MainList2 from "Assets/images/icons/main_purple_circle1.svg";
import MainList3 from "Assets/images/icons/main_purple_circle2.svg";
import MainList4 from "Assets/images/icons/main_talk_icon.svg";
import MainList5 from "Assets/images/icons/main_talk_icon2.svg";
import MainList6 from "Assets/images/icons/main_sky_icon.svg";
import MainList7 from "Assets/images/icons/main_circle_icon.svg";
import MainList8 from "Assets/images/icons/main_hands.svg";
import MainList9 from "Assets/images/icons/main_plane.svg";
import NewsbuilderImg from "Assets/images/newsbuilder_img.PNG";

// styles
import { Landing } from "Styles/styles";

// logics
import HomeLogic from "./Home.logic";

const Home = ({ loading, setLoading }) => {
  SwiperCore.use([Autoplay, Navigation]);

  const navigate = useNavigate();
  const { state, data, func } = HomeLogic({
    loading,
    setLoading,
    navigate,
  });
  return (
    <>
      <Landing>
        {/* main */}
        <div>
          <div className="title">
            <img src={Logo} alt="로고이미지" />
            <h1>
              어린이 뉴스 <br />
              이제 직접 읽고, 쓰고, <br /> 공유하자!
            </h1>
            <p>어린이 전용 뉴스 구독 서비스 & 뉴스 빌더 플랫폼</p>
          </div>
          <div className="laptop">
            <img src={Laptop} alt="랩탑이미지" />
          </div>
          <div className="main_list">
            <img src={MainList1} alt="메인 c 아이콘" />
            <img src={MainList2} alt="메인 보라색 원 아이콘" />
            <img src={MainList3} alt="메인 구슬 아이콘" />
            <img src={MainList4} alt="메인 핑크 말풍선" />
            <img src={MainList5} alt="메인 하늘 말풍선" />
            <img src={MainList6} alt="메인 하늘색 조각 아이콘" />
            <img src={MainList7} alt="메인 보라색 거울 아이콘" />
            <img src={MainList8} alt="메인 손 아이콘" />
            <img src={MainList9} alt="메인 비행기 아이콘" />
          </div>
          <span></span>
        </div>
        {/* div2 */}
        <div>
          <div>
            <h2>위그와 함께 우리 아이의</h2>
            <div>
              <ul>
                <li>시사상식</li>
                <li>문해력</li>
                <li>글쓰기</li>
                <li>ICT Literacy</li>
                <li>시사상식</li>
                <li>문해력</li>
                <li>글쓰기</li>
                <li>ICT Literacy</li>
              </ul>
            </div>
            <h2>을 높여주세요!</h2>
          </div>
          <button onClick={() => func.onClickLink("signup")}>
            무료로 시작하기
          </button>
        </div>
        {/* div3 */}
        <div>
          <h4>뉴스놀이터</h4>
          <h3>쉽게 읽는 뉴스!</h3>
          <p>
            세상 돌아가는 이야기를 학년별 맞춤 읽기 난이도로
            <br /> 쉽고 재밌게 읽으며 문해력과 시사 상식까지 한 번에 잡아요!
          </p>
          <Swiper
            className="swiper-wrapper"
            spaceBetween={24}
            slidesPerView={4}
            // slidesPerView={3}
            autoplay={{ disableOnInteraction: false }}
            loop={true}
            observer={false}
            observeParents={false}
            breakpoints={{
              0: {
                slidesPerView: 1.2,
                spaceBetween: 10,
                centeredSlides: true,
                observeParents: true,
                observer: true,
              },
              720: {
                slidesPerView: 2,
                centeredSlides: false,
              },
              1024: {
                slidesPerView: 4,
                // centeredSlides: true,
              },
            }}
          >
            <SwiperSlide className="swiper-slide">
              <div className="news news01">
                <img src={News01} alt="뉴스이미지01" className="news01_img" />
                <p>테크·전자·기술</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="news news02">
                <img src={News02} alt="뉴스이미지01" />
                <p>경제·금융</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="news news03">
                <img src={News03} alt="뉴스이미지01" />
                <p>세계·정치</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="news news04">
                <img src={News04} alt="뉴스이미지01" />
                <p>문화·사회</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* div4 */}
        <div>
          <h4>뉴스 빌더</h4>
          <h3>
            내가 직접 만드는,
            <br /> 나만의 뉴스!
          </h3>
          <div>
            <img src={NewsbuilderImg} alt="뉴스빌더이미지" />
          </div>
          <ul>
            <li>
              <img src={Newsbuild01} alt="뉴스빌더아이콘1" />
              템플릿을 활용해 관심있는 <br />
              주제의 뉴스를 써봐요.
            </li>
            <li>
              <img src={Newsbuild02} alt="뉴스빌더아이콘2" />
              내 이름을 걸고
              <br />
              뉴스레터를 발간해요.
            </li>
            <li>
              <img src={Newsbuild03} alt="뉴스빌더아이콘3" />
              내가 만든 뉴스레터를 <br />
              가족, 친구들과 공유해요.
            </li>
          </ul>
        </div>
        {/* div5 */}
        <div>
          <div>
            <img src={Space01} alt="노트북이미지" />
            <div>
              <h3>
                스스로 참여하는 <br />
                주도적인 공간
              </h3>
              <ul>
                <li>
                  <img src={SpaceList01} alt="뉴스공간이미지1" />
                  <span>
                    <h4>직접 작성하는 뉴스</h4>
                    <p>주제 선택부터 내용까지 스스로 작성해요.</p>
                  </span>
                </li>
                <li>
                  <img src={SpaceList02} alt="뉴스공간이미지2" />
                  <span>
                    <h4>쉽고 즐겁게 읽는 뉴스</h4>
                    <p>매주 새로운 뉴스를 스스로 찾아 읽어요.</p>
                  </span>
                </li>
                <li>
                  <img src={SpaceList03} alt="뉴스공간이미지3" />
                  <span>
                    <h4>또래와 함께 소통하는 공간</h4>
                    <p>내 생각을 정리하고 댓글을 통해 의견을 공유해요.</p>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <img src={Space02} alt="노트북이미지" />
            <div>
              <h3>
                철저히 관리하는 <br />
                안전한 공간
              </h3>
              <ul>
                <li>
                  <img src={SpaceList04} alt="뉴스공간이미지1" />
                  <span>
                    <h4>NO 상업적 광고</h4>
                    <p>보기 싫은 상업적 광고가 없어요. </p>
                  </span>
                </li>
                <li>
                  <img src={SpaceList05} alt="뉴스공간이미지2" />
                  <span>
                    <h4>NO 유해 콘텐츠</h4>
                    <p>잔인하거나 선정적인 유해 콘텐츠가 없어요.</p>
                  </span>
                </li>
                <li>
                  <img src={SpaceList06} alt="뉴스공간이미지3" />
                  <span>
                    <h4>NO 비방, 욕설, 비매너</h4>
                    <p>클린한 댓글 문화를 위해 항상 관리하고 있어요.</p>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* div6 */}
        <div>
          <h4>생생한 후기</h4>
          <h3>
            위그와 함께한 <br />
            사람들의 이야기
          </h3>
          <Swiper
            className="main_talk"
            spaceBetween={24}
            slidesPerView={3.5}
            loop={true}
            navigation={true}
            modules={[Navigation]}
            observer={true}
            observeParents={true}
            breakpoints={{
              0: {
                slidesPerView: 1.2,
                spaceBetween: 10,
                centeredSlides: true,
              },
              720: {
                slidesPerView: 2.5,
                spaceBetween: 24,
                centeredSlides: true,
              },
              1025: {
                slidesPerView: 3.5,
                spaceBetween: 24,
                centeredSlides: true,
              },
            }}
          >
            <SwiperSlide className="swiper-slide">
              <div>
                <p>
                  아이가 먼저 시사 이슈에 대해 물어봐서 깜짝 놀랐어요. 덕분에
                  저희 집 이야기거리가 풍성해졌습니다. 아이와 대화려면 엄마도
                  공부 좀 해야겠어요.
                </p>
                <span>
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                </span>
                <p className="parents_text">초 1학년 학부모 김OO</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div>
                <p>
                  아이 문해력이 많이 좋아졌어요. 초등 저학년도 읽기 쉽고 아이
                  생활과 관련이 있는 내용들도 많아서 시키지 않아도 먼저 찾아
                  읽어요.
                </p>
                <span>
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                </span>
                <p className="parents_text">초 3학년 학부모 유OO</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div>
                <p>
                  글쓰기에 관심이 없던 아이인데, 자기가 쓴 뉴스를 친구들과
                  공유하고 나서부터 신나서 뉴스를 써요. 주제도 신중하게 고르고
                  다 쓰고는 엄청 뿌듯해합니다.
                </p>
                <span>
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                </span>
                <p className="parents_text">초 4학년 학부모 서OO</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div>
                <p>
                  아직 저학년이라 댓글로 생각을 표현하는게 어렵지 않을까
                  싶었는데 엄마의 쓸데없는 걱정이었어요. 타이핑도 늘고 진지하게
                  댓글을 남기는 모습이 신기합니다.
                </p>
                <span>
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                </span>
                <p className="parents_text">초 1학년 학부모 장OO</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div>
                <p>
                  하나의 기사를 가지고 연관 기사도 읽고 댓글로 생각을 나누면서
                  사고력이 깊어진 것 같아요. 세상을 보는 눈도 넓어지고 다른
                  사람들 생각도 궁금해하네요.
                </p>
                <span>
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                  <img src={Star} alt="별이미지" />
                </span>
                <p className="parents_text">초 3학년 학부모 윤OO</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        
        {/* div7 */}
        <div className="subscribe_main">
          <h4>구독</h4>
          <ul>
            <li>
              <img src={Mail} alt="메일이미지" />
            </li>
            <li>
              <h4>무료</h4>
              <h3>0원</h3>
              <button onClick={() => func.onClickLink("signup")}>
                회원가입
              </button>
              <img src={LandingArrow} alt="구독박스화살표" />
            </li>
            <li>
              <h4>한 달 구독</h4>
              <h3>9,900원</h3>
              <button onClick={() => func.onClickLink(2)}>결제하기</button>
              <img src={LandingArrow} alt="구독박스화살표" />
            </li>
            <li>
              <h4>1년 구독</h4>
              <h3>
                <span>15%</span>99,000원
              </h3>
              <button onClick={() => func.onClickLink(3)}>결제하기</button>
              <img src={LandingArrow2} alt="구독박스화살표" />
            </li>
          </ul>
          <div className="subscribe_wrap">
            <div>뉴스 놀이터</div>
            <ul className="subscribe">
              <li>
                <span>
                  <h5>최신 뉴스 읽기</h5>
                  <p>매주 뉴스가 업데이트 돼요.</p>
                </span>
              </li>
              <li>
                <h6>무료</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li>
                <h6>한 달 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li>
                <h6>1년 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
            </ul>
            <ul className="subscribe">
              <li>
                <span>
                  <h5>댓글 놀이터</h5>
                  <p>댓글을 쓰고 읽으며 생각을 나눠요.</p>
                </span>
              </li>
              <li>
                <h6>무료</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li>
                <h6>한 달 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li>
                <h6>1년 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
            </ul>
            <ul className="subscribe">
              <li className="big2">
                <span>
                  <h5>관련 뉴스 더보기</h5>
                  <p>주제와 관련된 다양한 뉴스를 읽으며 사고력을 더해요.</p>
                </span>
              </li>
              <li className="big2">
                <h6>무료</h6>
              </li>
              <li className="big2">
                <h6>한 달 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li className="big2">
                <h6>1년 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
            </ul>
            <ul className="subscribe mb40 ">
              <li className="big2">
                <span>
                  <h5>좋아하는 뉴스 저장하기</h5>
                  <p>마음에 드는 뉴스를 저장해 나만의 콜렉션을 만들어요.</p>
                </span>
              </li>
              <li className="big2">
                <h6>무료</h6>
              </li>
              <li className="big2">
                <h6>한 달 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li className="big2">
                <h6>1년 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
            </ul>
          </div>
          <div className="subscribe_wrap">
            <div>뉴스 빌더</div>
            <ul className="subscribe">
              <li>
                <span>
                  <h5>나의 뉴스 작성하기</h5>
                  <p>관심있는 주제의 뉴스를 매주 하나씩 써봐요.</p>
                </span>
              </li>
              <li>
                <h6>무료</h6>
              </li>
              <li>
                <h6>한 달 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li>
                <h6>1년 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
            </ul>
            <ul className="subscribe">
              <li>
                <span>
                  <h5>뉴스레터 작성하기</h5>
                  <p>나의 이름을 건 뉴스레터를 매월 만들어요.</p>
                </span>
              </li>
              <li>
                <h6>무료</h6>
              </li>
              <li>
                <h6>한 달 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li>
                <h6>1년 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
            </ul>
            <ul className="subscribe">
              <li className="big">
                <span>
                  <h5>뉴스를 발간하고 공유하기</h5>
                  <p>
                    직접 쓴 뉴스와 뉴스레터를 발간하고 가족, 친구와 공유해요.
                  </p>
                </span>
              </li>
              <li className="big">
                <h6>무료</h6>
              </li>
              <li className="big">
                <h6>한 달 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
              <li className="big">
                <h6>1년 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
            </ul>
            <ul className="subscribe mb110">
              <li className="big2">
                <span>
                  <h5>뉴스레터 모음 인쇄본 받기</h5>
                  <p>1년간 작성한 뉴스레터를 모아 인쇄본으로 간직해요.</p>
                </span>
              </li>
              <li className="big2">
                <h6>무료</h6>
              </li>
              <li className="big2">
                <h6>한 달 구독</h6>
              </li>
              <li className="big2">
                <h6>1년 구독</h6>
                <img src={LandingCheck} alt="체크이미지" />
              </li>
            </ul>
          </div>
        </div>
        {/* div8 */}
        <div>
          <div>
            <h1>
              뉴스로 즐겁게 학습하는 위그, <br />
              지금 시작해보세요.
            </h1>
            <button onClick={() => func.onClickLink("signup")}>
              무료로 시작하기
            </button>
          </div>

          <img src={Tablet} alt="태블랫이미지" />
        </div>
      </Landing>
      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );
};

export default Home;

import React, { useState, useCallback, useEffect, useRef } from "react";
import { toJpeg } from "html-to-image";
// api
import { mainApi } from "Utils/api/api";

const BuilderLogic = ({ loading, setLoading, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    data_loading: true,
    news: [],
    letter: [],
    modal: { open: false, type: "", id: "" },
  });
  const ref = useRef(null);
  useEffect(() => {
    if (url.id) {
      getData();
    }
  }, [url]);

  ////////////////  API  ////////////////
  // 임시 저장 데이터 불러오기
  const getData = async () => {
    try {
      const {
        data: { data },
      } = await mainApi.getUrlToNews({
        type: url.type === "news" ? url.type : "letter",
        id: url.id,
      });
      setState({
        ...state,
        data_loading: false,
        news: url.type === "news" ? data.news : data,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 뉴스 url
  const getNewsUrl = async (type) => {
    navigator.clipboard.writeText(`${window.location.origin}${url.current}`);
    setModalData(true, "copy");
  };

  // 뉴스 다운로드
  const getNewDownload = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toJpeg(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${state.news.title}.jpeg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
  };

  return {
    state,
    ref: { ref },
    func: {
      setStateValue,
      setModalData,
      getNewsUrl,
      getNewDownload,
    },
  };
};

export default BuilderLogic;

import styled from "styled-components";

export default styled.div`
  width: 100%;
  /* height: 100vh;
  min-height: 800px; */
  /* min-height: calc(100vh - 38px); */
  padding-bottom: 300px;
  min-height: 100vh;
  position: relative;
  background-color: var(--white);
  @media screen and (max-width: 809px) {
    padding-bottom: 134px;
  }
  h1 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 36px;
    position: relative;
    margin: 0 auto;
    padding-top: 160px;
    width: 308px;
    box-sizing: border-box;
    img {
      width: 119px;
      height: 35px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
      margin-bottom: 32px;
    }
    ul {
      display: block;
      text-align: center;
      background: #e9ebee;
      border-radius: 8px;
      margin-bottom: 50px;
      li {
        font-size: 16px;
        display: inline-block;
        padding: 10px 12px;
        line-height: 140%;

        font-weight: 700;
        color: var(--grey5);
        cursor: pointer;
        width: 50%;
        &::after {
          display: none;
        }
        &.on {
          background-color: var(--white);
          font-weight: 900;
          color: var(--text);
          border: 1px solid var(--grey3);
          border-radius: 8px;
        }
      }
    }
  }
  .form {
    position: relative;
    margin: 0 auto;
    width: 308px;
    height: 338px;
    text-align: left;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 300px;
  }
  div {
    text-align: left;
    margin-bottom: 14px;
    h6 {
      margin-bottom: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--grey7);
    }
    &:last-child {
      margin-bottom: 0;
    }
    input {
      padding-top: 3px;
      width: 100%;
      height: 48px;
      font-size: 14px;
      border: 1px solid var(--grey3);
      transition: border 0.1s ease-in-out, background-color 0.1s ease-in-out;
      padding: 14px 16px;
      border-radius: 8px;
      &::placeholder {
        font-size: 14px;
        color: var(--grey5);
      }
      &:focus {
        border: 1px solid var(--main);
        background-color: #fff;
      }
    }
    ul {
      text-align: right;
      margin-top: 14px;
      li {
        font-size: 14px;
        line-height: 140%;

        color: var(--grey6);
        display: inline-block;
        cursor: pointer;
        &:nth-child(1) {
          margin-right: 17px;
          position: relative;
          &::after {
            content: "";
            width: 1px;
            height: 10px;
            background-color: var(--grey4);
            position: absolute;
            right: -9px;
            top: 5px;
          }
        }
      }
    }
  }

  .error_tag {
    color: var(--red);
    font-size: 14px;
    line-height: 140%;
    margin-top: 6px;
  }
  .input_error {
    border: 1px solid var(--red);
  }
  .safe_tag {
    color: var(--text);
    font-size: 12px;
    line-height: 140%;
    margin-top: 6px;
  }

  .login_button {
    margin-top: 40px;
    button {
      width: 100%;
      height: 42px;
      color: var(--white);
      background-color: #a788ff;
      border-radius: 6px;
      margin-bottom: 24px;
      &:hover {
        background-color: var(--main);
        transition-duration: 0.4s;
      }
    }
    p {
      text-align: center;
      font-size: 14px;
      line-height: 140%;

      color: var(--grey6);
      span {
        border-bottom: 1px solid var(--main);
        color: var(--main);
        margin-left: 8px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  .btns {
    margin-top: 40px;
    button {
      padding: 11px 16px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 140%;

      font-weight: 500;
      &:nth-child(1) {
        background-color: var(--grey2);
        color: var(--grey6);
      }
      &:nth-child(2) {
        background-color: var(--sub);
        color: var(--white);
        float: right;
        &:hover {
          background-color: var(--main);
          transition-duration: 0ss;
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { LmsNavDiv } from "./LmsNav.style";
import lmsJson from "./lms.json";

// select
import { selectStyles, DropdownIndicator } from "Styles/select.style";
import Select, { components } from "react-select";
// img
import Setting from "Assets/images/icons/setting.svg";
import Dashboard from "Assets/images/icons/dashboard_main.svg";
import Subscribe from "Assets/images/icons/subscribe_main.svg";
import SubscribePlan from "Assets/images/icons/subscribe_plan.svg";
import DashboardNewsIcon1 from "Assets/images/icons/dashboard_news_icon1.svg";
import DashboardNewsIcon1_1 from "Assets/images/icons/dashboard_news_icon1_1.svg";
import DashboardNewsIcon2 from "Assets/images/icons/dashboard_news_icon2.svg";
import DashboardNewsIcon2_1 from "Assets/images/icons/dashboard_news_icon2_1.svg";
import DashboardNewsIcon3 from "Assets/images/icons/dashboard_news_icon3.svg";
import DashboardNewsIcon3_1 from "Assets/images/icons/dashboard_news_icon3_1.svg";
import DashboardNewsIcon4 from "Assets/images/icons/dashboard_news_icon4.svg";
import DashboardNewsIcon4_1 from "Assets/images/icons/dashboard_news_icon4_1.svg";
import DashboardNewsIcon5 from "Assets/images/icons/dashboard_news_icon5.svg";
import DashboardNewsIcon5_1 from "Assets/images/icons/dashboard_news_icon5_1.svg";
import LmsLogic from "./LmsNav.logic";
import moment from "moment";

const LmsNav = ({ child_id, url, func, setOnoff }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, data, funcs } = LmsLogic({
    navigate,
    url,
    menu_open: null,
  });
  return (
    <LmsNavDiv>
      <div>
        <h3>
          안녕하세요.
          <br /> {localStorage.getItem("user_nick_name")}님!
        </h3>
        <span onClick={() => (window.location.href = "/account/info/0")}>
          <img src={Setting} alt="설정 아이콘" />
          설정
        </span>
        {url.location === "parents" && state.user_info.family ? (
          <Select
            placeholder="자녀선택"
            options={
              state.user_info.family.length > 0
                ? state.user_info.family.map((i) => {
                    return { value: i.id, label: i.name };
                  })
                : [{ value: null, label: "연결 자녀 없음" }]
            }
            defaultValue={
              state.user_info.family.length > 0
                ? {
                    value: state.user_info.family[0].id,
                    label: state.user_info.family[0].name,
                  }
                : { value: null, label: "연결 자녀 없음" }
            }
            components={{ DropdownIndicator }}
            styles={selectStyles}
            width={272}
            height={48}
            onChange={(value) => func.setStateValue("child_id", value.value)}
          />
        ) : null}
      </div>
      <ul className="menu">
        <li
          className={
            url.type === "dashboard" ||
            url.type === "news" ||
            url.type === "newsletter"
              ? "on"
              : ""
          }
        >
          <h3
            onClick={() =>
              navigate(
                `/lms/${
                  localStorage.getItem("type") === "parents"
                    ? "parents"
                    : "student"
                }/dashboard/participation`
              )
            }
          >
            <img src={Dashboard} alt="대시보드 아이콘" />
            대시보드
          </h3>

          {/* className={state.menu_open === index ? "on" : ""} */}

          <ul
            className={
              url.type === "dashboard" ||
              url.type === "news" ||
              url.type === "newsletter"
                ? "on"
                : ""
            }
          >
            <li
              className={url.status === "participation" ? "on" : ""}
              onClick={() =>
                navigate(`/lms/${url.location}/dashboard/participation`)
              }
            >
              <img
                src={
                  url.status === "participation"
                    ? DashboardNewsIcon1_1
                    : DashboardNewsIcon1
                }
                alt="뉴스 놀이터 참여 현황 아이콘"
              />
              <p> 뉴스 놀이터 참여 현황</p>
            </li>
            <li
              className={
                url.status === "news" || url.type === "news" ? "on" : ""
              }
              onClick={() => navigate(`/lms/${url.location}/dashboard/news`)}
            >
              <img
                src={
                  url.status === "news" || url.type === "news"
                    ? DashboardNewsIcon2_1
                    : DashboardNewsIcon2
                }
                alt="나의 뉴스,뉴스 빌더 아이콘"
              />
              <p>
                {
                  lmsJson.news.title[
                    localStorage.getItem("type") === "child"
                      ? "student"
                      : "parents"
                  ]
                }
              </p>
            </li>
            <li
              className={
                url.status === "newsletter" || url.type === "newsletter"
                  ? "on"
                  : ""
              }
              onClick={() =>
                navigate(`/lms/${url.location}/dashboard/newsletter`)
              }
            >
              <img
                src={
                  url.status === "newsletter" || url.type === "newsletter"
                    ? DashboardNewsIcon3_1
                    : DashboardNewsIcon3
                }
                alt=" 나의 뉴스레터,뉴스레터 빌더 아이콘"
              />
              <p>
                {
                  lmsJson.newsletter.title[
                    localStorage.getItem("type") === "child"
                      ? "student"
                      : "parents"
                  ]
                }
              </p>
            </li>
            <li
              className={url.status === "likednews" ? "on" : ""}
              onClick={() =>
                navigate(`/lms/${url.location}/dashboard/likednews`)
              }
            >
              <img
                src={
                  url.status === "likednews"
                    ? DashboardNewsIcon4_1
                    : DashboardNewsIcon4
                }
                alt="나의 댓글 아이콘"
              />
              <p>좋아한 뉴스</p>
            </li>
            {localStorage.getItem("type") === "child" ? (
              <li
                className={url.status === "mycomment" ? "on" : ""}
                onClick={() =>
                  navigate(`/lms/${url.location}/dashboard/mycomment`)
                }
              >
                <img
                  src={
                    url.status === "mycomment"
                      ? DashboardNewsIcon5_1
                      : DashboardNewsIcon5
                  }
                  alt=" 나의 댓글 아이콘"
                />
                <p>나의 댓글</p>
              </li>
            ) : null}
          </ul>
        </li>
        {url.location === "parents" ? (
          <li
            className={url.type === "subscribe" ? "on" : ""}
            onClick={() => navigate(`/lms/${url.location}/subscribe/null`)}
          >
            <h3>
              <img src={Subscribe} alt="구독관리 아이콘" />
              구독 관리
            </h3>
          </li>
        ) : null}
      </ul>
      <div className="yearly_subscription">
        <img src={SubscribePlan} alt="연간 구독 아이콘" />
        <h5>구독</h5>
        <p>
          구독 기간이{" "}
          {state.user_info.family
            ? url.location === "parents"
              ? state.user_info.family.filter((i) => i.id === child_id).length >
                0
                ? state.user_info.family.filter((i) => i.id === child_id)[0]
                    .subscriptions.length > 0
                  ? moment(
                      state.user_info.family.filter((i) => i.id === child_id)[0]
                        .subscriptions[0].ended_at
                    ).diff(moment(), "days")
                  : 0
                : 0
              : state.user_info.subscriptions &&
                moment(state.user_info.subscriptions.ended_at).diff(
                  moment(),
                  "days"
                )
            : 0}
          일 남았어요
        </p>
      </div>
    </LmsNavDiv>
  );
};

export default LmsNav;

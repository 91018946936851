import { useRef, useState, useEffect } from "react";

import data from "./login.json";
import { userApi } from "Utils/api/api";
import { setFormData } from "Utils/lib/helper";

const LoginLogic = () => {
  const [loading, setLoading] = useState(false);
  const ref_instance = { email: useRef(null), pw: useRef(null) };
  const [state, setState] = useState({
    id: "",
    pw: "",
    alert: null,
  });
  useEffect(() => {
    // localStorage.clear();
    sessionStorage.clear();
  }, []);
  const login = async () => {
    if (!state.id) {
      alert(data.alert.none_id);
      return ref_instance.id.current.focus();
    }
    if (!state.pw) {
      alert(data.alert.none_pw);
      return ref_instance.pw.current.focus();
    }
    setLoading(true);
    const json = {
      login_id: state.id,
      password: state.pw,
    };
    try {
      const {
        data: { data },
      } = await userApi.login(json);
      localStorage.setItem("user_token", data.token);
      localStorage.setItem("login_id", data.user.login_id);
      localStorage.setItem("type", data.user.type);
      localStorage.setItem(
        "user_nick_name",
        data.user.nick_name ? data.user.nick_name : data.user.name
      );
      localStorage.setItem(
        "user_profile",
        data.user.profile_image_url ? data.user.profile_image_url : null
      );
      console.log(data);
      window.location.href = "/";
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  ////////////////  기능  ////////////////
  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return {
    state,
    data: {},
    ref: {},
    func: {
      setStateValue,
      login,
    },
  };
};

export default LoginLogic;

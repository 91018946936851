import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//styles
import { Top } from "./SubNavStyle";
import { userApi, checkApi } from "Utils/api/api";

//img
import Logo from "Assets/images/icons/logo.png";
import People from "Assets/images/icons/people.svg";
import LmsIcon from "Assets/images/icons/lms_icon.svg";
import Logout from "Assets/images/icons/logout_icon.svg";
import RightarrowSmall from "Assets/images/icons/rightarrow_small.svg";
import SearchIcon from "Assets/images/icons/search_icon.svg";
import Close from "Assets/images/icons/close2.svg";
import MbMenu from "Assets/images/icons/mobile_menu_icon.svg";
import SubscribeIcon from "Assets/images/icons/subscribe_icon.svg";
import Mbpeople from "Assets/images/icons/mb_menu_people.svg";

const SubNav = () => {
  const [state, setState] = useState({
    current: "",
    menu_open: false,
    mb_menu_open: false,
    search_open: false,
    modal: false,
    old_password: "",
    new_password: "",
    user_id: "",
    user_nike_name: "",
    user_roll: "",
    search_keyword: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [searchListOn, setSearchListOn] = useState(false);
  // 상세 조회
  const getDetail = async () => {
    try {
      const {
        data: { data },
      } = await userApi.getMyInfo();
      setState({
        ...state,
        current: location.pathname,
        user_id: localStorage.getItem("login_id"),
        user_nike_name: data.user.name,
        user_roll: localStorage.getItem("type"),
        user_profile: data.user.profile_image_url,
        menu_open: false,
        mb_menu_open: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  useEffect(() => {
    if (localStorage.getItem("user_token")) {
      getDetail();
    } else {
      setState({
        ...state,
        current: location.pathname,
        menu_open: false,
        mb_menu_open: false,
      });
    }
  }, [location.pathname]);
  useEffect(() => {
    if (state.modal) {
      setState({
        ...state,
        menu_open: false,
        mb_menu_open: false,
      });
    }
  }, [state.modal]);
  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };
  // target class 붙지 않는 객체 클릭시 searchbox 비활성화
  const handleClickOutside = ({ target }) => {
    if (!target.classList.contains("target")) {
      setSearchListOn(false);
    } else {
      setSearchListOn(true);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
  }, []);
  // 검색하기
  const searchKeyword = async () => {
    if (state.search_keyword === "" || state.search_keyword.length < 2) {
      alert("키워드는 두글자 이상으로 검색 바랍니다.");
      return;
    } else {
      navigate(`playground/search/${state.search_keyword}`);
      setSearchListOn(false);
    }
  };

  const logout = () => {
    if (window.confirm("로그아웃하시겠습니까?")) {
      localStorage.removeItem("user_token");
      localStorage.removeItem("login_id");
      localStorage.removeItem("user_nick_name");
      window.location.href = "/";
    }
  };


  return (
    <>
      <Top>
        <div>
          <ul>
            <li>
              <img
                src={Logo}
                alt=""
                onClick={() => {
                  navigate("/");
                }}
              />
            </li>
            <li onClick={() => navigate("/")}>서비스 소개</li>
            <li onClick={() => navigate("/playground/main/0")}>뉴스놀이터</li>
            <li onClick={() => navigate("/subscribe/info/null")}>구독</li>
            <li onClick={() => navigate("/question/news")}>자주 묻는 질문</li>
          </ul>
          <div>
            <span
              className="search target"
              onClick={() => setSearchListOn(!searchListOn)}
            >
              <img className=" target" src={SearchIcon} alt="검색아이콘" />
            </span>
            <span
              style={ state.user_profile
                      ? { backgroundImage: `url(${state.user_profile})` }
                      : { backgroundImage: `` }
               }
              className="people"
              onClick={
                state.user_id !== ""
                  ? () => setState({ ...state, menu_open: !state.menu_open })
                  : () => navigate("/login/student")
              }
            ></span>
            {/* 모바일 햄버거 메뉴 */}
            <span
              className="mb_menu"
              onClick={() => setStateValue("mb_menu_open", !state.mb_menu_open)}
            >
              <img src={MbMenu} alt="모바일 메뉴 아이콘" />
            </span>

            <div className="open_box">
              {/* profile open */}
              <div className={state.menu_open ? "" : "hidden"}>
                <ul>
                  {/* <li onClick={()=>setStateValue("modal", !state.modal)}>비밀번호 변경</li> */}
                  <li
                    onClick={() => (window.location.href = "/account/info/0")}
                  >
                    <img src={state.user_profile} alt="프로필 이미지" />
                    <div className="profile_box">
                      <h5>{state.user_nike_name}</h5>
                      <p>{state.user_id}</p>
                      <img src={RightarrowSmall} alt="오른쪽화살표" />
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      (window.location.href = `/lms/${
                        state.user_roll === "parents"
                          ? state.user_roll
                          : "student"
                      }/dashboard/participation`)
                    }
                  >
                    <img src={LmsIcon} alt="lms아이콘" />
                    LMS
                  </li>

                  {state.user_roll === "parents" ? (
                    <li onClick={() => navigate("/lms/parents/subscribe/null")}>
                      <img src={SubscribeIcon} alt="lms아이콘" />
                      구독관리
                    </li>
                  ) : null}

                  <li onClick={() => logout()}>
                    <img src={Logout} alt="로그아웃아이콘" />
                    로그아웃
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {state.mb_menu_open ? (
          <div className="mb_menu_open">
            <i
              className="xi-close"
              onClick={() => setStateValue("mb_menu_open", !state.mb_menu_open)}
            ></i>
            <ul>
              <li>
                <div
                  onClick={
                    state.user_id !== ""
                      ? () => (window.location.href = "/account/info/0")
                      : () => navigate("/login/student")
                  }
                >
                  <h6>
                    {state.user_id !== "" ? state.user_nike_name : "로그인"}
                  </h6>
                  <p>
                    {state.user_id !== "" ? state.user_id : "로그인을 해주세요"}
                  </p>
                </div>
                <img src={Mbpeople} alt="모바일 프로필이미지" />
              </li>
              <li onClick={() => navigate("/")}>서비스 소개</li>
              <li onClick={() => navigate("/playground/main/0")}>뉴스놀이터</li>
              <li onClick={() => navigate("/subscribe/info/null")}>구독</li>
              <li onClick={() => navigate("/question/news")}>자주 묻는 질문</li>
              {state.user_id !== "" 
                ? <li onClick={() => logout()}>로그아웃</li> 
                : ""}
            </ul>
          </div>
        ) : null}
        {/* search open */}
        <div className={searchListOn ? "search_open target" : "hidden target"}>
          <div>
            <input
              type="text"
              className="target"
              placeholder="뉴스를 검색해보세요"
              onChange={({ target: { value } }) =>
                setStateValue("search_keyword", value)
              }
              onKeyPress={(e) => {
                if (e.key !== "Enter") return;
                if (e.shiftKey) return;
                e.preventDefault();
                searchKeyword();
              }}
              value={state.search_keyword}
            />
            <i className="xi-search target" onClick={() => searchKeyword()}></i>
            <img
              className="target"
              src={Close}
              alt="닫기 아이콘"
              onClick={() => setState({ ...state, search_keyword: "" })}
            />
          </div>
        </div>
      </Top>
    </>
  );
};

export default SubNav;

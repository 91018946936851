import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";

// api
import {
  lmsCommonApi,
  lmsChildApi,
  userApi,
  lmsParentsApi,
  paymentApi,
} from "Utils/api/api";

//lib
import { scrollTop, phoneNumToString } from "Utils/lib/helper";

// json
import jsonData from "Assets/contents/contents.json";

// img

const LmsLogic = ({ loading, setLoading, url }) => {
  // 주차 구하기
  const getWeek = (date) => {
    const currentTime = moment(date);
    const currentDay = moment(date).get("day"); // 요일 구하기 (0: 일요일 , 6: 토요일 )

    const toThursday = [3, 3, 2, 1, 0, 1, 2];
    let thursday;

    // 목요일을 포함한 주가 기준이 된다.
    // 1. 월~목요일인 경우
    if (currentDay >= 1 && currentDay < 5) {
      const addDate = toThursday[currentDay];
      thursday = currentTime.add(addDate, "day").format("YYYY-MM-DD");
    }

    // 2. 금~일요일인 경우
    if (currentDay == 0 || currentDay > 4) {
      const subDate = toThursday[currentDay];
      thursday = currentTime.subtract(subDate, "day").format("YYYY-MM-DD");
    }

    const thisYear = moment(thursday).get("year");
    const thisMonth = moment(thursday).get("month") + 1;
    const thisDate = moment(thursday).get("date");

    const setThisMonth = moment(thursday).date(1);
    const thisFirstDay = moment(setThisMonth).day();

    const thisWeek = Math.ceil((thisDate + thisFirstDay) / 7);

    let week = thisFirstDay > 4 ? thisWeek - 1 : thisWeek;

    return { year: thisYear, month: thisMonth, week: week };
  };
  // 기본 state 관리
  const [state, setState] = useState({
    loading: true,
    user_info: [],
    user_payment_info: [],
    child_id: null,
    search_keyword: "",
    year: null,
    month: null,
    week: null,
    modal: { open: false, type: "", id: "" },
    alert: null,
    is_hover: false,
    is_setting: false,
    menu_open: "",
  });
  const setMenuOnOff = (idx) => {
    if (state.menu_open === idx) {
      setState({ ...state, menu_open: null });
    } else {
      setState({ ...state, menu_open: idx });
    }
  };
  // data state 관리
  const [dataState, setDataState] = useState({
    data_loading: true,
    study_news: [], // 뉴스놀이터 참여현황
    study_news_total: 0,
    my_news: [], // 뉴스목록
    my_news_total: 0,
    news_letter: [], // 뉴스레터
    news_letter_total: 0,
    news_letter_writer: "",
    liked_news: [], // 좋아한 뉴스
    liked_news_total: 0,
    comment_news: [], // 댓글
    comment_news_total: 0,
    year: null,
    month: null,
    week: null,
  });
  // 데이터 저장 state 관리
  const [inputData, setInputData] = useState({
    child: [],
    card_name: "",
    card_num01: "",
    card_num02: "",
    card_num03: "",
    card_num04: "",
    card_birth: "",
    card_expiry_month: "",
    card_expiry_year: "",
    card_pwd_2digit: "",
  });
  // filter 관리
  const [filterList, setFilterList] = useState({
    year_list: [...Array(new Date().getFullYear() - 2023 + 1)].map((i, idx) => {
      return {
        value: 2023 + idx,
        label: `${2023 + idx} 년`,
      };
    }),
    month_list: [...Array(12)].map((i, idx) => {
      return {
        value: idx + 1,
        label: `${idx + 1} 월`,
      };
    }),
    week_list: [...Array(5)].map((i, idx) => {
      return {
        value: idx + 1,
        label: `${idx + 1} 주차`,
      };
    }),
    card_list: [],
    card_month_list: [...Array(12)].map((i, idx) => {
      return {
        value: idx > 9 ? idx + 1 : `0${idx + 1}`,
        label: `${idx + 1} 월`,
      };
    }),
    card_year_list: [...Array(30)].map((i, idx) => {
      return {
        value: parseInt(new Date().getFullYear()) + idx,
        label: `${parseInt(new Date().getFullYear()) + idx} 년`,
      };
    }),
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (state.search_keyword === "") {
      // getList();
      scrollTop();
    }
  }, [state.search_keyword, currentPage]);

  useEffect(() => {
    getUserInfo();
    // getList(url.status);
  }, []);
  useEffect(() => {
    if (url.type === "subscribe") {
      getUserPayment();
    } else {
    }
  }, [url.type, state.child_id]);
  useEffect(() => {
    getList(url.status);
  }, [
    state.user_info,
    state.modal.open,
    currentPage,
    url.type,
    url.status,
    state.year,
    state.month,
    state.week,
  ]);

  ////////////////  API  ////////////////
  // 유저 정보 조회
  const getUserInfo = async () => {
    try {
      const {
        data: { data },
      } = await userApi.getMyInfo({ id: null });
      setState({
        ...state,
        user_info: data,
        loading: false,
        child_id:
          data.family && data.family.length > 0 ? data.family[0].id : null,
        year: getWeek().year,
        month: getWeek().month,
        week: getWeek().week,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 구독관리 조회
  const getUserPayment = async () => {
    try {
      const { data } = await lmsParentsApi.getSubscription({
        id: state.child_id,
      });
      setState({
        ...state,
        user_payment_info: data.data,
        loading: false,
        modal: { open: false, type: "", id: "" },
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };

  // 목록 조회
  const getList = async (type) => {
    if (
      url.location === "parents" &&
      state.user_info &&
      state.user_info.family.length == 0
    ) {
      setDataState({
        ...dataState,
        data_loading: false,
      });
    } else {
      if (type === "participation") {
        try {
          const {
            data: { data },
          } = await lmsCommonApi.getNewsStudyList({
            id: state.child_id,
            year: state.year,
            month: state.month,
            week: state.week,
            page: currentPage - 1,
          });

          setDataState({
            ...dataState,
            data_loading: false,
            study_news: data.news,
            study_news_total: data.total,
          });
        } catch (error) {
          console.log(error);
          // alert(res.data.message);
          setStateValue("alert", {
            title: error.response.data.message,
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
        } finally {
          setLoading(false);
        }
      } else if (type === "news") {
        try {
          const {
            data: { data },
          } = await lmsCommonApi.getNewsList({
            id: state.child_id,
            year: state.year,
            month: state.month,
            week: state.week,
            page: currentPage - 1,
          });
          console.log(data);

          setDataState({
            ...dataState,
            data_loading: false,
            my_news: data.news,
            my_news_total: data.total,
          });
        } catch (error) {
          console.log(error);
          // alert(res.data.message);
          setStateValue("alert", {
            title: error.response.data.message,
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
        } finally {
          setLoading(false);
        }
      } else if (type === "newsletter") {
        try {
          const {
            data: { data },
          } = await lmsCommonApi.getNewsLetterList({
            id: state.child_id,
            year: state.year,
            page: currentPage - 1,
          });
          setState({
            ...state,
            year: parseInt(data.year),
          });
          setDataState({
            ...dataState,
            data_loading: false,
            news_letter: data.letters,
            news_letter_total: data.total,
            news_letter_writer: data.users.nick_name,
          });
        } catch (error) {
          console.log(error);
          // alert(res.data.message);
          setStateValue("alert", {
            title: error.response.data.message,
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
        } finally {
          setLoading(false);
        }
      } else if (type === "likednews") {
        try {
          const {
            data: { data },
          } = await lmsCommonApi.getLikedNewsList({ id: state.child_id });
          console.log(data);
          setDataState({
            ...dataState,
            data_loading: false,
            liked_news: data.likes,
            liked_news_total: data.total,
          });
        } catch (error) {
          console.log(error);
          // alert(res.data.message);
          setStateValue("alert", {
            title: error.response.data.message,
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
        } finally {
          setLoading(false);
        }
      } else if (type === "mycomment") {
        try {
          const {
            data: { data },
          } = await lmsChildApi.getCommentList({ page: currentPage - 1 });
          setDataState({
            ...dataState,
            data_loading: false,
            comment_news: data.comments,
            comment_news_total: data.total,
          });
        } catch (error) {
          console.log(error);
          // alert(res.data.message);
          setStateValue("alert", {
            title: error.response.data.message,
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
        } finally {
          setLoading(false);
        }
      }
    }
  };
  // 뉴스 삭제
  const deleteNews = async (type, id) => {
    if (type === "news") {
      try {
        const { data } = await lmsChildApi.deleteNews({
          id: id,
        });
        setModalData(!state.modal.open, null, null);
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const { data } = await lmsChildApi.deleteLetter({
          id: id,
        });
        setModalData(!state.modal.open, null, null);
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // 결제 수단 등록
  const setPaymentCard = async () => {
    try {
      if (
        inputData.card_num01 === "" ||
        inputData.card_num02 === "" ||
        inputData.card_num03 === "" ||
        inputData.card_num04 === ""
      ) {
        setStateValue("alert", {
          title: "카드번호를 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (
        inputData.card_expiry_year === "" ||
        inputData.card_expiry_month === ""
      ) {
        setStateValue("alert", {
          title: "카드번호를 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.birth === "") {
        setStateValue("alert", {
          title: "생년월일을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.pwd_2digit === "") {
        setStateValue("alert", {
          title: "비밀번호 앞 2자리를 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      const json = {
        card_number: `${inputData.card_num01}-${inputData.card_num02}-${inputData.card_num03}-${inputData.card_num04}`,
        expiry: `${inputData.card_expiry_year}-${inputData.card_expiry_month}`,
        birth: inputData.card_birth,
        pwd_2digit: inputData.card_pwd_2digit,
      };
      const { data } = await paymentApi.addCardInfo(json);
      getUserPayment();
    } catch (error) {
      console.log(error);
      setStateValue("alert", {
        title: "카드 등록이 실패하였습니다.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 구독해지
  const cancelSubscription = async () => {
    try {
      const { data } = await lmsParentsApi.deleteSubscription({
        id: state.child_id,
      });
      getUserPayment();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // keyword로 검색 결과 셋팅
  const searchList = () => {
    console.log(state.search_keyword);
    if (state.search_keyword === "" || state.search_keyword.length < 2) {
      alert("키워드는 두글자 이상으로 검색 바랍니다.");
      return;
    } else {
      getList();
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };

  // state input 셋팅
  const setInputStateValue = (key, value, url) => {
    setInputData({ ...inputData, [key]: value });
  };

  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };
  return {
    state,
    data: { dataState, currentPage, filterList },
    func: {
      setStateValue,
      setModalData,
      paging,
      searchList,
      deleteNews,
      setMenuOnOff,
      setPaymentCard,
      cancelSubscription,
      setInputStateValue,
    },
  };
};

export default LmsLogic;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import { Modal, Alert } from "Components";
import { setImageFromFile, checkFileSize } from "Utils/lib/helper";
import { getSubscribeStatusKr } from "Utils/lib/status";

// select
import { selectStyles, DropdownIndicator } from "Styles/select.style";
import Select, { components } from "react-select";

// img
import People from "Assets/images/icons/people2.svg";
import Trash from "Assets/images/icons/trash.svg";
import PictureSelect from "Assets/images/icons/account_picture_select.svg";

// styles
import { SettingContainer, BasicButton, Radiostyle } from "Styles/styles";

// logics
import AccountLogic from "./Account.logic";
import moment from "moment";

const Account = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: "",
  });
  const { state, data, func } = AccountLogic({
    loading,
    setLoading,
    navigate,
    url: urlState,
  });
  useEffect(() => {
    setUrlState({
      ...state,
      current: location.pathname,
      type: location.pathname.split("/account/")[1].split("/")[0],
      id: location.pathname.split("/account/")[1].split("/")[1],
    });
  }, [location.pathname]);
  return (
    <>
      <SettingContainer>
        {!state.loading ? (
          urlState.type === "info" ? (
            <>
              <div>
                <h4>설정</h4>
                {state.detail_info.user.type === "child" ? (
                  <div>
                    <h6>프로필 사진</h6>
                    <ul className="profile_picture">
                      {/* 프로필 이미지가 있는지 여부? */}
                      {data.inputData.img_src && data.inputData.img_url ? (
                        <li>
                          <img
                            src={data.inputData.img_src}
                            alt="사진 선택"
                            className="picture_icon"
                          />
                          <span
                            onClick={() =>
                              func.setInputData({
                                ...data.inputData,
                                img_url: null,
                                img_src: null,
                              })
                            }
                          >
                            <img src={PictureSelect} alt="사진변경 아이콘" />
                            사진 변경
                          </span>
                        </li>
                      ) : (
                        <li>
                          <input
                            type="file"
                            accept="image/jpeg, image/png"
                            className="hidden"
                            id="image_add"
                            onChange={({ target: { files } }) => {
                              if (files.length) {
                                if (checkFileSize(10, files[0].size)) {
                                  setImageFromFile({
                                    file: files[0],
                                    setImageUrl: ({ result }) =>
                                      func.setInputData({
                                        ...data.inputData,
                                        img_url: files[0],
                                        img_src: result,
                                      }),
                                  });
                                } else {
                                  alert(
                                    "10MB 이하의 파일만 업로드 가능합니다."
                                  );
                                  return;
                                }
                              }
                            }}
                          />
                          <label htmlFor="image_add">
                            <img
                              src={People}
                              alt="사진 선택"
                              className="picture_icon"
                            />
                            <p>사진업로드</p>
                          </label>
                        </li>
                      )}
                    </ul>
                  </div>
                ) : null}
                <div>
                  <div>
                    <h6>이름</h6>
                  </div>
                  <input
                    type="text"
                    defaultValue={state.detail_info.user.name}
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("name", value)
                    }
                  />
                </div>
                {state.detail_info.user.type === "child" ? (
                  <div>
                    <div>
                      <h6>닉네임</h6>
                    </div>
                    <input
                      type="text"
                      defaultValue={data.inputData.nick_name}
                      onChange={({ target: { value } }) =>
                        func.setInputStateValue("nick_name", value)
                      }
                    />
                  </div>
                ) : null}
                <div>
                  <div>
                    <h6>
                      {state.detail_info.user.type === "child"
                        ? "아이디"
                        : "이메일"}
                    </h6>
                  </div>
                  <input
                    type="text"
                    className="grey_input"
                    defaultValue={data.inputData.login_id}
                    readOnly={true}
                  />
                </div>
                <div>
                  <div>
                    <h6>비밀번호</h6>
                  </div>
                  <button
                    className="change_button"
                    onClick={() =>
                      (window.location.href = `/find/pwchange/${
                        state.detail_info.user.type === "child"
                          ? "student"
                          : "parents"
                      }`)
                    }
                  >
                    변경
                  </button>
                </div>
                <div>
                  <div>
                    <h6>
                      {state.detail_info.user.type === "child" ? "보호자" : ""}{" "}
                      휴대폰 번호
                    </h6>
                  </div>
                  <input
                    type="text"
                    className="grey_input"
                    readOnly={true}
                    value={state.detail_info.user.phone_number}
                  />
                  <button
                    className="change_button mt10"
                    onClick={() =>
                      func.setModalData(true, "phone_number_change")
                    }
                  >
                    변경
                  </button>
                </div>
                <div className="mb40">
                  <h6>주소</h6>
                  <Select
                    placeholder="국가"
                    options={[{ value: 1, label: "대한민국" }]}
                    value={{ value: 1, label: "대한민국" }}
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={124}
                    height={46}
                  />
                  <Select
                    placeholder="지역"
                    options={state.city_list}
                    components={{ DropdownIndicator }}
                    defaultValue={
                      state.city_list.filter(
                        (c) => c.value === data.inputData.city_id
                      )[0]
                    }
                    styles={selectStyles}
                    width={124}
                    height={48}
                    onChange={(value) =>
                      func.setInputStateValue("city_id", value.value)
                    }
                  />
                </div>
                <div>
                  <div>
                    <h6>생년월일</h6>
                  </div>
                  <input
                    type="text"
                    defaultValue={data.inputData.birth}
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("birth", value)
                    }
                  />
                </div>
                {state.detail_info.user.type === "child" ? (
                  <div>
                    <h6>학년</h6>
                    <Radiostyle>
                      <div className="mb14">
                        <input
                          type="radio"
                          id="firstyear"
                          name="age"
                          defaultChecked={
                            data.inputData.grade === "1" ? true : false
                          }
                          onClick={() => func.setInputStateValue("grade", "1")}
                        />
                        <label htmlFor="firstyear">1학년</label>
                        <input
                          type="radio"
                          id="secondyear"
                          name="age"
                          defaultChecked={
                            data.inputData.grade === "2" ? true : false
                          }
                          onClick={() => func.setInputStateValue("grade", "2")}
                        />
                        <label htmlFor="secondyear">2학년</label>
                        <input
                          type="radio"
                          id="thirdyear"
                          name="age"
                          defaultChecked={
                            data.inputData.grade === "3" ? true : false
                          }
                          onClick={() => func.setInputStateValue("grade", "3")}
                        />
                        <label htmlFor="thirdyear">3학년</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="fourthyear"
                          name="age"
                          defaultChecked={
                            data.inputData.grade === "4" ? true : false
                          }
                          onClick={() => func.setInputStateValue("grade", "4")}
                        />
                        <label htmlFor="fourthyear">4학년</label>
                        <input
                          type="radio"
                          id="fifthyear"
                          name="age"
                          defaultChecked={
                            data.inputData.grade === "5" ? true : false
                          }
                          onClick={() => func.setInputStateValue("grade", "5")}
                        />
                        <label htmlFor="fifthyear">5학년</label>
                        <input
                          type="radio"
                          id="sixthyear"
                          name="age"
                          defaultChecked={
                            data.inputData.grade === "6" ? true : false
                          }
                          onClick={() => func.setInputStateValue("grade", "6")}
                        />
                        <label htmlFor="sixthyear">6학년</label>
                      </div>
                    </Radiostyle>
                  </div>
                ) : null}
                <div>
                  <h6>성별</h6>
                  <Radiostyle>
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      defaultChecked={
                        data.inputData.gender === "male" ? true : false
                      }
                      onClick={() => func.setInputStateValue("gender", "male")}
                    />
                    <label htmlFor="male">남자</label>
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      defaultChecked={
                        data.inputData.gender === "female" ? true : false
                      }
                      onClick={() =>
                        func.setInputStateValue("gender", "female")
                      }
                    />
                    <label htmlFor="female">여자</label>
                  </Radiostyle>
                </div>
                <div className="mb200">
                  <h6>나와 연결된 회원</h6>
                  <div className="connect_user">
                    {state.detail_info.family &&
                    state.detail_info.family.length > 0 ? (
                      state.detail_info.family.map((item) => (
                        <span
                          className={`${
                            state.detail_info.user.type === "child"
                              ? "parents"
                              : "children" // className 이 children일시 커서 기본으로
                          }`}
                          onClick={() =>
                            navigate(`/account/children/${item.id}`)
                          }
                        >
                          <h6>{item.name}</h6>
                          <p>{item.email}</p>
                        </span>
                      ))
                    ) : (
                      <span
                        className={`${
                          state.detail_info.user.type === "child"
                            ? "parents"
                            : "children" // className 이 children일시 커서 기본으로
                        }`}
                      >
                        <h6>
                          연결된{" "}
                          {state.detail_info.user.type === "child"
                            ? "보호자"
                            : "자녀"}
                          없음
                        </h6>
                        <p>
                          {state.detail_info.user.type === "child"
                            ? "보호자"
                            : "자녀"}
                          가 위그에 가입하면 자동으로 연결돼요
                        </p>
                      </span>
                    )}
                  </div>
                  <button
                    className="save_button mt40"
                    onClick={() => func.changeInfo()}
                  >
                    저장
                  </button>
                  <div className="bye_user">
                    <span onClick={() => func.setModalData(true, "bye_user")}>
                      탈퇴하기
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : //  등록된 자녀 정보
          !state.child_loading ? (
            <div>
              <h4>등록된 자녀</h4>
              <div>
                <div>
                  <h6>이름</h6>
                </div>
                <input
                  type="text"
                  className="grey_input"
                  readOnly={true}
                  value={state.detail_child_info.family.name}
                />
              </div>
              <div>
                <div>
                  <h6>닉네임</h6>
                </div>
                <input
                  type="text"
                  className="grey_input"
                  readOnly={true}
                  value={state.detail_child_info.family.nick_name}
                />
              </div>
              <div>
                <div>
                  <h6>아이디</h6>
                </div>
                <input
                  type="text"
                  className="grey_input"
                  readOnly={true}
                  value={state.detail_child_info.family.login_id}
                />
              </div>
              <div className="mb40">
                <h6>주소</h6>
                <Select
                  placeholder="국가"
                  options={[{ value: 1, label: "대한민국" }]}
                  value={{ value: 1, label: "대한민국" }}
                  components={{ DropdownIndicator }}
                  styles={selectStyles}
                  width={124}
                  height={46}
                />
                <Select
                  placeholder="지역"
                  options={state.city_list}
                  components={{ DropdownIndicator }}
                  defaultValue={
                    state.city_list.filter(
                      (c) => c.value === data.inputData.child_city_id
                    )[0]
                  }
                  styles={selectStyles}
                  width={124}
                  height={48}
                  onChange={(value) =>
                    func.setInputStateValue("child_city_id", value.value)
                  }
                />
              </div>
              <div>
                <div>
                  <h6>생년월일</h6>
                </div>
                <input
                  type="text"
                  defaultValue={data.inputData.child_birth}
                  onChange={({ target: { value } }) =>
                    func.setInputStateValue("child_birth", value)
                  }
                />
              </div>
              <div>
                <h6>성별</h6>
                <Radiostyle>
                  <input
                    type="radio"
                    id="male"
                    name="child_gender"
                    defaultChecked={
                      data.inputData.child_gender === "male" ? true : false
                    }
                    onClick={() =>
                      func.setInputStateValue("child_gender", "male")
                    }
                  />
                  <label htmlFor="male">남자</label>
                  <input
                    type="radio"
                    id="female"
                    name="child_gender"
                    defaultChecked={
                      data.inputData.child_gender === "female" ? true : false
                    }
                    onClick={() =>
                      func.setInputStateValue("child_gender", "female")
                    }
                  />
                  <label htmlFor="female">여자</label>
                </Radiostyle>
              </div>
              <div className="mb200">
                {state.detail_child_info.subscriptions ? (
                  <>
                    <h6>구독</h6>
                    <div className="subscription_info">
                      <h5>
                        {
                          state.detail_child_info.subscriptions.products
                            .product_name
                        }
                      </h5>
                      <p>
                        {moment(
                          state.detail_child_info.subscriptions.ended_at
                        ).format("YYYY.MM.DD.")}{" "}
                        {getSubscribeStatusKr(
                          state.detail_child_info.subscriptions.status
                        )}
                      </p>
                    </div>
                  </>
                ) : null}

                <div>
                  <span></span>
                  <button
                    className="save_button mb200"
                    onClick={() => func.changeChildInfo()}
                  >
                    저장
                  </button>
                </div>
              </div>
            </div>
          ) : null
        ) : null}
      </SettingContainer>
      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
      {/* --------- MODAL --------- */}
      {/* 탈퇴하기 모달*/}
      {state.modal.open && state.modal.type === "bye_user" ? (
        <Modal
          minWidth={339}
          maxHeight={287}
          borderRadius={12}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="bye_user">
                <h3>위그를 탈퇴하시겠어요?</h3>
                <h4>
                  탈퇴 시 모든 활동 내역과 기록이 삭제되며 삭제된 기록은 복구할
                  수 없어요.
                </h4>
                <div className="btns">
                  <BasicButton
                    className="grey2"
                    onClick={() =>
                      func.setModalData(!state.modal.open, null, null)
                    }
                  >
                    취소하기
                  </BasicButton>
                  <BasicButton
                    className="main"
                    onClick={() => func.withdrawal()}
                  >
                    탈퇴하기
                  </BasicButton>
                </div>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}
      {/* 링크복사 모달*/}
      {state.modal.open && state.modal.type === "phone_number_change" ? (
        <Modal
          minWidth={339}
          minHeight={229}
          maxHeight={287}
          borderRadius={12}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="phone_number_change">
                <h3>휴대폰 번호 변경</h3>
                <div>
                  <h6>보호자 휴대폰 번호</h6>
                  <input
                    type="text"
                    className="short_input"
                    onChange={({ target: { value } }) =>
                      func.setInputStateValue("phone_number", value)
                    }
                  />
                  {state.phone_send ? (
                    <>
                      <input
                        type="text"
                        className="short_input certification_input"
                        placeholder="인증번호를 입력해주세요."
                        onChange={({ target: { value } }) =>
                          func.setInputStateValue("code", value)
                        }
                      />
                      <div className="btns">
                        <button onClick={() => func.checkPhoneOk("send")}>
                          재전송
                        </button>
                        <button onClick={() => func.checkPhoneOk("check")}>
                          확인
                        </button>
                      </div>
                    </>
                  ) : (
                    <button
                      className="certification_button"
                      onClick={() => func.checkPhoneOk("send")}
                    >
                      인증번호 받기
                    </button>
                  )}
                </div>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}
    </>
  );
};

export default Account;

import styled from "styled-components";

import leftIcon from "Assets/images/icons/left.svg";
import rightIcon from "Assets/images/icons/right.svg";

export default styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  > button {
    margin: 0 15px;
    padding: 0 0 1px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    background: #fff;
    &::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      background-image: url(${leftIcon});
      background-size: 100% 100%;
    }
    &:last-child::after {
      background-image: url(${rightIcon});
    }
  }
  ol {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 5px;
      padding: 3px 5px;
      font-size: 14px;
      cursor: pointer;
      color: var(--grey3);
      &.active {
        color: var(--text);
        font-weight: 700;
        /* text-decoration: underline; */
      }
    }
  }
`;

import styled from "styled-components";

export const LmsNavDiv = styled.div`
  width: 320px;
  padding: 32px 24px;
  padding-top: 82px;
  border-right: 1px solid var(--grey3);
  min-height: 100vh;
  display: inline-block;
  position: relative;
  background-color: var(--white);
  > div {
    margin-bottom: 40px;
    h3 {
      font-size: 20px;
      font-weight: 700;
      color: var(--text);
      line-height: 140%;
      width: 70%;
      display: inline-block;
      margin-bottom: 24px;
    }
    > span {
      display: inline-block;
      float: right;
      width: 68px;
      height: 32px;
      margin-top: 15px;
      background: #f2f4f6;
      border-radius: 100px;
      padding: 10px 10px;
      font-weight: 500;
      font-size: 14px;
      color: #646f7c;
      cursor: pointer;
      vertical-align: middle;
      img {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
  .menu {
    display: block;
    position: relative;
    > li {
      font-size: 16px;
      line-height: 140%;
      color: #28323c;
      vertical-align: middle;
      padding: 14px 10px;
      cursor: pointer;
      border-radius: 6px;
      margin-bottom: 10px;
      height: 54px;
      > &:hover {
        background-color: var(--grey2);
        transition-duration: 0.4s;
      }
      &.on {
        background-color: var(--grey2);
        font-weight: 500;
        margin-bottom: 200px;
      }
      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        vertical-align: sub;
        display: inline-block;
        margin-bottom: -3px;
      }
      h3 {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
      }

      // sub메뉴
      ul {
        margin-top: 18px;
        display: none;
        li {
          height: 48px;
          padding: 14px 10px;
          margin-bottom: 0;
          font-weight: 400;
          font-size: 14px;
          &.on {
            font-weight: 700;
            color: #28323c;
            p {
              font-weight: 700;
              color: #28323c;
            }
          }
          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
            line-height: 140%;
            color: #646f7c;
            font-size: 14px;
          }
        }
        &.on {
          display: block;
        }
      }
    }
  }
  .yearly_subscription {
    position: absolute;
    bottom: 0px;
    background: var(--grey1);
    border-radius: 8px;
    width: 272px;
    padding: 20px;
    img {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: top;
      margin-right: 4px;
    }
    h5 {
      display: inline-block;
      vertical-align: middle;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      color: var(--text);
      margin-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--text);
    }
  }
`;

import React from "react";
import styled from "styled-components";
import { BasicButton } from "Styles/styles";
import { View, Main } from "./Alert.style";

const Modal = ({ title, sub, isOk, isCancel, borderRadius, width }) => {
  return (
    <View>
      <Main borderRadius={borderRadius} modalWidth={width}>
        <h4>{title}</h4>
        <h5>{sub}</h5>
        <div className="btns">
          {isCancel ? (
            <BasicButton className="grey2" onClick={() => isCancel()}>
              취소
            </BasicButton>
          ) : null}
          {isOk ? (
            <BasicButton className="main" onClick={() => isOk()}>
              확인
            </BasicButton>
          ) : null}
        </div>
      </Main>
    </View>
  );
};

export default Modal;

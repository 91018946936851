import styled from "styled-components";

export default styled.div`
  width: 100%;
  /* height: 100vh; */
  /* min-height: 800px; */
  min-height: calc(100vh - 38px);

  position: relative;
  background-color: var(--white);
  letter-spacing: -0.04em;

  // 회원가입 info
  .form {
    position: relative;
    margin: 0 auto;
    width: 308px;
    text-align: left;
    border-radius: 10px;
    box-sizing: border-box;
    line-height: 140%;
    margin-top: 46px;
    margin-bottom: 420px;
    h3 {
      font-size: 18px;
      margin-bottom: 24px;
      letter-spacing: -0.04em;
      line-height: 140%;
      font-weight: 500;
    }
    > div {
      padding: 20px;
      width: 308px;
      height: 65px;
      background: #ffffff;
      border: 1px solid #6c38ff;
      border-radius: 10px;
      box-sizing: border-box;
      line-height: 140%;
      cursor: pointer;
      margin-bottom: 14px;
      color: var(--main);
      font-weight: 600;
      letter-spacing: -0.04em;
      &:hover {
        background-color: var(--grey1);
      }
      &:last-child {
        margin-bottom: 0;
      }
      img {
        width: 16px;
        height: 16px;
        float: right;
        display: inline-block;
        vertical-align: middle;
        margin-top: 3px;
      }
    }
  }

  // 회원가입 img ul
  .signup_logobox {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 36px;
    position: relative;
    margin: 0 auto;
    padding-top: 160px;
    width: 512px;
    box-sizing: border-box;
    img {
      width: 119px;
      height: 35px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
      margin-bottom: 40px;
    }
    h4 {
      color: var(--text);
      text-align: center;
      display: block;
      font-weight: 700;
      font-size: 26px;
      line-height: 140%;
      margin-bottom: 50px;
    }
    ul {
      display: block;
      text-align: center;
      margin-bottom: 46px;
      li {
        font-size: 20px;
        display: inline-block;
        cursor: pointer;
        font-weight: 700;
        line-height: 140%;
        text-align: center;
        vertical-align: middle;
        color: var(--grey5);
        &.signup_done {
          color: var(--text);
        }
        &.on {
          color: var(--text);
        }
        &.signup_finish_text {
          font-size: 26px;
          font-weight: 700;
        }
        img {
          width: 14px;
          height: 14px;
          display: inline-block;
          vertical-align: middle;
          margin: 0 8px;
          margin-bottom: 6px;
        }
      }
    }
    @media screen and (max-width: 809px) {
      width: 90%;
      ul {
        li {
          font-size: 13px;
        }
      }
    }
  }

  // 회원가입 step
  .signup_step {
    width: 512px;
    margin: 0 auto;
    @media screen and (max-width: 809px) {
      width: 90%;
    }
    h6 {
      color: var(--grey6);
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 6px;
      letter-spacing: -0.04em;
      font-weight: 400;
    }
    button {
      width: 100%;
      margin-bottom: 162px;
      background-color: #a788ff;
      &:hover {
        background-color: var(--main);
      }
    }
    .step1_agree {
      margin-bottom: 30px;
      &:nth-child(2) {
        margin-bottom: 40px;
      }
      .agree_all_text {
        color: var(--text);
        font-weight: 500;
      }
      > div {
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.04em;
        border: 1px solid var(--grey3);
        color: var(--grey6);
        border-radius: 8px;
        padding: 14px 16px 0px;
        font-size: 14px;
        box-sizing: border-box;
        margin-bottom: 16px;
        max-height: 100px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c5c8ce;
          border-radius: 20px;
          background-clip: padding-box;
          border: 2px solid transparent;
        }
        &::-webkit-scrollbar-track {
          background-color: var(--white);
          border-radius: 0 20px 20px 0;
        }
        span {
          margin-bottom: 20px;
          display: block;
        }
      }
      span {
        display: block;
        margin-bottom: 16px;
      }
      // 체크박스
      input[type="checkbox"] {
        display: inline-block;
        opacity: 1;
        margin-right: 8px;
        accent-color: var(--white);
        border: 1px solid #777777;
        position: static;
        vertical-align: middle;
        appearance: none;
        background: #fff;
        cursor: pointer;
        height: 16px;
        outline: 0;
        width: 16px;
        border-radius: 4px;
      }
      input[type="checkbox" i] {
        width: 20px;
        height: 20px;
      }
      input[type="checkbox"]:checked {
        background-color: var(--main);
        color: var(--white);
      }
      input[type="checkbox"]::after {
        border: solid var(--white);
        border-width: 0 2px 2px 0;
        content: "";
        display: none;
        height: 52%;
        left: 35%;
        position: relative;
        top: 11%;
        transform: rotate(45deg);
        width: 22%;
      }
      input[type="checkbox"]:checked::after {
        display: block;
      }
      label {
        vertical-align: middle;
        font-size: 14px;
        cursor: pointer;
        line-height: 140%;
        color: #555;
        font-weight: 400;
      }
    }
    // step2
    .step2_certification {
      position: relative;
      margin: 0 auto;
      width: 457px;
      text-align: left;
      border-radius: 10px;
      box-sizing: border-box;
      line-height: 140%;
      margin-top: 46px;
      margin-bottom: 400px;
      > div {
        width: 308px;
        margin: 0 auto;
        h6 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #374553;
        }
        input {
          width: 308px;
          height: 48px;
          border: 1px solid #e9ebee;
          border-radius: 8px;
          padding: 14px 16px;
          margin-bottom: 10px;
          font-size: 14px;
          &::placeholder {
            color: var(--grey5);
          }
        }
        .certification_button {
          width: 308px;
          height: 42px;
          background: #ffffff;
          border: 1px solid #6c38ff;
          border-radius: 6px;
          display: block;
          margin-bottom: 30px;
          color: var(--main);
        }
        .btns {
          button {
            margin-bottom: 20px;
            &:nth-child(1) {
              width: 112px;
              height: 42px;
              border: 1px solid var(--main);
              color: var(--main);
              border-radius: 6px;
              background-color: var(--white);
            }
            &:nth-child(2) {
              width: 186px;
              height: 42px;
              border: 1px solid var(--main);
              border-radius: 6px;
              background-color: var(--main);
              color: var(--white);
            }
          }
        }
      }
      span {
        background: var(--grey1);
        border-radius: 6px;
        display: block;
        padding: 18px 14px;
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          display: inline-block;
          margin-bottom: 3px;
        }
        h6 {
          margin-bottom: 10px;
          font-weight: 700;
          letter-spacing: -0.07em;
          color: var(--grey6);
          display: inline-block;
          margin: 0 5px;
        }
        p {
          font-size: 14px;
          line-height: 170%;
          letter-spacing: -0.08em;
          color: var(--grey6);
          display: inline-block;
        }
      }
      @media screen and (max-width: 809px) {
        width: 308px;
      }
    }

    // step3
    .step3_certification {
      position: relative;
      margin: 0 auto;
      width: 308px;
      text-align: left;
      border-radius: 10px;
      box-sizing: border-box;
      line-height: 140%;
      margin-top: 46px;
      margin-bottom: 128px;
      > .parents_connect {
        padding: 20px;
        width: 308px;
        height: 56px;
        background: #f0ebff;
        border-radius: 10px;
        box-sizing: border-box;
        line-height: 140%;
        cursor: pointer;
        margin-bottom: 24px;
        font-weight: 500;
        letter-spacing: -0.04em;
        color: var(--text);
        vertical-align: middle;
        margin-bottom: 30px;
        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 5px;
        }
        > span {
          border-radius: 6px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
          color: var(--main);
          margin-bottom: 5px;
          margin-right: 3px;
          font-weight: 700;
        }
      }
      .step3_inputbpx {
        margin-bottom: 30px;
        h6 {
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: var(--grey6);
          display: inline-block;
        }
        .requirement {
          width: 6px;
          height: 6px;
          vertical-align: middle;
          display: inline-block;
          margin-left: 4px;
        }
        input[type="text"],
        input[type="password"],
        input[type="number"] {
          padding: 14px 16px;
          width: 308px;
          height: 48px;
          border: 1px solid var(--grey3);
          border-radius: 8px;
          font-size: 14px;
          display: inline-block;
          vertical-align: middle;
          &::placeholder {
            color: var(--grey5);
          }
          &.short_input {
            width: 308px;
          }
          &.grey_input {
            background-color: var(--grey1);
            color: var(--grey5);
          }
        }
        input[type="radio"] {
          display: inline-block;
          margin-right: 10px;
          position: relative;
        }
        input[type="radio"],
        input[type="radio"]:checked {
          appearance: none;
          cursor: pointer;
          padding: 0;
        }
        input[type="radio"] {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          background-color: var(--white);
          border: 1px solid var(--grey4);
          color: var(--grey4);
          margin-right: 8px;
          cursor: pointer;
          border-radius: 100%;
          &:checked {
            background-color: var(--white);
            border: 6px solid var(--main);
            color: var(--grey4);
          }
        }
        label {
          margin-right: 24px;
          cursor: pointer;
          font-size: 14px;
          color: var(--text);
        }
        > button {
          width: 78px;
          height: 40px;
          border: 1px solid var(--main);
          border-radius: 6px;
          color: var(--main);
          font-size: 14px;
          line-height: 140%;
          float: none;
          width: 100%;
          margin-top: 10px;
          background-color: var(--white) !important;
          margin-bottom: 0px !important;
          font-weight: 500;
          &:hover {
            background-color: var(--grey2) !important;
            transition-duration: 0.4s;
          }
        }
        .signup_radio {
          margin-bottom: 14px;
        }
      }
      button {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
    // step4
    .step4_certification {
      margin-bottom: 212px;
      > div {
        h4 {
          font-weight: 500;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: var(--text);
          margin-bottom: 6px;
        }
      }
      img {
        width: 308;
        height: 256px;
        display: block;
        margin-top: 24px;
        margin-top: 28px;
      }
      button {
        width: 308px;
        height: 42px;
        background: #6c38ff;
        border-radius: 6px;
        color: var(--white);
        margin: 0 auto;
        margin-top: 28px;
        margin-bottom: 25px;
        display: block;
      }
      p {
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #646f7c;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .error_tag {
    color: var(--red);
    font-size: 14px;
    line-height: 140%;
    margin-top: 6px;
  }
  .input_error {
    border: 1px solid var(--red);
  }
  .safe_tag {
    color: var(--main);
    font-size: 14px;
    line-height: 140%;
    margin-top: 6px;
    letter-spacing: -0.04em;
  }

  .btns {
    margin-top: 40px;
    button {
      padding: 11px 16px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.04em;
      font-weight: 500;
      &:nth-child(1) {
        background-color: var(--grey2);
        color: var(--grey6);
      }
      &:nth-child(2) {
        background-color: var(--sub);
        color: var(--white);
        float: right;
        &:hover {
          background-color: var(--main);
          transition-duration: 0ss;
        }
      }
    }
  }
`;

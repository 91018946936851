// 기본 적용 스타일 코드입니다.
import { createGlobalStyle } from "styled-components";
// icon
import arrowDown from "Assets/images/icons/arrow_down_black.png";

// 사용되는 폰트입니다.
// import SpoqaHanSansNeoBold from "Assets/fonts/SpoqaHanSansNeo-Bold.otf";
// import SpoqaHanSansNeoMedium from "Assets/fonts/SpoqaHanSansNeo-Medium.otf";
// import SpoqaHanSansNeoRegular from "Assets/fonts/SpoqaHanSansNeo-Regular.otf";
// import SpoqaHanSansNeoLightEot from "Assets/fonts/SpoqaHanSansNeo-Light.eot";
// import SpoqaHanSansNeoLightWoff2 from "Assets/fonts/SpoqaHanSansNeo-Light.woff2";
// import SpoqaHanSansNeoLightWoff from "Assets/fonts/SpoqaHanSansNeo-Light.woff";
// import SpoqaHanSansNeoRegularEot from "Assets/fonts/SpoqaHanSansNeo-Regular.eot";
// import SpoqaHanSansNeoRegularWoff2 from "Assets/fonts/SpoqaHanSansNeo-Regular.woff2";
// import SpoqaHanSansNeoRegularWoff from "Assets/fonts/SpoqaHanSansNeo-Regular.woff";
// import SpoqaHanSansNeoMediumEot from "Assets/fonts/SpoqaHanSansNeo-Medium.eot";
// import SpoqaHanSansNeoMediumWoff2 from "Assets/fonts/SpoqaHanSansNeo-Medium.woff2";
// import SpoqaHanSansNeoMediumWoff from "Assets/fonts/SpoqaHanSansNeo-Medium.woff";
// import SpoqaHanSansNeoBoldEot from "Assets/fonts/SpoqaHanSansNeo-Bold.eot";
// import SpoqaHanSansNeoBoldWoff2 from "Assets/fonts/SpoqaHanSansNeo-Bold.woff2";
// import SpoqaHanSansNeoBoldWoff from "Assets/fonts/SpoqaHanSansNeo-Bold.woff";
// import NotoSansKRBold from "Assets/fonts/NotoSansKR-Bold.otf";
// import NotoSansKRLight from "Assets/fonts/NotoSansKR-Light.otf";
// import NotoSansKRMedium from "Assets/fonts/NotoSansKR-Medium.otf";
// import NotoSansKRRegular from "Assets/fonts/NotoSansKR-Regular.otf";
const globalStyle = createGlobalStyle`
   :root {
      --main: #6C38FF;
      --sub: #A788FF;
      --text: #28323C;
      --black: #000000;
      --grey1: #F7F8F9;
      --grey2: #F2F4F6;
      --grey3: #E9EBEE;
      --grey4: #C5C8CE;
      --grey5:#A2A7B1;
      --grey6:#646F7C;
      --grey7:#374553;
      --white: #ffffff;
      --red: #F9665C ;
   }

   /* font */
   @font-face {
      font-family: "Spoqa Han Sans Neo", "sans-serif";
      src: url("Assets/fonts/SpoqaHanSansNeo-Light.eot") format("embedded-opentype"),
         url("Assets/fonts/SpoqaHanSansNeo-Light.woff2") format("woff2"),
         url("Assets/fonts/SpoqaHanSansNeo-Light.woff") format("woff"),
         url("Assets/fonts/SpoqaHanSansNeo-Light.ttf") format("truetype");
      font-weight: 300;
      font-style: normal;
      }
   @font-face {
   font-family: "Spoqa Han Sans Neo", "sans-serif";
   src: url("Assets/fonts/SpoqaHanSansNeo-Regular.eot")
         format("embedded-opentype"),
      url("Assets/fonts/SpoqaHanSansNeo-Regular.woff2") format("woff2"),
      url("Assets/fonts/SpoqaHanSansNeo-Regular.woff") format("woff"),
      url("Assets/fonts/SpoqaHanSansNeo-Regular.ttf") format("truetype");
   font-weight: 400;
   font-style: normal;
   }
@font-face {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  src: url("Assets/fonts/SpoqaHanSansNeo-Medium.eot")
      format("embedded-opentype"),
    url("Assets/fonts/SpoqaHanSansNeo-Medium.woff2") format("woff2"),
    url("Assets/fonts/SpoqaHanSansNeo-Medium.woff") format("woff"),
    url("Assets/fonts/SpoqaHanSansNeo-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  src: url("Assets/fonts/SpoqaHanSansNeo-Bold.eot") format("embedded-opentype"),
    url("Assets/fonts/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("Assets/fonts/SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("Assets/fonts/SpoqaHanSansNeo-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}

/* noto sans */
   @font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url("Assets/fonts/NotoSansKR-Light.woff2") format('woff2'),
  url("Assets/fonts/NotoSansKR-Light.woff") format('woff'),
  url("Assets/fonts/NotoSansKR-Light.otf") format('opentype')
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: normal;
  src: url("Assets/fonts/NotoSansKR-Regular.woff2") format('woff2'),
  url("Assets/fonts/NotoSansKR-Regular.woff") format('woff'),
  url("Assets/fonts/NotoSansKR-Regular.otf") format('opentype')
}


@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url("Assets/fonts/NotoSansKR-Medium.woff2") format('woff2'),
  url("Assets/fonts/NotoSansKR-Medium.woff") format('woff'),
  url("Assets/fonts/NotoSansKR-Medium.otf") format('opentype')
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: bold;
  src: url("Assets/fonts/NotoSansKR-Bold.woff2") format('woff2'),
  url("Assets/fonts/NotoSansKR-Bold.woff") format('woff'),
  url("Assets/fonts/NotoSansKR-Bold.otf") format('opentype')
}

    

   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      line-height: 1;
      letter-spacing: -0.08em;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }
   html {
      font-size: 16px;
   }
   body {
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';
      -webkit-touch-callout: none;
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      letter-spacing: -0.08em;
   }
   #root {
      position: relative;
      width: 100%;
      /* height: 100vh; */
      background: var(--background);
      /* overflow: hidden; */
   }
   // 기본 셋팅 부분
   a {
      text-decoration: none;
      color: inherit;
   }
   img {
      width: 100%;
   }
   ul, ol {
      list-style-type: none;
   }
   input,
   select,
   textarea {
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      outline: none;
      resize: none;
      background-color: transparent;
      border-color:transparent
   }
  
   input[type="number"] {
      -moz-appearance: textfield;
   }
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      :checked ~ .check {
         background-color: var(--main);
         &::after {
            display: block;
         }
      }
   }
   select {
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      border: 1px solid #e0e0e0;
      width: 300px;
      height: 38px;
      padding: 0 5px;
      background: url(${arrowDown}) calc(100% - 5px) center no-repeat;
      background-size: 20px;
   }
   // 기본 버튼 설정
   button {
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      /* &:active,&:hover{
         opacity: 0.8;
      }        */

   }
   i {
      color: var(--black);
      }
   // 글씨 크기
   h1{
      font-size: 28px;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }
   h2{
      font-size: 20px;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }
   h3{
      font-size: 16px;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }
   h4{
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }
   h5{
      font-size: 12px;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }
   p{
      font-size: 14px;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }
   input{
      width: 100%;
      display: block;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }
   textarea {
      line-height: 140%;
      font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';

   }

   // 자주 쓰는 class
   .cursor {
      cursor: pointer;
   }
   .hidden {
      width:0px!important;
      height: 0px!important;
      overflow: hidden!important;
      opacity:0!important;
      visibility:hidden!important;
      display:none!important;
  }
   table {
      white-space:pre;
      tr,td,th{
         line-height: 140%;
         white-space: pre-line;
         word-break: keep-all;
      }
   }
   .cursor{
      cursor: pointer;
   }

   // margin
   .mt20 {
      margin-top: 20px;
    }
    .mt40 {
      margin-top: 40px;
    }
    .mt80 {
      margin-top: 80px !important;
    }
    .mb0 {
      margin-bottom: 0px !important;
    }
    .mb30 {
      margin-bottom: 30px !important;
    }
    .mb40 {
      margin-bottom: 40px !important;
    }
    .mb80 {
      margin-bottom: 80px;
    }
    .mb110 {
      margin-bottom: 110px !important;
    }
    .mb200 {
      margin-bottom: 200px;
    }
    /* .mr0 {
      margin-right:0px !important;
    } */

    // select 커서 높이 맞추기
    .css-uxezpk-control {
      input {
         height: 100% !important;
      }
    }
    .css-120todo-Input {
      input {
         height: 100% !important;
      }
    }
      // CKEditor
      .ck-editor__editable { 
      min-height: 100px; 
      max-height: 1000px;
      border:none;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #7f7f7f;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0.6;
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: #f3f3f3;
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
      }
   }
   .ck .ck-placeholder {
      margin-top:30px !important;
   }
   .ck .ck-placeholder:before {
      color:var(--grey3);
      font-size: 15px;
   }
   .ck.ck-toolbar{
      border: 1px solid #E4E4E4;
      border-left: none;
      border-right: none;
      background-color: var(--white);
   }
   .ckck-content{
      border: 1px solid #E4E4E4;
      border-left: none;
      border-right: none;
   }
   .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
      border: 1px solid #E4E4E4;
      border-left: none;
      border-right: none;

   }
   .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
      border: 1px solid #E4E4E4;
      border-left: none;
      border-right: none;

      box-shadow: none;
   }

   
`;

export default globalStyle;

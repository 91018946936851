import React from "react";
import styled from "styled-components";
import { BasicButton } from "Styles/styles";
import { View, Main } from "./Modal.style";

const Modal = ({
  title,
  setmodal,
  data,
  width,
  borderRadius,
  sub,
  height,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
}) => {
  return (
    <View>
      <Main
        modalWidth={width}
        modalMinwidth={minWidth}
        modalMaxwidth={maxWidth}
        modalHeight={height}
        modalMinheight={minHeight}
        modalMaxheight={maxHeight}
        modalBorderRadius={borderRadius}
      >
        <h4>{title}</h4>
        <h5>{sub}</h5>
        {setmodal ? (
          <button className="closebtn" onClick={() => setmodal()}>
            <i className="xi-close"></i>
          </button>
        ) : null}

        {data}
      </Main>
    </View>
  );
};

export default Modal;

import styled from "styled-components";

//img
import Calendar from "Assets/images/icons/calendar.svg";
import Stroke1 from "Assets/images/icons/Stroke1.svg";
import Rightbar from "Assets/images/icons/rightbar.png";

export const View = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  z-index: 99;
`;

export const Main = styled.div`
  padding: 20px;
  width: ${({ modalWidth }) => (modalWidth ? modalWidth : 300) + "px"};
  border-radius: ${({ modalBorderRadius }) =>
    (modalBorderRadius ? modalBorderRadius : 0) + "px"};
  min-width: ${({ modalMinwidth }) =>
    (modalMinwidth ? modalMinwidth : 0) + "px"};
  max-width: ${({ modalMaxwidth }) =>
    (modalMaxwidth ? modalMaxwidth : 0) + "px"};
  max-height: ${({ modalMaxheight }) =>
    (modalMaxheight ? modalMaxheight : 0) + "px"};
  min-height: ${({ modalMinheight }) =>
    modalMinheight
      ? typeof modalMinheight === "number"
        ? modalMinheight + "px"
        : modalMinheight
      : 0 + "px"};
  background: #fff;
  overflow: hidden;
  position: relative;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0.6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }

  .scrollBox {
    overflow-y: auto;
    height: 495px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #7f7f7f;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0.6;
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: #f3f3f3;
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
    li {
      width: 100%;
      height: 46px;
      display: inline-block;
      position: relative;
      text-align: left;
      margin-bottom: 10px;
      img {
        width: 46px;
        height: 46px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        object-fit: cover;
      }
      span {
        display: inline-block;
        width: 514px;
        text-align: left;
        vertical-align: middle;
        h6 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: var(--text);
        }
        p {
          font-size: 12px;
          color: var(--grey2);
          line-height: 140%;
        }
      }
      .pick {
        padding: 5.5px 10px;
        background-color: var(--main);
        color: var(--white);
        font-size: 12px;
        border: none;
        width: 45px;
        height: 28px;
        line-height: 140%;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        right: 0;
        margin-right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 10px;
    color: var(--text);
    text-align: left;
    padding: 0;
    line-height: 140%;
    letter-spacing: -0.08em;
  }
  h4 {
    display: block;
    /* width: calc(100% - 40px); */
    word-break: keep-all;
    color: var(--grey7);
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    letter-spacing: -0.08em;
    line-height: 160%;
  }
  // close 아이콘
  h5 {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: var(--text);
    text-align: left;
    display: inline;
    line-height: 140%;
  }
  .closebtn {
    position: absolute;
    right: 15px;
    top: 20px;
    border: none;
  }
  i {
    color: #000;
    font-size: 16px;
  }

  // 휴대폰 번호 변경
  /* .phone_number_change {
    > div {
      width: 331px;
      height: 65px;
      border-radius: 10px;
      border: 1px solid var(--main);
      font-size: 18px;
      color: var(--main);
      vertical-align: middle;
      text-align: left;
      margin: 24px 0;
      padding: 20px;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      &:hover {
        background-color: var(--grey1);
      }
      img {
        float: right;
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-top: 3px;
      }
    }
    > span {
      padding: 18px 14px;
      background-color: var(--grey1);
      display: block;
      box-sizing: border-box;
      text-align: left;
      font-size: 14px;
      line-height: 170%;
      color: var(--grey6);
      letter-spacing: -0.08em;
      border-radius: 6px;
      position: relative;
      strong {
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        color: var(--grey6);
        margin-bottom: 10px;
        display: block;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        color: var(--grey6);
        line-height: 170%;
        span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  } */

  // 비밀번호 변경 모달
  .password_change {
    h4 {
      &.red {
        color: var(--red);
      }
    }
    > div {
      h6 {
        text-align: left;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: var(--text);
        font-weight: 400;
        margin-bottom: 6px;
        margin-top: 24px;
      }

      input[type="password"] {
        font-size: 14px;
        padding: 14px 16px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid var(--grey3);
        &::placeholder {
          color: var(--grey5);
        }
      }
      button {
        width: 100%;
        margin-top: 30px;
      }
    }
  }

  // 회원 탈퇴 모달
  .bye_user {
    h4 {
      text-align: left;
      font-size: 15px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: var(--text);
      font-weight: 400;
      width: 100%;
    }
    .btns {
      text-align: right;
      margin-top: 24px;
      button {
        &:nth-child(1) {
          margin-right: 6px;
        }
      }
    }
  }

  // 결제 수단 변경
  .payment_change {
    h3 {
      margin-bottom: 24px;
      font-size: 22px;
      font-weight: 700;
    }
    > div {
      margin-bottom: 24px;
      text-align: left;
      div {
        h6 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: var(--grey6);
          margin-bottom: 6px;
          text-align: left;
        }
      }
      input[type="text"] {
        padding: 14px 16px;
        width: 432px;
        height: 48px;
        border: 1px solid var(--grey3);
        border-radius: 8px;
      }
      input[type="number"] {
        padding: 14px 16px;
        width: 102px;
        height: 48px;
        border: 1px solid var(--grey3);
        border-radius: 8px;
        display: inline-block;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      input[type="password"] {
        padding: 14px 16px;
        width: 102px;
        height: 48px;
        border: 1px solid var(--grey3);
        border-radius: 8px;
        display: inline-block;
        margin-right: 10px;
        &.grey_input {
          background: #f7f8f9;
          border: 1px solid #e9ebee;
        }
      }
    }
    button {
      padding: 11px 16px;
      // width: 60px;
      height: 42px;
      background: #a788ff;
      border-radius: 6px;
      float: right;
      color: var(--white);
      line-height: 140%;
      letter-spacing: -0.04em;
      box-sizing: border-box;
      margin-top: 16px;
    }
  }
  // 결제 수단 변경 실패
  .payment_fail {
    h3 {
      margin-bottom: 10px;
      font-size: 22px;
      font-weight: 700;
    }
    h4 {
      margin-bottom: 24px;
    }
    > span {
      padding: 18px 14px;
      background-color: var(--grey1);
      display: block;
      box-sizing: border-box;
      text-align: left;
      font-size: 14px;
      line-height: 170%;
      color: var(--grey6);
      letter-spacing: -0.08em;
      border-radius: 6px;
      position: relative;
      strong {
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        color: var(--grey6);
        margin-bottom: 10px;
        display: block;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        color: var(--grey6);
        line-height: 170%;
        span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
    button {
      padding: 11px 16px;
      height: 42px;
      background: var(--main);
      border-radius: 6px;
      float: right;
      color: var(--white);
      line-height: 140%;
      letter-spacing: -0.04em;
      box-sizing: border-box;
      margin-top: 24px;
    }
  }

  // 사진 선택 모달
  .picture_select {
    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #374553;
      margin-bottom: 6px;
      text-align: left;
      display: block;
    }
    h6 {
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      letter-spacing: -0.04em;
      color: #646f7c;
      text-align: left;
      margin-bottom: 12px;
    }
    > div {
      text-align: left;
      .search_box {
        width: 350px;
        height: 48px;
        border: 1px solid #e9ebee;
        border-radius: 8px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        input[type="text"] {
          padding-left: 35px;
          height: 48px;
        }
        i {
          position: absolute;
          left: 14px;
          top: 16px;
          color: var(--grey4);
        }
      }
      .image_add {
        display: inline-block;
        vertical-align: middle;
        border: 1px solid var(--main);
        color: var(--main);
        border-radius: 6px;
        padding: 11px 16px;
        font-weight: 500;
        cursor: pointer;
      }
      &.picture_list {
        margin-top: 20px;
        h6 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #28323c;
          margin-bottom: 8px;
        }
        ul {
          width: 100%;
          display: grid;
          flex-direction: column;
          gap: 8px 8px;
          grid-template-columns: repeat(auto-fill, calc(20% - 7px));
          background-color: var(--white);
          scrollbar-width: none;
          -ms-overflow-style: none;
          overflow-y: auto;
          position: relative;
          //  height: 400px;
          height: 292px;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #7f7f7f;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0.6;
            border-radius: 10px;
            background-clip: padding-box;
            border: 2px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border-radius: 10px;
            box-shadow: inset 0px 0px 5px white;
          }
          li {
            height: 140px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      &.search_none {
        height: 292px;
        > div {
          padding-top: 80px;
          i {
            font-size: 24px;
            color: var(--grey4);
            text-align: center;
            margin-bottom: 27px;
            display: block;
          }
          h4 {
            font-weight: 500;
            font-size: 22px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.04em;
            color: #374553;
            margin-bottom: 8px;
            width: 100%;
          }
          p {
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.04em;
            color: #646f7c;
          }
        }
      }
    }
  }

  // 나의 뉴스 불러오기 모달
  .mynews_select {
    h5 {
      margin-bottom: 14px;
      text-align: left;
      display: block;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #374553;
    }
    > div {
      text-align: left;
      &.mynews_list {
        margin-top: 20px;
        ul {
          width: 100%;
          display: grid;
          flex-direction: column;
          gap: 30px 8px;
          grid-template-columns: repeat(auto-fill, calc(33% - 4px));
          background-color: var(--white);
          scrollbar-width: none;
          -ms-overflow-style: none;
          overflow-y: auto;
          position: relative;
          height: 400px;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #7f7f7f;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0.6;
            border-radius: 10px;
            background-clip: padding-box;
            border: 2px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border-radius: 10px;
            box-shadow: inset 0px 0px 5px white;
          }
          li {
            height: 332px;
            img {
              height: 180px;
              object-fit: cover;
              border-radius: 8px;
              width: 100%;
              background: var(--grey2);
              display: block;
              object-fit: cover;
              border-radius: 8px;
            }
            > div {
              margin-top: 10px;
              .tagbox {
                margin-bottom: 8px;
                span {
                  display: inline-block;
                  background: #f2f4f6;
                  border-radius: 4px;
                  padding: 4px 8px;
                  margin-right: 6px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 140%;
                  letter-spacing: -0.04em;
                  color: #646f7c;
                }
              }
              h5 {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                margin-bottom: 8px;
                letter-spacing: -0.04em;
                height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              h6 {
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.04em;
                color: #a2a7b1;
                margin-bottom: 10px;
              }
              button {
                width: 100%;
                height: 34px;
                background: #6c38ff;
                border-radius: 6px;
                display: block;
                color: var(--white);
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  // 휴대폰 번호 변경 모달
  .phone_number_change {
    div {
      margin-top: 24px;
      text-align: left;
      h6 {
        margin-bottom: 6px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: var(--grey6);
      }
      p {
        text-align: right;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: var(--grey7);
        margin-top: 14px;
        cursor: pointer;
      }
      input {
        padding-top: 3px;
        width: 100%;
        height: 48px;
        font-size: 14px;
        border: 1px solid var(--grey3);
        transition: border 0.1s ease-in-out, background-color 0.1s ease-in-out;
        padding: 14px 16px;
        border-radius: 8px;
        &::placeholder {
          font-size: 14px;
          color: var(--grey5);
        }
        &.grey_input {
          background-color: var(--grey1);
          border: 1px solid var(--grey3);
        }
        &.certification_input {
          margin: 10px 0;
        }
      }
      .certification_button {
        width: 100%;
        height: 42px;
        background: #ffffff;
        border: 1px solid #6c38ff;
        border-radius: 6px;
        display: block;
        margin-top: 10px;
        color: var(--main);
      }
      .btns {
        width: 100% !important;
        margin-top: 10px;
        button {
          display: inline-block;
          vertical-align: middle;
          &:nth-child(1) {
            width: 112px;
            height: 42px;
            background: #ffffff;
            border: 1px solid var(--main);
            border-radius: 6px;
            text-align: center;
            color: var(--main);
            margin-right: 10px;
          }
          &:nth-child(2) {
            width: 177px;
            height: 42px;
            background: #a788ff;
            border-radius: 6px;
            color: var(--white);
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  // 공통 모달
  .modal_style {
    .btns {
      text-align: right;
      margin-top: 24px;
      button {
        &:nth-child(1) {
          margin-right: 6px;
        }
      }
    }
  }
`;

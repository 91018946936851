import React, { useState, useEffect } from "react";

// api
import { mainApi } from "Utils/api/api";

const NewsLogic = ({ loading, setLoading, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    data_loading: true,
    data_news: [],
    data_comments: [],
    data_comments_total: 0,
    data_related_news: [],
    search_keyword: "",
    modal: { open: false, type: "", id: "" },
    grade_type: null, // 1학년, 2학년, 3~4학년, 5~6학년
    alert: null,
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (url.id) {
      getDetail();
    }
  }, [url, state.grade_type]);
  // 데이터 저장 state 관리
  const [inputData, setInputData] = useState({
    comment: "",
  });
  // 데이터 list 관리
  const dataList = {};

  ////////////////  API  ////////////////
  // 상세 조회
  const getDetail = async () => {
    try {
      const {
        data: { data },
      } = await mainApi.getNewsDetail({
        grade: state.grade_type,
        id: url.id,
      });
      console.log(data);
      setState({
        ...state,
        data_loading: false,
        data_news: data.news,
        data_comments: data.comments,
        data_comments_total: data.comments.total,
        data_related_news: data.related_news,
        grade_type: data.news.contents ? data.news.contents[0].grade : "1",
      });
    } catch (error) {
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      console.log(error);
      // alert(res.data.message);
    } finally {
      setLoading(false);
    }
  };
  // 댓글 작성
  const writeComment = async () => {
    if (localStorage.getItem("user_token")) {
      try {
        const json = {
          comment: inputData.comment,
        };
        const { data } = await mainApi.setCommentNews(
          {
            id: url.id,
          },
          json
        );
        document.querySelector("#comment").value = "";
        setStateValue("alert", {
          title: "댓글 등록이 요청되었어요.",
          sub: "작성한 댓글은 LMS의 나의 댓글에서 확인할 수 있어요.",
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setStateValue("alert", {
        title: "댓글은 회원만 가능 합니다.",
        sub: "로그인 하시러 가시겠습니까?",
        isOk: () => (window.location.href = "/login/student"),
        isCancel: () => setStateValue("alert", null),
      });
    }
  };

  // 좋아요
  const likedNews = async (id) => {
    if (localStorage.getItem("user_token")) {
      try {
        const { data } = await mainApi.setLikeNews({
          id: url.id,
        });
        getDetail();
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setStateValue("alert", {
        title: "좋아요는 회원만 가능 합니다.",
        sub: "로그인 하시러 가시겠습니까?",
        isOk: () => (window.location.href = "/login/student"),
        isCancel: () => setStateValue("alert", null),
      });
    }
  };

  // 뉴스 url
  const getNewsUrl = async () => {
    navigator.clipboard.writeText(`${window.location.origin}${url.current}`);
    setStateValue("alert", {
      title: "링크가 자동으로 복사되었어요!",
      sub: "링크를 보내고싶은 곳에 붙여넣기를 해보세요.",
      isOk: () => setStateValue("alert", null),
      isCancel: null,
    });
  };
  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };
  // state input 셋팅
  const setInputStateValue = (key, value) => {
    setInputData({ ...inputData, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };
  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };

  return {
    state,
    data: { dataList, currentPage },
    func: {
      setStateValue,
      setModalData,
      writeComment,
      likedNews,
      getNewsUrl,
      setInputStateValue,
      paging,
    },
  };
};

export default NewsLogic;
